import { createAxiosInstance } from '../lib/createAxiosInstance';
import { API_PATHS } from './constants/apiPaths';
import { GetNotificationDetailResponseBody } from './types/getNotificationDetail';
import { AppNotificationDetail } from '../types/AppNotificationDetail';
import { notificationDetailValidator } from './validators/notificationDetailValidator';
import DateFormatter from '../lib/dateFormatter';
import { AxiosResponse } from 'axios';

export const getNotificationDetail = async (
    notificationId: number
): Promise<AppNotificationDetail> => {
    try {
        const instance = createAxiosInstance();
        const response: AxiosResponse = await instance.get(
            API_PATHS.GET_NOTIFICATION_DETAIL(notificationId)
        );
        const notificationDetailResponse: GetNotificationDetailResponseBody =
            response.data ?? {};

        const convertedNotificationDetail: AppNotificationDetail = {
            id: notificationDetailResponse.id,
            releaseDateTime: DateFormatter.formatDate(
                notificationDetailResponse.release_datetime
            ),
            title: notificationDetailResponse.title,
            type: notificationDetailResponse.type,
            typeBackgroundColor:
                notificationDetailResponse.type_background_color,
            typeTextColor: notificationDetailResponse.type_text_color,
            content: notificationDetailResponse.content,
            previousId: notificationDetailResponse.previous_id,
            nextId: notificationDetailResponse.next_id,
        } as AppNotificationDetail;

        const isValid: boolean = notificationDetailValidator(
            convertedNotificationDetail
        );
        if (!isValid) {
            throw Error;
        }

        return convertedNotificationDetail;
    } catch (error) {
        console.error('Error fetching notifications:', error);
        throw error;
    }
};
