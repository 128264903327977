import ClubPlayer from '../types/clubPlayer';
import { createAxiosInstance } from '../lib/createAxiosInstance';
import getClubPlayerResponseBody from './types/getClubPlayerType';
import { clubPlayerValidator } from './validators/clubPlayerValidator';
import { API_PATHS } from './constants/apiPaths';
import { AxiosResponse } from 'axios';

/**
 * 選手情報取得APIを呼び出す
 * @returns 選手情報
 * @throws {Error} 選手情報取得失敗時にエラーを投げる
 */
export const getClubPlayer = async (code: string): Promise<ClubPlayer> => {
    try {
        const instance = createAxiosInstance();
        const response: AxiosResponse = await instance.get(
            API_PATHS.GET_CLUB_PLAYER(code)
        );
        const responseBody: getClubPlayerResponseBody = response.data ?? {};
        const clubPlayerObj: ClubPlayer = {
            id: responseBody.id,
            code: responseBody.code,
            name: responseBody.name,
            englishName: responseBody.english_name,
            presentationName: responseBody.presentation_name,
            position: responseBody.position,
            hometown: responseBody.hometown,
            birthday: responseBody.birthday,
            height: responseBody.height,
            weight: responseBody.weight,
            bloodType: responseBody.blood_type,
            imageIconUrl: responseBody.image_icon_url,
            content: responseBody.content,
        };
        const isValid = clubPlayerValidator(clubPlayerObj);
        if (!isValid) {
            throw Error;
        }
        return clubPlayerObj;
    } catch (error) {
        console.error('Error fetching club player:', error);
        throw error;
    }
};
