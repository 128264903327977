import React, { useState, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import styled, { keyframes } from 'styled-components';
import { ButtonLink } from '../components/button';
import { ImagePopup } from '../components/ImagePopup';
import LeftArrow from '../assets/images/icon_left.svg';
import RightArrow from '../assets/images/icon_right.svg';
import rotateIcon from '../assets/images/icon_rotate.svg';
import diaryIcon from '../assets/images/icon_diary_black.svg';
import picturesIcon from '../assets/images/icon_img.svg';
import ReactCardFlip from 'react-card-flip';

import NftStamp, {
    NftStampContainer,
    VisaTitle,
    ShortNameContent,
    VisaDate,
    VisaImg,
} from '../components/NftStamp';
import { Await, Link, generatePath, useLoaderData } from 'react-router-dom';
import { LoadingSpinnerOverlay } from '../Spinner';
import { NftDetail as NftDetailType } from '../types/nftDetailType';
import { NftDetailLoaderDataType } from '../types/nftDetailLoaderData';
import DateFormatter from '../lib/dateFormatter';
import noImage from '../assets/images/noimage.jpg';
import { PATH_MAPPING } from '../constants/pathMapping';
import { ga4PushEvent } from '../ga4';
import { GA4_CUSTOM_EVENT } from '../constants/ga4CustomEvent';
import PhotoGallery from '../components/PhotoGallery';
import DiaryEditor from '../components/DiaryEditor';
import { EventName } from '../components/EventName';
import downloadIcon from '../assets/images/icon_dl.svg';
import Picture from '../types/pictureType';
import { useSwipeable } from 'react-swipeable';
import { CustomSwipeableOptions } from '../types/swipeableOptions';
import { useNavigate } from 'react-router-dom';

// 親コンテナのスタイル定義
const ParentContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 400px;
    padding: 0 20px 30px;
    margin: 0 auto;
`;

// 詳細コンテナのスタイル定義
const DetailContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 30px;
`;

// タイトル
const EventTitle = styled.div`
    font-size: 18px;
    font-weight: bold;
    line-height: 26px;
    color: #141313;
    text-align: center;
    word-wrap: break-word;
`;

// 日付テキストのスタイル定義
const DateText = styled.div`
    padding: 8px 20px 0;
    font-size: 12px;
    font-weight: bold;
    color: #848487;
    text-align: center;
`;

type ArrowProps = {
    $isVisible: boolean;
};

// 矢印のスタイル定義
const Arrow = styled.div<ArrowProps>`
    position: relative;
    display: flex;
    align-items: center;
    visibility: ${({ $isVisible: isVisible }) =>
        isVisible ? 'visible' : 'hidden'};

    &:first-child {
        margin-right: 10px;
    }

    &:last-child {
        margin-left: 10px;
    }

    img {
        width: 8.8347px;
        height: 15.5694px;
    }
`;

const ArrowLink = styled(Link)`
    position: relative;

    /* タップ判定を広げるおまじない */
    &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 30px;
        height: 30px;
        content: '';
        transform: translate(-50%, -50%);
    }
`;

const SecondArrow = styled(Arrow)`
    margin-top: 10px;
`;
// テキストコンテナのスタイル定義
const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
`;

// プレースホルダーのスタイル定義
const PlaceHolder = styled(TextContainer)`
    height: 0%;
`;

// テキストと矢印のコンテナのスタイル定義
const TextArrowContainer = styled.div`
    display: flex;
`;

// セカンド矢印のコンテナのスタイル定義
const SecondArrowContainer = styled(TextArrowContainer)`
    /* スタイルは指定されない */
`;

// 画像コンテナのスタイル定義
const ImageContainer = styled.div`
    margin-top: 10px;
`;

type NftImageContainerProps = {
    itemProp?: string;
};

// 選手画像のコンテナのスタイル定義
const NftImageContainer = styled.div<NftImageContainerProps>`
    height: 491px;
    background-image: url(${(props) => props.itemProp});
    filter: drop-shadow(3px 3px 6px rgba(0 0 0/80%));
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    @media (width <= 400px) {
        max-width: 90vw;
        max-height: calc(90vw * 491 / 360);
    }
`;

// リンクスペースホルダーのスタイル定義
const LinkSpaceHolder = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    min-height: 24px;
    margin-top: 10px;
`;

// リンクコンテナのプロップス定義
interface LinkContainerProps {
    isVisible: boolean;
}

// リンクコンテナのスタイル定義
const LinkContainer = styled.div
    .attrs<LinkContainerProps>(() => ({}))
    .withConfig({
        shouldForwardProp: (prop) => !['isVisible'].includes(prop),
    })<LinkContainerProps>`
      display: flex;
      justify-content: center;
      max-height: ${({ isVisible }) => (isVisible ? '50px' : '0px')};
      overflow: hidden;
      visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
      opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
      transition: opacity 0.6s ease, max-height 0.6s ease, visibility 0.8s ease-in-out;

      a {
          display: block;
          font-size: 8px;
          font-weight: 500;
          color: #848487;
          text-decoration: none;
          word-break: break-all;
          white-space: normal;
      }
  `;

const DownloadLink = styled.span`
    position: absolute;
    top: 24px;
    right: 0;
    display: flex;
    font-size: 14px;
    color: #141313;
    text-decoration: underline;
    cursor: pointer;
`;

const NftDescriptionDisclosure = styled.details`
    padding: 1rem 1rem 0;
    margin-top: 25px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #141313;
    white-space: pre-wrap;
    cursor: pointer;
    border-radius: 4px;

    summary {
        padding: 0.5em;
        margin: -0.5em -0.5em 0;
        font-weight: bold;
        cursor: pointer;
    }

    &[open] {
        padding: 1rem;
        line-height: 1.5;
        background-color: #ffffff;

        summary {
            margin-bottom: 0.5em;
        }
    }
`;

const DownloadIcon = styled.img`
    margin-left: 9px;
`;

const ArrowText = styled.div`
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
`;

// 回転アイコンコンテナのスタイル定義
const RotateIconBackButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    & img {
        height: 25px;
        cursor: pointer;
    }
`;

// ボタンラッパーのスタイル定義
const ButtonWrapper = styled.div`
    display: flex;
    padding-top: 58px;
    border-radius: 5px;
`;

// ラウンドボタンのスタイル定義
const RoundButton = styled.div<{ $isPressed: boolean; $isVisible: boolean }>`
    position: fixed;
    right: calc(50% - 185px);
    bottom: 95px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 55px;
    pointer-events: ${({ $isVisible }) => ($isVisible ? 'auto' : 'none')};
    cursor: ${({ $isVisible }) => ($isVisible ? 'pointer' : 'default')};
    background-color: #d10411;
    border: none;
    border-radius: 50%;
    opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
    transition: opacity 0.2s ease;

    &::before,
    &::after {
        position: absolute;
        width: 15px;
        height: ${({ $isPressed }) => ($isPressed ? '2px' : '1.8px')};
        content: '';
        background: #ffffff;
        transition:
            transform 0.1s ease,
            height 0.1s ease;
    }

    &::before {
        transform: rotate(${({ $isPressed }) => ($isPressed ? '0' : '90')}deg);
    }

    @media (width <= 400px) {
        right: 10px;
    }
`;

// 子ボタンのスタイル定義
const ChildButton = styled.button`
    position: absolute;
    width: 55px;
    height: 55px;
    border: none;
    border-radius: 50%;
`;

// ジャンプアニメーションのキーフレーム定義
const jump = keyframes`
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
`;

// 上部子ボタンのスタイル定義
const TopChildButton = styled(ChildButton)`
    top: -65px;
    left: 0;
    background-color: #ffffff;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    animation: ${jump} 0.4s ease-in-out;
    animation-fill-mode: forwards;

    &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 31.98px;
        height: 31.04px;
        content: '';
        background-image: url(${diaryIcon});
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        transform: translate(-50%, -50%);
    }
`;

// 左部子ボタンのスタイル定義
const LeftChildButton = styled(ChildButton)`
    top: 0;
    left: -65px;
    background-color: #ffffff;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    animation: ${jump} 0.6s ease-in-out;
    animation-fill-mode: forwards;

    &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 34px;
        height: 23.48px;
        content: '';
        background-image: url(${picturesIcon});
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        transform: translate(-50%, -50%);
    }
`;

// カスタム出席内容ラッパーのスタイル定義
const CustomNftStampWrapper = styled.div`
    ${NftStampContainer} {
        position: relative;
        display: flex;
        max-width: 360px;
        height: 491px;

        @media (width <= 400px) {
            max-width: 90vw;
            max-height: calc(90vw * 491 / 360);
        }
    }

    /* VisaTitleのスタイルオーバーライド */
    ${VisaTitle} {
        padding: 58px 47px 30px;
        font-size: 38px;
        font-weight: bold;
        line-height: 3.7rem;
        text-align: center;
    }

    /* ShortNameContentのスタイルオーバーライド */
    ${ShortNameContent} {
        margin-top: 36px;
        margin-bottom: 42px;
        font-size: 54px;
        font-weight: bold;
        color: #ffffff;
    }

    /* VisaDateのスタイルオーバーライド */
    ${VisaDate} {
        margin-top: 4px;
        font-size: 20px;
        font-weight: bold;
    }

    @media (width <= 400px) {
        ${VisaTitle} {
            padding: 58px 47px 30px;
            font-size: 35px;
            line-height: 3.5rem;
        }
        ${ShortNameContent} {
            margin-top: calc(105vw - 365px);
            font-size: 44px;
        }
        ${VisaDate} {
            font-size: 18px;
        }
    }

    /* VisaImgのスタイルオーバーライド */
    ${VisaImg} {
        position: absolute;
        width: 350px;
        max-width: 360px;
        max-height: 491px;

        @media (width <= 400px) {
            max-width: 90vw;
            max-height: calc(90vw * 491 / 360);
        }
    }
`;

type TabProps = {
    $active: boolean;
};

const Tab = styled.button<TabProps>`
    display: flex;
    justify-content: center;
    padding: 10px 0;
    font-size: 14px;
    font-weight: ${({ $active }) => ($active ? 'bold' : 'normal')};
    color: ${({ $active }) => ($active ? 'black' : '#7D7D7D')};
    cursor: pointer;
    background: none;
    border: none;

    span {
        padding: 0 4px 3px;
        border-bottom: ${({ $active }) =>
            $active ? '2px solid black' : 'none'};
    }
`;

const Separator = styled.div`
    margin: 2px 72.5px 0;
    font-size: 22px;
    color: #aaaaaa;
`;

const TabContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 40px;
`;

const FakeContainer = styled.div`
    /* Nothing to add */
`;

const TabContentsWrapper = styled.div`
    margin-bottom: 50px;
`;

// NFT詳細コンポーネント
const NftDetail: React.FC = () => {
    const data = useLoaderData() as NftDetailLoaderDataType;
    const nftData = data.nft;
    const [isPressed, setIsPressed] = useState(false);
    const [isFlipped, setIsFlipped] = useState(false);
    const [isLinkVisible, setIsLinkVisible] = useState(true);
    const [activeTab, setActiveTab] = useState(0);
    const [scrollToTab, setScrollToTab] = useState<number | null>(null);
    const [content, setContent] = useState('');
    const [lastEdited, setLastEdited] = useState('');
    const [isButtonVisible, setIsButtonVisible] = useState(true);
    const { ref: fakeContainerRef, inView: fakeContainerInView } = useInView({
        initialInView: true,
    });
    const photoGalleryRef = useRef<HTMLDivElement>(null);
    const diaryEditorRef = useRef<HTMLDivElement>(null);

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState<number | null>(null);
    const [pictures, setPictures] = useState<Picture[]>([]);
    const [shouldRerender, setShouldRerender] = useState(false);
    const navigate = useNavigate();

    const handleImageSelect = (imageId: number) => {
        setSelectedImage(imageId);
        setIsPopupOpen(true);
    };

    const onPicturesUpdated = (updatedPictures: Picture[]) => {
        setPictures(updatedPictures);
        setShouldRerender(!shouldRerender);
    };

    const handleChildButtonClick = (tabIndex: number) => {
        setActiveTab(tabIndex);
        setScrollToTab(tabIndex);
    };

    useEffect(() => {
        if (scrollToTab === 0 && photoGalleryRef.current) {
            photoGalleryRef.current.scrollIntoView({ behavior: 'smooth' });
            setScrollToTab(null); // Reset the scrollToTab state after scrolling
        } else if (scrollToTab === 1 && diaryEditorRef.current) {
            diaryEditorRef.current.scrollIntoView({ behavior: 'smooth' });
            setScrollToTab(null); // Reset the scrollToTab state after scrolling
        }
    }, [scrollToTab]);

    useEffect(() => {
        setIsButtonVisible(!fakeContainerInView);
    }, [fakeContainerInView]);

    // FakeContainer が表示されているときに子ボタンを非表示にするための新しい useEffect フック
    useEffect(() => {
        if (fakeContainerInView) {
            setIsPressed(false);
        }
    }, [fakeContainerInView]);

    React.useEffect(() => {
        ga4PushEvent(GA4_CUSTOM_EVENT.DISPLAY_ATTENDANCE_HISTORY_DETAIL_PAGE);
    }, []);

    /**
     * handleButtonClick - ボタンがクリックされたときの処理
     * ボタンの状態を切り替え
     */
    const handleButtonClick = () => {
        setIsPressed(!isPressed);
    };

    /**
     * handleButtonClick - ボタンがクリックされたときの処理
     * ボタンの状態を切り替え
     */
    const handledButtonClick = () => {
        setIsFlipped(!isFlipped);
        setIsLinkVisible(!isLinkVisible);
    };

    /**
     * カードDLのハンドル
     * @param cardImageUrl カード画像のURL
     */
    const handleCardDownload = async (cardImageUrl: string) => {
        ga4PushEvent(GA4_CUSTOM_EVENT.PRESSED_CARD_DOWNLOAD_LINK);
        const response = await fetch(cardImageUrl);
        const blob = await response.blob();
        const file = new File([blob], `nft.png`, { type: 'image/png' });
        // shareメニューが使用できる端末の場合はshareメニューからダウンロードさせる。
        if (navigator.share) {
            navigator.share({
                files: [file],
                title: 'NFTカードダウンロード',
                text: 'NFTカードをダウンロードします',
            });
            // そうでない場合は疑似aタグでダウンロードさせる
        } else {
            const a = document.createElement('a');
            a.href = URL.createObjectURL(file);
            a.download = file.name;
            a.click();
        }
    };

    //スマホの際の横スワイプできるようになる実装。ライブラリーを使用。
    const SwipeHandler = (
        previousEventId: number | null,
        nextEventId: number | null
    ) =>
        useSwipeable({
            onSwipedLeft: () => {
                nextEventId !== null
                    ? navigate(
                          generatePath(PATH_MAPPING.NFT_DETAILS, {
                              eventId: nextEventId,
                          })
                      )
                    : '#';
            },
            onSwipedRight: () => {
                previousEventId
                    ? navigate(
                          generatePath(PATH_MAPPING.NFT_DETAILS, {
                              eventId: previousEventId,
                          })
                      )
                    : '#';
            },
            onSwipedDown: () => {},
            onSwipedUp: () => {},
            delta: 50,
            preventDefaultTouchmoveEvent: true,
            trackMouse: true,
        } as CustomSwipeableOptions);

    return (
        <React.Suspense fallback={<LoadingSpinnerOverlay opacity={0.6} />}>
            <Await resolve={nftData}>
                {(nftData: NftDetailType) => {
                    /**
                     * getCustomNftStampWrapper - CustomNftStampWrapperコンポーネントを返す
                     * @returns CustomNftStampWrapperコンポーネント
                     */
                    const getCustomNftStampWrapper = () => {
                        return (
                            <>
                                <CustomNftStampWrapper>
                                    <NftStamp
                                        event={{
                                            id: nftData.event.id,
                                            date: nftData.event.date,
                                            shortName: nftData.event.shortName,
                                            color: nftData.event.color,
                                            mainTextColor:
                                                nftData.event.mainTextColor,
                                            subTextColor:
                                                nftData.event.subTextColor,
                                        }}
                                        shouldLink={false}
                                    />
                                </CustomNftStampWrapper>
                            </>
                        );
                    };
                    /**
                     * getReactCardFlip - ReactCardFlipコンポーネントを返す
                     * @param hasImageUrl
                     * @returns ReactCardFlipコンポーネント
                     */
                    const getReactCardFlip = (hasImageUrl: boolean) => {
                        const imageUrl = hasImageUrl
                            ? nftData.imageUrl || noImage
                            : noImage;

                        return (
                            <ReactCardFlip
                                isFlipped={isFlipped}
                                flipDirection="horizontal"
                            >
                                <NftImageContainer itemProp={imageUrl} />
                                {getCustomNftStampWrapper()}
                            </ReactCardFlip>
                        );
                    };

                    return (
                        <ParentContainer>
                            <DetailContainer>
                                {/* 矢印とテキストのコンテナ */}
                                <div
                                    {...SwipeHandler(
                                        nftData.event.previousId,
                                        nftData.event.nextId
                                    )}
                                >
                                    <TextArrowContainer>
                                        <Arrow
                                            $isVisible={Boolean(
                                                nftData.event.previousId
                                            )}
                                        >
                                            <ArrowLink
                                                to={
                                                    nftData.event.previousId
                                                        ? generatePath(
                                                              PATH_MAPPING.NFT_DETAILS,
                                                              {
                                                                  eventId:
                                                                      nftData
                                                                          .event
                                                                          .previousId,
                                                              }
                                                          )
                                                        : '#'
                                                }
                                            >
                                                <img
                                                    src={LeftArrow}
                                                    alt="左矢印"
                                                />
                                            </ArrowLink>
                                        </Arrow>
                                        <TextContainer>
                                            <EventTitle>
                                                <EventName
                                                    name={nftData.event.name}
                                                />
                                            </EventTitle>
                                            <DateText>
                                                {`${DateFormatter.formatDate(nftData.event.date)}（${DateFormatter.formatDayOfWeek(nftData.event.date, true)}）`}
                                            </DateText>
                                        </TextContainer>
                                        <Arrow
                                            $isVisible={Boolean(
                                                nftData.event.nextId
                                            )}
                                        >
                                            <ArrowLink
                                                to={
                                                    nftData.event.nextId
                                                        ? generatePath(
                                                              PATH_MAPPING.NFT_DETAILS,
                                                              {
                                                                  eventId:
                                                                      nftData
                                                                          .event
                                                                          .nextId,
                                                              }
                                                          )
                                                        : '#'
                                                }
                                            >
                                                <img
                                                    src={RightArrow}
                                                    alt="右矢印"
                                                />
                                            </ArrowLink>
                                        </Arrow>
                                    </TextArrowContainer>
                                    {/* 回転アイコンのコンテナ */}
                                    <RotateIconBackButtonContainer>
                                        <ArrowText
                                            onClick={() =>
                                                navigate(PATH_MAPPING.NFT)
                                            }
                                        >
                                            ← 一覧に戻る
                                        </ArrowText>
                                        <img
                                            src={rotateIcon}
                                            alt="回転アイコン"
                                            onClick={handledButtonClick}
                                        />
                                    </RotateIconBackButtonContainer>
                                    {/* 画像とカードフリップのコンテナ */}
                                    <ImageContainer>
                                        {nftData.imageUrl
                                            ? /* NFT画像がある場合は「NFT画像」を表示 */
                                              getReactCardFlip(true)
                                            : /* NFT画像がない場合は「スタンプ」を表示 */
                                              getReactCardFlip(false)}
                                        {nftData.event.canPostTrajectory && (
                                            <RoundButton
                                                onClick={handleButtonClick}
                                                $isPressed={isPressed}
                                                $isVisible={isButtonVisible}
                                            >
                                                {/* ボタンが押されたときに表示される子ボタン */}
                                                {isPressed && (
                                                    <>
                                                        <TopChildButton
                                                            onClick={() =>
                                                                handleChildButtonClick(
                                                                    1
                                                                )
                                                            }
                                                        />
                                                        <LeftChildButton
                                                            onClick={() =>
                                                                handleChildButtonClick(
                                                                    0
                                                                )
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </RoundButton>
                                        )}
                                    </ImageContainer>
                                </div>
                                {/* リンクの表示/非表示を制御するコンテナ */}
                                <LinkSpaceHolder>
                                    {isLinkVisible && (
                                        <>
                                            <LinkContainer
                                                isVisible={isLinkVisible}
                                            >
                                                <a
                                                    href={nftData.nftAddress}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {nftData.nftAddress}
                                                </a>
                                            </LinkContainer>
                                            {/* ローカルだとうまくダウンロードできない（オリジンドメインが同じじゃないとダウンロードできない） */}
                                            {/* ダウンロードの検証はdevなりstgなりで行うこと */}
                                            {nftData.imageUrl && (
                                                <DownloadLink
                                                    onClick={() => {
                                                        handleCardDownload(
                                                            nftData.imageUrl
                                                        );
                                                    }}
                                                >
                                                    カードDL
                                                    <DownloadIcon
                                                        src={downloadIcon}
                                                        alt="ダウンロードアイコン"
                                                    />
                                                </DownloadLink>
                                            )}
                                        </>
                                    )}
                                </LinkSpaceHolder>
                                {nftData.description && (
                                    <NftDescriptionDisclosure>
                                        <summary>NFT詳細</summary>
                                        {nftData.description}
                                    </NftDescriptionDisclosure>
                                )}
                                {/* タブのコンテナ */}
                                {nftData.event.canPostTrajectory && (
                                    <>
                                        <TabContainer>
                                            <Tab
                                                onClick={() => setActiveTab(0)}
                                                $active={activeTab === 0}
                                            >
                                                <span>写真</span>
                                            </Tab>
                                            <Separator>|</Separator>
                                            <Tab
                                                onClick={() => setActiveTab(1)}
                                                $active={activeTab === 1}
                                            >
                                                <span>日記</span>
                                            </Tab>
                                        </TabContainer>
                                        <FakeContainer ref={fakeContainerRef} />
                                        {/* アクティブなタブに応じたコンテンツの表示 */}
                                        {activeTab === 0 ? (
                                            <TabContentsWrapper
                                                ref={photoGalleryRef}
                                            >
                                                <PhotoGallery
                                                    accountNftId={
                                                        nftData.accountNftId
                                                    }
                                                    onSelectImage={
                                                        handleImageSelect
                                                    }
                                                    onPicturesLoaded={
                                                        setPictures
                                                    }
                                                    pictures={pictures}
                                                    nftData={nftData}
                                                />
                                            </TabContentsWrapper>
                                        ) : (
                                            <TabContentsWrapper
                                                ref={diaryEditorRef}
                                            >
                                                <DiaryEditor
                                                    accountNftId={
                                                        nftData.accountNftId
                                                    }
                                                    content={content}
                                                    setContent={setContent}
                                                    lastEdited={lastEdited}
                                                    setLastEdited={
                                                        setLastEdited
                                                    }
                                                    activeTab={activeTab}
                                                    nftData={nftData}
                                                />
                                            </TabContentsWrapper>
                                        )}
                                    </>
                                )}

                                <SecondArrowContainer>
                                    <SecondArrow
                                        $isVisible={Boolean(
                                            nftData.event.previousId
                                        )}
                                    >
                                        <ArrowLink
                                            to={
                                                nftData.event.previousId
                                                    ? generatePath(
                                                          PATH_MAPPING.NFT_DETAILS,
                                                          {
                                                              eventId:
                                                                  nftData.event
                                                                      .previousId,
                                                          }
                                                      )
                                                    : '#'
                                            }
                                        >
                                            <img src={LeftArrow} alt="左矢印" />
                                        </ArrowLink>
                                    </SecondArrow>
                                    <PlaceHolder />
                                    <SecondArrow
                                        $isVisible={Boolean(
                                            nftData.event.nextId
                                        )}
                                    >
                                        <ArrowLink
                                            to={
                                                nftData.event.nextId
                                                    ? generatePath(
                                                          PATH_MAPPING.NFT_DETAILS,
                                                          {
                                                              eventId:
                                                                  nftData.event
                                                                      .nextId,
                                                          }
                                                      )
                                                    : '#'
                                            }
                                        >
                                            <img
                                                src={RightArrow}
                                                alt="右矢印"
                                            />
                                        </ArrowLink>
                                    </SecondArrow>
                                </SecondArrowContainer>
                                {/* NFT一覧へのリンクボタン */}
                                <ButtonWrapper>
                                    <ButtonLink
                                        to={PATH_MAPPING.NFT}
                                        bgColor="transparent"
                                        textColor="#141313"
                                        borderColor="#000000"
                                    >
                                        一覧に戻る
                                    </ButtonLink>
                                </ButtonWrapper>
                            </DetailContainer>
                            <ImagePopup
                                isOpen={isPopupOpen}
                                images={pictures.map((picture) => picture.id)}
                                selectedImage={selectedImage || null}
                                onClose={() => setIsPopupOpen(false)}
                                onSelectImage={handleImageSelect}
                                accountNftId={nftData.accountNftId}
                                pictures={pictures}
                                onPicturesUpdated={onPicturesUpdated}
                            />
                        </ParentContainer>
                    );
                }}
            </Await>
        </React.Suspense>
    );
};

export default NftDetail;
