import publicKeyToAddress from 'ethereum-public-key-to-address';

/*
 * Web3AuthのIDトークンをパースしてアドレスを返す
 * @param {string} idToken IDトークン
 * @returns {string | undefined} アドレス
 */
export const parseWeb3AuthIdTokenAndReturnAddress = (
    idToken: string
): string | undefined => {
    if (!idToken) return undefined;
    const base64Url: string = idToken.split('.')[1];
    const base64: string = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload: string = decodeURIComponent(
        atob(base64)
            .split('')
            .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
            .join('')
    );
    return publicKeyToAddress(
        JSON.parse(jsonPayload)?.wallets[0]?.public_key || ''
    );
};
