import UseDiceResult from '../../types/useDiceResult';

export const postUseDiceResultValidator = (useDiceResult: UseDiceResult) => {
    if (!useDiceResult) {
        return false;
    }
    if (typeof useDiceResult.accountId !== 'number') {
        return false;
    }
    if (typeof useDiceResult.sugorokuMapCode !== 'string') {
        return false;
    }
    if (typeof useDiceResult.sugorokuMapName !== 'string') {
        return false;
    }
    if (typeof useDiceResult.sugorokuMapVersion !== 'string') {
        return false;
    }
    if (typeof useDiceResult.currentStageCode !== 'string') {
        return false;
    }
    if (typeof useDiceResult.currentCell !== 'number') {
        return false;
    }
    if (typeof useDiceResult.isFinished !== 'boolean') {
        return false;
    }
    if (typeof useDiceResult.remainDiceValue !== 'number') {
        return false;
    }
    if (typeof useDiceResult.diceValue !== 'number') {
        return false;
    }
    return true;
};
