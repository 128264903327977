import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import notificationIcon from './assets/images/icon_notification.svg';
import newNotificationIcon from './assets/images/icon_new.svg';
import { PATH_MAPPING } from './constants/pathMapping';
import { useNavigate } from 'react-router-dom';

const IconContainer = styled.div`
    position: relative;
    display: flex;
    cursor: pointer;
`;

const Icon = styled.img`
    width: 19px;
    height: 22px;
`;

const NewNotificationIndicator = styled.div<{ $show: boolean }>`
    position: relative;
    top: -4px;
    left: -1px;
    width: 18px;
    height: 18px;
    visibility: ${({ $show }) => ($show ? 'visible' : 'hidden')};
    background-color: red;
    background-image: url(${newNotificationIcon});
    background-size: cover;
    border-radius: 50%;
    ${({ $show }) =>
        $show &&
        `
        animation: jump 0.5s ease-in-out;
        @keyframes jump {
            0% {
                transform: scale(1);
            }
            20% {
                transform: scale(1.1);
            }
            100% {
                transform: scale(1);
            }
        }
    `}
`;

interface BellIconProps {
    unreadNotificationCount: number;
}

const NotificationIcon: React.FC<BellIconProps> = ({
    unreadNotificationCount,
}) => {
    const [hasNewNotifications, setHasNewNotifications] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setHasNewNotifications(unreadNotificationCount > 0);
    }, [unreadNotificationCount]);

    const handleIconClick = () => {
        navigate(PATH_MAPPING.NOTIFICATIONS);
    };

    return (
        <IconContainer onClick={handleIconClick}>
            <Icon src={notificationIcon} alt="Notifications" />
            <NewNotificationIndicator $show={hasNewNotifications} />
        </IconContainer>
    );
};
export default NotificationIcon;
