import { createAxiosInstance } from '../lib/createAxiosInstance';
import { API_PATHS } from './constants/apiPaths';
import AppNotification from '../types/AppNotification';
import { GetNotificationsResponseBody } from './types/getNotifications';
import { notificationValidator } from './validators/notificationValidator';
import { AxiosResponse } from 'axios';

/**
 * お知らせ一覧取得APIを呼び出す
 * @returns {AppNotification[]} お知らせ情報一覧
 * @throws {Error} API呼び出しに失敗した場合にエラーを投げる
 */
export const getNotifications = async (): Promise<AppNotification[]> => {
    try {
        const instance = createAxiosInstance();
        const response: AxiosResponse = await instance.get(
            API_PATHS.GET_NOTIFICATIONS
        );
        const responseBody: GetNotificationsResponseBody = response.data ?? {};

        const notifications: Array<AppNotification> = [];

        for (const notification of responseBody.notification_list) {
            const convertedNotification: AppNotification = {
                id: notification.id,
                releaseDateTime: notification.release_datetime,
                type: notification.type,
                read: notification.is_read,
                showOnTop: notification.is_show_on_top,
                title: notification.title,
                typeBackgroundColor: notification.type_background_color,
                typeTextColor: notification.type_text_color,
            } as AppNotification;

            const isValid: boolean = notificationValidator(
                convertedNotification
            );
            if (!isValid) {
                continue;
            }

            notifications.push(convertedNotification);
        }
        return notifications ?? [];
    } catch (error) {
        console.error('Error fetching notifications:', error);
        throw error;
    }
};
