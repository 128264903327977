import React from 'react';
import { ButtonLink } from './button';
import styled from 'styled-components';
import { ga4PushEvent } from '../ga4';
import { GA4_CUSTOM_EVENT } from '../constants/ga4CustomEvent';

const NoEventsText = styled.div`
    margin-top: 40px;
    font-size: 20px;
    font-weight: bold;
`;

const ButtonWrap = styled.div`
    width: 100%;

    & > a {
        display: inline-block;
        margin-top: 40px;
    }
`;

const NoEventsSection: React.FC<{ onRecheck: () => void }> = ({
    onRecheck,
}) => {
    /**
     * onRecheck(初期化処理)が走る前にGA4イベントを送信する
     */
    const onRecheckFunctionWrapper = () => {
        ga4PushEvent(GA4_CUSTOM_EVENT.PRESSED_CHECK_IN_BUTTON);
        onRecheck();
    };
    return (
        <>
            <NoEventsText>現在開催中のイベントはありません。</NoEventsText>
            <ButtonWrap>
                <ButtonLink
                    to="#"
                    bgColor="#000000"
                    textColor="#ffffff"
                    onClick={onRecheckFunctionWrapper}
                >
                    再チェック
                </ButtonLink>
            </ButtonWrap>
        </>
    );
};

export default NoEventsSection;
