import { getNfts } from '../api/getNfts';
import { defer } from 'react-router-dom';

/**
 * ページ描画前に来場履歴を取得する
 *
 * @returns 来場履歴データ
 */
export const nftLoader = async () => {
    try {
        const Nfts = getNfts();
        return defer({ nfts: Nfts });
    } catch (error) {
        // TODO エラーハンドリング実装時に考える
        return [];
    }
};
