import { createAxiosInstance } from '../lib/createAxiosInstance';
import { API_PATHS } from './constants/apiPaths';

/**
 * 日記更新APIを呼び出す
 * @param {number} accountNftId アカウントNFT ID
 * @param {string} content 本文
 * @throws {Error} 失敗時にエラーを投げる
 */
export const putDiary = async (
    eventId: number,
    content: string
): Promise<null> => {
    try {
        const instance = createAxiosInstance();
        await instance.put(API_PATHS.PUT_DIARY(eventId), {
            content: content,
        });

        return null;
    } catch (error) {
        console.error('Put diary failed:', error);
        throw error;
    }
};
