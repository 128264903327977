import { AxiosInstance } from 'axios';
import { createAxiosInstanceWithoutAuthorization } from '../lib/createAxiosInstance';
import CookieManager from '../lib/cookieManager';
import { API_PATHS } from './constants/apiPaths';
import { COOKIE_KEYS } from '../constants/cookieKeys';
import { AxiosError } from 'axios';

/**
 * ログインAPIを呼び出す
 * @returns {boolean} ログイン成功時はtrue、失敗時はfalse
 * @throws {Error} ログイン失敗時にエラーを投げる
 */
export const postLogin = async (
    code: string,
    redirect_uri: string
): Promise<boolean> => {
    // JWTが返ってきます。
    try {
        const instance: AxiosInstance =
            createAxiosInstanceWithoutAuthorization();
        const response = await instance.post(API_PATHS.POST_LOGIN, {
            code: code,
            redirect_uri: redirect_uri,
        });
        const apiToken: string = response?.data?.api_token ?? undefined;
        const refresh_token: string =
            response?.data?.refresh_token ?? undefined;
        const nonce = response?.data?.nonce ?? undefined;
        if (!apiToken || !refresh_token || !nonce) {
            throw new Error('Login failed.');
        }
        CookieManager.setCookieValue(COOKIE_KEYS.API_TOKEN, apiToken);
        CookieManager.setCookieValue(COOKIE_KEYS.REFRESH_TOKEN, refresh_token);
        CookieManager.setCookieValue(COOKIE_KEYS.NONCE, nonce);
        return true;
    } catch (error) {
        if (error && error instanceof AxiosError) {
            throw error;
        }
        return false;
    }
};

export default postLogin;
