// すごろくエラー時に使用する共通ダイアログ
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Dialog from '../components/Dialog';
import { useDialog } from '../components/UseDialog';
// 画像のインポート
import doleWakuwaku from '../assets/images/dole_wakuwaku.svg';
import { PATH_MAPPING } from '../constants/pathMapping';
import { DiceReceivedDialogMessage } from './DiceReceivedDialogMessage';
import { ga4PushEvent } from '../ga4';
import { GA4_CUSTOM_EVENT } from '../constants/ga4CustomEvent';

const DialogImageWrapper = styled.div`
    position: absolute;
    top: -85px;
    left: 50%;
    transform: translateX(-50%);
`;

const DialogImage = styled.img`
    width: 183px;
`;

const DialogTextContainer = styled.div`
    display: grid;
    row-gap: 10px;
`;

const DialogMainText = styled.span`
    font-size: 22px;
    font-weight: 900;
    line-height: 30px;
    color: #d71920;
    text-align: center;
`;

const StyledButton = styled.button`
    width: 200px;
    padding: 13px 0;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 900;
    color: #000000;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid #000000;
    border-radius: 25px;

    /* アクセシビリティ的によくないが、最初からネガティブな選択肢にフォーカスがあたっているのが気に食わないので… */
    &:focus-visible {
        outline: none;
    }
`;

const OkButton = styled(StyledButton)`
    font-weight: bold;
    color: white;
    background-color: #d71920;
    border: 1px solid #d71920;
`;

interface SugorokuLoginBonusDialogProps {
    setShowLoginBonusDialog: React.Dispatch<React.SetStateAction<boolean>>;
    setCheckingLoginDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SugorokuLoginBonusDialog: React.FC<
    SugorokuLoginBonusDialogProps
> = ({ setShowLoginBonusDialog, setCheckingLoginDialog }) => {
    const { ref, closeModal: originalCloseModal } = useDialog();
    const navigate = useNavigate();

    const closeModal = () => {
        setShowLoginBonusDialog(false);
        originalCloseModal();
        setCheckingLoginDialog(false);
    };

    /**
     * ログインボーナスダイアログを閉じる際の処理
     */
    const handleCloseModal = () => {
        ga4PushEvent(GA4_CUSTOM_EVENT.PRESSED_LOGIN_BONUS_DIALOG_CLOSE);
        closeModal();
    };

    const handleOkButtonClick = () => {
        ga4PushEvent(
            GA4_CUSTOM_EVENT.PRESSED_LOGIN_BONUS_DIALOG_SUGOROKU_MAP_LINK
        );
        closeModal();
        navigate(PATH_MAPPING.SUGOROKU);
    };
    return (
        <Dialog
            ref={ref}
            isOpen={true}
            DialogImageWrapper={
                <DialogImageWrapper>
                    <DialogImage src={doleWakuwaku} alt="dolekun" />
                </DialogImageWrapper>
            }
            closeModal={handleCloseModal}
            showCloseButton={true}
        >
            <DialogTextContainer>
                <DialogMainText>ログインボーナス！</DialogMainText>
                <DiceReceivedDialogMessage diceCount={1} />
            </DialogTextContainer>
            <OkButton onClick={handleOkButtonClick}>すごろくマップへ</OkButton>
        </Dialog>
    );
};
