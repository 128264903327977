import { createContext } from 'react';

/**
 * 未読バッジの表示状態を管理するためのコンテキストの値の型
 */
interface NoticeBadgeContextValue {
    /**
     * 「来場チェック」の未読バッジを表示するかどうかを示すブール値
     */
    showCheckInNoticeBadge: boolean;
    /**
     * 「来場チェック」の未読バッジの表示状態を更新する関数
     * @param show - 未読バッジを表示するかどうか
     */
    setShowCheckInNoticeBadge: (show: boolean) => void;
    /**
     * 「すごろく」の未読バッジを表示するかどうかを示すブール値
     */
    showItemNoticeBadge: boolean;
    /**
     * 「すごろく」の未読バッジの表示状態を更新する関数
     * @param show - 未読バッジを表示するかどうか
     */
    setShowItemNoticeBadge: (show: boolean) => void;
}

/**
 * 未読バッジの表示状態を管理するためのコンテキスト
 */
export const NoticeBadgeContext = createContext<NoticeBadgeContextValue>({
    showCheckInNoticeBadge: false,
    setShowCheckInNoticeBadge: () => {},
    showItemNoticeBadge: false,
    setShowItemNoticeBadge: () => {},
});
