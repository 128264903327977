import stageEventResult from '../../types/stageEventResult';

export const postStageEventResultValidator = (
    stageEventResult: stageEventResult
) => {
    if (!stageEventResult) {
        return false;
    }
    if (typeof stageEventResult.isStageEventSuccess !== 'boolean') {
        return false;
    }
    if (typeof stageEventResult.beforeSugorokuStageCode !== 'string') {
        return false;
    }
    if (typeof stageEventResult.afterSugorokuStageCode !== 'string') {
        return false;
    }
    if (typeof stageEventResult.beforeCell !== 'number') {
        return false;
    }
    if (typeof stageEventResult.afterCell !== 'number') {
        return false;
    }
    if (typeof stageEventResult.remainDice !== 'number') {
        return false;
    }
    if (typeof stageEventResult.isFinished !== 'boolean') {
        return false;
    }
    return true;
};
