import AccountSugorokuProgress from '../../types/accountSugorokuType';

export const accountSugorokuProgressValidator = (
    accountSugorokuProgress: AccountSugorokuProgress
) => {
    if (!accountSugorokuProgress) {
        return false;
    }
    if (typeof accountSugorokuProgress.account_id !== 'number') {
        return false;
    }
    if (typeof accountSugorokuProgress.sugoroku_map_code !== 'string') {
        return false;
    }
    if (typeof accountSugorokuProgress.sugoroku_map_name !== 'string') {
        return false;
    }
    if (typeof accountSugorokuProgress.sugoroku_map_version !== 'string') {
        return false;
    }
    if (typeof accountSugorokuProgress.current_stage_code !== 'string') {
        return false;
    }
    if (typeof accountSugorokuProgress.current_cell !== 'number') {
        return false;
    }
    if (typeof accountSugorokuProgress.is_finished !== 'boolean') {
        return false;
    }
    if (!Array.isArray(accountSugorokuProgress.items)) {
        return false;
    }
    for (const item of accountSugorokuProgress.items) {
        if (typeof item.account_id !== 'number') {
            return false;
        }
        if (typeof item.code !== 'string') {
            return false;
        }
        if (typeof item.name !== 'string') {
            return false;
        }
        if (typeof item.quantity !== 'number') {
            return false;
        }
    }
    if (
        typeof accountSugorokuProgress.is_today_login_bonus_received !==
        'boolean'
    ) {
        return false;
    }
    return true;
};
