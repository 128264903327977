import AccountInfoType from '../types/accountInfo';
import { createAxiosInstance } from '../lib/createAxiosInstance';
import { accountInfoValidator } from './validators/accountInfoValidator';
import { API_PATHS } from './constants/apiPaths';
import SessionStorageManager from '../lib/sessionStorageManager';
import { SESSION_STORAGE_KEYS } from '../constants/sessionStorageKeys';
import { AxiosResponse } from 'axios';

/**
 * アカウント情報を取得するAPIを呼び出す
 * @returns {AccountInfoType | null} アカウント情報、またはアカウント情報が無効な場合はnull
 * @throws {Error} API呼び出しに失敗した場合にエラーを投げる
 */
export const getAccountsInfo = async (): Promise<AccountInfoType | null> => {
    try {
        const instance = createAxiosInstance();
        const response: AxiosResponse = await instance.get(
            API_PATHS.GET_ACCOUNTS_INFO
        );
        const accountInfo: AccountInfoType = response.data?.account_info;

        if (accountInfoValidator(accountInfo)) {
            return accountInfo;
        } else {
            console.error('Invalid account information:', accountInfo);
            return null;
        }
    } catch (error) {
        console.error('Error fetching Account Information:', error);
        throw error;
    }
};

/**
 * SessionStorageから会員情報を取得する。SessionStorageに会員情報が存在しない場合は、APIコールして取得する。
 * @returns 会員情報のPromise
 */
export const getAccountsInfoWithSessionStorage =
    async (): Promise<AccountInfoType | null> => {
        const sessionData = SessionStorageManager.getSessionStorageValue(
            SESSION_STORAGE_KEYS.USER_INFO
        );

        if (sessionData !== null) {
            const decodedData = decodeURIComponent(atob(sessionData));
            return JSON.parse(decodedData);
        }

        const accountInfo = await getAccountsInfo();

        if (accountInfo !== null) {
            const dataToStore = {
                first_name: accountInfo.first_name,
                last_name: accountInfo.last_name,
                attribute1: accountInfo.attribute1,
                icon_image_url: accountInfo.icon_image_url,
            };

            const encodedData = btoa(
                encodeURIComponent(JSON.stringify(dataToStore))
            );
            SessionStorageManager.setSessionStorageValue(
                'account_info',
                encodedData
            );
        }
        return accountInfo;
    };
