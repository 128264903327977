class DateFormatter {
    /**
     * 日付をYYYY.m.dd形式でフォーマットする
     * @param inputDate 日付文字列
     * @returns フォーマット後の日付
     */
    static formatDate(
        inputDate: string,
        addMonthPadding = false,
        addDayPadding = true
    ): string {
        try {
            // Safari対策。ハイフンを/に置換する
            // @see https://zenn.dev/aiueda/articles/f9e11aeca7813f
            const replacedInputDate = inputDate.replaceAll('-', '/');
            const date = new Date(replacedInputDate);

            if (isNaN(date.getTime())) {
                throw new Error('Invalid date format');
            }

            const year = date.getFullYear();
            let month = (date.getMonth() + 1).toString();
            if (addMonthPadding) {
                month = month.padStart(2, '0');
            }
            let day = date.getDate().toString();
            if (addDayPadding) {
                day = day.padStart(2, '0');
            }

            return `${year}.${month}.${day}`;
        } catch (error) {
            console.error(`Error formatting date`);
            throw error;
        }
    }

    /**
     * 日付の曜日部分を取得
     * @param inputDate 日付文字列
     * @param isUpperFirst 頭を大文字にするか
     * @returns 曜日文字列
     */
    static formatDayOfWeek(
        inputDate: string,
        isUpperFirst: boolean = false
    ): string {
        try {
            // Safari対策。ハイフンを/に置換する
            // @see https://zenn.dev/aiueda/articles/f9e11aeca7813f
            const replacedInputDate = inputDate.replaceAll('-', '/');

            const date = new Date(replacedInputDate);

            if (isNaN(date.getTime())) {
                throw new Error('Invalid date format');
            }
            // 0-6の数字を曜日に変換
            const dayOfWeek = date.getDay();
            const days = isUpperFirst
                ? ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
                : ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
            const dayOfWeekString = days[dayOfWeek];

            return dayOfWeekString;
        } catch (error) {
            console.error(`Error formatting day of week`);
            throw error;
        }
    }
}

export default DateFormatter;
