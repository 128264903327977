import ReceiveProbability from '../types/receiveProbabilityType';
import { createAxiosInstance } from '../lib/createAxiosInstance';
import GetReceiveProbabilityResponseBody from './types/getReceiveProbabilityType';
import { receiveProbabilityValidator } from './validators/receiveProbabilityValidator';
import { API_PATHS } from './constants/apiPaths';
import { AxiosResponse } from 'axios';

/**
 * アイテム獲得見込み情報取得API
 * @returns {receiveProbability} アイテム獲得見込み情報
 * @throws {Error} アイテム獲得見込み情報取得失敗時にエラーを投げる
 */
export const getReceiveProbability = async (): Promise<ReceiveProbability> => {
    try {
        const instance = createAxiosInstance();
        const response: AxiosResponse = await instance.get(
            API_PATHS.GET_RECEIVE_PROBABILITY
        );
        const responseBody: GetReceiveProbabilityResponseBody = response.data;
        const receiveProbability: ReceiveProbability = {
            postPicture: responseBody.post_picture.map((item) => ({
                eventId: item.event_id,
                eventName: item.event_name,
            })),
            putDiary: responseBody.put_diary.map((item) => ({
                eventId: item.event_id,
                eventName: item.event_name,
            })),
        };
        const isValid = receiveProbabilityValidator(receiveProbability);
        if (!isValid) {
            throw Error;
        }
        return receiveProbability;
    } catch (error) {
        console.error('Error fetching get receive probability:', error);
        throw error;
    }
};
