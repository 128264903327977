import { createAxiosInstance } from '../lib/createAxiosInstance';
import { API_PATHS } from './constants/apiPaths';
import PostCheckInResponseBody from './types/postCheckInType';
import { SUCCESS } from './constants/apiStatusCodes';
import CheckInResult from '../types/checkInResult';
import { AxiosResponse } from 'axios';

/**
 * チェックインAPIを呼び出す
 * @param {number} eventId イベントID
 * @returns {CheckInResult} チェックイン結果
 * @throws {Error} チェックイン失敗時にエラーを投げる
 */
export const postCheckIn = async (
    eventId: number,
    latitude: number,
    longitude: number
): Promise<CheckInResult> => {
    try {
        const instance = createAxiosInstance();
        const response: AxiosResponse = await instance.post(
            API_PATHS.POST_EVENT_CHECK_IN(eventId),
            {
                latitude: latitude,
                longitude: longitude,
            }
        );
        const responseBody: PostCheckInResponseBody = response.data ?? {};

        const result: CheckInResult = {
            statusCode: response?.status ?? 0,
            success: Boolean(response?.status === SUCCESS) ?? false,
            message: responseBody?.process_message ?? '',
        } as CheckInResult;

        return result;
    } catch (error) {
        console.error('Check-in failed:', error);
        throw error;
    }
};
