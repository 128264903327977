// すごろくエラー時に使用する共通ダイアログ
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Dialog from '../components/Dialog';
import { useDialog } from '../components/UseDialog';
// 画像のインポート
import dolekunDauntingPoseImage from '../assets/images/dolekun-daunting-pose.svg';
import { PATH_MAPPING } from '../constants/pathMapping';
import { MESSAGES } from '../constants/messages';

const SugorokuErrorDialogImageWrapper = styled.div`
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
`;

const DialogImage = styled.img`
    width: 70px;
`;

// ダイアログのメッセージをラップする
const DialogMessageWrapper = styled.span`
    font-weight: bold;
    text-align: center;

    a {
        color: #333333;
    }
`;

export const SugorokuErrorDialog: React.FC = () => {
    const { ref } = useDialog();
    return (
        <Dialog
            ref={ref}
            isOpen={true}
            DialogImageWrapper={
                <SugorokuErrorDialogImageWrapper>
                    <DialogImage src={dolekunDauntingPoseImage} alt="dolekun" />
                </SugorokuErrorDialogImageWrapper>
            }
            closeModal={() => {}}
            showCloseButton={false}
            $backgroundAlphaPercent={100}
        >
            <DialogMessageWrapper>
                {MESSAGES.API_ERROR_DIALOG_MESSAGE}
                <br></br>
                <br></br>
                <Link to={PATH_MAPPING.SUGOROKU} reloadDocument>
                    すごろくマップへ
                </Link>
            </DialogMessageWrapper>
        </Dialog>
    );
};
