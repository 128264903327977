export const API_PATHS = {
    // Login
    POST_LOGIN: '/login',
    // Event
    GET_EVENTS: '/events',
    // Event CheckIn
    POST_EVENT_CHECK_IN: (eventId: number) => {
        return `/events/${eventId}/check-in`;
    },
    // Attendances
    GET_ATTENDANCES: '/attendances',
    // Accounts_Info
    GET_ACCOUNTS_INFO: '/accounts',
    // Save Wallet Address
    POST_WALLET_ADDRESS: '/accounts/wallet',
    // Notifications
    GET_NOTIFICATIONS: '/notifications',
    // Notification Detail
    GET_NOTIFICATION_DETAIL: (notificationId: number) => {
        return `/notifications/${notificationId}`;
    },
    // Has Unread Notification
    GET_UNREAD_NOTIFICATION_COUNT: '/notifications/unread/counts',
    // Nfts
    GET_NFTS: '/nfts',
    // Nft Detail
    GET_NFT_DETAIL: (eventId: number) => {
        return `/events/${eventId}/nft`;
    },
    // Pictures
    GET_PICTURES: (accountNftId: number) => {
        return `/nfts/${accountNftId}/pictures`;
    },
    // Create Picture
    POST_PICTURE: (accountNftId: number) => {
        return `/nfts/${accountNftId}/pictures`;
    },
    // Delete Picture
    DELETE_PICTURE: (accountNftId: number, pictureId: number) => {
        return `/nfts/${accountNftId}/pictures/${pictureId}`;
    },
    // Get Diary
    GET_DIARY: (accountNftId: number) => {
        return `/nfts/${accountNftId}/diary`;
    },
    // Update Diary
    PUT_DIARY: (accountNftId: number) => {
        return `/nfts/${accountNftId}/diary`;
    },
    // Post Club_Ad Watching Time
    POST_CLUB_AD_WATCHING_TIME: (clubAdId: number) => {
        return `/club/ad/${clubAdId}/watch`;
    },
    // JLeague_Account_Info
    GET_JLEAGUE_ACCOUNT_INFO: '/jleague_account_info',
    // JLeague Logout Url
    GET_JLEAGUE_LOGOUT_URL: '/jleague_logout_url',
    // Get Access Token By Nonce
    GET_ACCESS_TOKEN_BY_NONCE: '/token',
    // Refresh Token
    POST_TOKEN_REFRESH: '/token/refresh',
    // Notifications Approval
    POST_NOTIFICATION_APPROVAL: '/notifications/approval',
    // Get Club Ad
    GET_CLUB_AD: '/club/ad',
    // Get Sugoroku Master
    GET_SUGOROKU_MASTER: '/sugoroku/master',
    // Get Account Sugoroku Progress
    GET_ACCOUNT_SUGOROKU_PROGRESS: '/sugoroku/progress',
    // Get Receive Probability
    GET_RECEIVE_PROBABILITY: '/sugoroku/items/receive-probability',
    // Get Club Player
    GET_CLUB_PLAYER: (code: string) => {
        return `/club/player/${code}`;
    },
    // Post Use Dice
    POST_USE_DICE: '/sugoroku/use/dice',
    // POST Sugoroku Item
    POST_SUGOROKU_ITEM: '/sugoroku/item',
    // POST Stage Event
    POST_STAGE_EVENT: '/sugoroku/stage-event',
};
