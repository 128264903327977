import { COOKIE_KEYS } from '../constants/cookieKeys';
import CookieManager from './cookieManager';

/**
 * トークン管理クラス
 */
export class TokenManager {
    private static readonly API_TOKEN_KEY: string = COOKIE_KEYS.API_TOKEN;
    private static readonly REFRESH_TOKEN_KEY: string =
        COOKIE_KEYS.REFRESH_TOKEN;

    public static getAccessToken(): string | undefined {
        return CookieManager.getCookieValue(this.API_TOKEN_KEY);
    }

    public static setAccessToken(token: string): void {
        CookieManager.setCookieValue(this.API_TOKEN_KEY, token);
    }

    public static deleteAccessToken(): void {
        CookieManager.deleteCookieValue(this.API_TOKEN_KEY);
    }

    public static getRefreshToken(): string | undefined {
        return CookieManager.getCookieValue(this.REFRESH_TOKEN_KEY);
    }

    public static setRefreshToken(token: string): void {
        CookieManager.setCookieValue(this.REFRESH_TOKEN_KEY, token);
    }

    public static deleteRefreshToken(): void {
        CookieManager.deleteCookieValue(this.REFRESH_TOKEN_KEY);
    }
}
