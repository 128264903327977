import { SUGOROKU_ITEM_CODE } from '../constants/sugorokuItemCode';
import AccountSugorokuProgress from '../types/accountSugorokuType';

/**
 * すごろくのアイテムに関するマネージャークラス
 */
class SugorokuItemManager {
    /**
     * 進行情報オブジェクトオブジェクトからサイコロの個数を計算する
     * @param progress 進行情報オブジェクト
     * @returns サイコロの個数
     */
    static getDiceCount(progress: AccountSugorokuProgress): number {
        const itemsLength = progress.items.length || 0;
        if (itemsLength > 0) {
            const diceItem = progress.items.find(
                (item) => item.code === SUGOROKU_ITEM_CODE.DICE
            );
            if (diceItem) {
                const diceValue = diceItem.quantity;
                return diceValue;
            } else {
                return 0;
            }
        } else {
            return 0;
        }
    }
}

export default SugorokuItemManager;
