import { axiosPublic } from './axiosPublic';
import { API_PATHS } from '../api/constants/apiPaths';
import { TokenManager } from './tokenManager';
import { AxiosResponse } from 'axios';
import memoize from 'memoize';

/**
 *  アクセストークンのリフレッシュ処理
 * @returns {Promise<string | undefined>} 新しいアクセストークン
 */
export const handleTokenRefresh = async (): Promise<string | undefined> => {
    try {
        const response: AxiosResponse = await axiosPublic.post(
            API_PATHS.POST_TOKEN_REFRESH,
            {
                refresh_token: TokenManager.getRefreshToken(),
            }
        );
        const newApiToken: string = response?.data?.api_token ?? undefined;
        TokenManager.setAccessToken(newApiToken);
    } catch (error) {
        TokenManager.deleteAccessToken();
        TokenManager.deleteRefreshToken();
    }
    return Promise.resolve(TokenManager.getAccessToken());
};

/**
 * アクセストークンのリフレッシュ処理をメモ化しておく。
 * これをしないと非同期的にリクエストを投げるたびリフレッシュ処理が走ってしまう。
 */
export const memoizedTokenRefresh = memoize(handleTokenRefresh, {
    maxAge: 1000 * 60, // 60s
});
