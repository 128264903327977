import AppNotification from '../../types/AppNotification';

export const notificationValidator = (
    notification: AppNotification
): boolean => {
    if (!notification) {
        return false;
    }
    if (!notification.id) {
        return false;
    }
    if (!notification.releaseDateTime) {
        return false;
    }
    if (!notification.type) {
        return false;
    }
    if (typeof notification.read !== 'boolean') {
        return false;
    }
    if (typeof notification.showOnTop !== 'boolean') {
        return false;
    }
    if (!notification.title) {
        return false;
    }
    if (!notification.typeBackgroundColor) {
        return false;
    }
    if (!notification.typeTextColor) {
        return false;
    }
    return true;
};
