import EventType from '../types/event';
import { createAxiosInstance } from '../lib/createAxiosInstance';
import GetEventsResponseBody from './types/getEventsType';
import { eventValidator } from './validators/eventValidator';
import { API_PATHS } from './constants/apiPaths';
import { AxiosResponse } from 'axios';

/**
 * イベント取得APIを呼び出す
 * @returns {EventType[]} 今日のイベントの配列
 * @throws {Error} イベント取得失敗時にエラーを投げる
 */
export const getEvents = async (): Promise<EventType[]> => {
    try {
        const instance = createAxiosInstance();
        const response: AxiosResponse = await instance.get(
            API_PATHS.GET_EVENTS
        );
        const responseBody: GetEventsResponseBody = response.data ?? {};
        const eventList: GetEventsResponseBody['event_list'] =
            responseBody?.event_list ?? [];

        const events: Array<EventType> = [];
        for (const eventObj of eventList) {
            const event: EventType = {
                id: eventObj.event_id,
                date: eventObj.event_date,
                name: eventObj.event_name,
                short_name: eventObj.event_short_name,
                has_checked_in: eventObj.has_checked_in,
                latitude: eventObj.latitude,
                longitude: eventObj.longitude,
                location_name: eventObj.location_name,
                radius: eventObj.radius,
            } as EventType;

            const isValid: boolean = eventValidator(event);
            if (!isValid) {
                continue;
            }

            events.push(event);
        }
        return events ?? [];
    } catch (error) {
        console.error('Error fetching today events:', error);
        throw error;
    }
};
