import { useJsApiLoader } from '@react-google-maps/api';
export type Map = google.maps.Map;
type Props = {
    defaultPosition: { lat: number; lng: number };
};
export const useMap = ({ defaultPosition }: Props) => {
    const googleMapsApiKey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;
    const { isLoaded } = useJsApiLoader({
        id: 'google-map',
        googleMapsApiKey,
    });
    const onLoad = (map: Map) => {
        const bounds = new window.google.maps.LatLngBounds(defaultPosition);
        map.fitBounds(bounds);
    };
    return { isLoaded, onLoad };
};
