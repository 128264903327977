import { Web3AuthNoModal } from '@web3auth/no-modal/dist/types/noModal';
import { connectToWeb3Auth } from './connectToWeb3Auth';
import { parseWeb3AuthIdTokenAndReturnAddress } from './parseWeb3AuthIdTokenAndReturnAddress';
import { UserAuthInfo } from '@web3auth/base';

/*
 * ウォレットを生成しpublicなアドレスを返す
 * @param {string} jwt jwt
 * @returns {Promise<string | undefined>} address
 */
export const generateAccountWallet = async (
    jwt: string | undefined,
    nonce: string | undefined
): Promise<string | undefined> => {
    if (!jwt) {
        return undefined;
    }
    const web3auth: Web3AuthNoModal | undefined = await connectToWeb3Auth(
        jwt,
        nonce
    );
    if (!web3auth) {
        return undefined;
    }
    const authenticateUser: UserAuthInfo = await web3auth.authenticateUser();
    const address = parseWeb3AuthIdTokenAndReturnAddress(
        authenticateUser?.idToken
    );
    await web3auth.logout();
    return address;
};
