import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import crying_dole from '../assets/images/fail_dole.png';
import skipIcon from '../assets/images/skip.png';
import PkScreen from '../components/PkScreen';
import { sendWatchingTime } from './club_ad_watching_history';
import { getClubAd } from '../api/getClubAd';
import ClubAd from '../types/clubAd';
import { SugorokuErrorDialog } from './SugorokuErrorDialog';
import { Link } from 'react-router-dom';
import { SugorokuItem } from '../types/sugorokuMasterType';
import { AD_VIDEO_EXTENSIONS } from '../constants/extensions';
import Dialog from '../components/Dialog';
import { useDialog } from '../components/UseDialog';
import { GA4_CUSTOM_EVENT } from '../constants/ga4CustomEvent';
import { ga4PushEvent } from '../ga4';

const PkFailText = styled.div`
    margin-top: 17px;
    font-size: 42px;
    font-weight: 900;
    color: #2060ac;
`;
const PkAdText = styled.span`
    margin-top: 16px;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
`;

const ReturnText = styled.span`
    margin-top: 16px;
    font-size: 14px;
    font-weight: bold;
    color: #333333;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const StyledButton = styled.button`
    width: 230px;
    padding: 13px 0;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 900;
    color: #000000;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid #000000;
    border-radius: 25px;

    /* アクセシビリティ的によくないが、最初からネガティブな選択肢にフォーカスがあたっているのが気に食わないので… */
    &:focus-visible {
        outline: none;
    }
`;

const RedButton = styled(StyledButton)`
    font-weight: bold;
    color: white;
    background-color: #d71920;
    border: 1px solid #d71920;
`;

const WhiteButton = styled(StyledButton)`
    margin-top: 10px;
    font-weight: bold;
    color: black;
    background-color: white;
    border: 1px solid black;
`;

const FullscreenContents = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: black;
`;

const FullScreenVideo = styled.video`
    max-width: 100%;
    max-height: 100%;
`;

const FullscreenImage = styled.img`
    max-width: 100%;
    max-height: calc(100dvh - 48px - 100px);
`;

const DetailsText = styled.div`
    padding: 10px 15px 15px;
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
    color: white;
    background-color: #d10411;
    border-radius: 20px;
`;

const FullscreenLink = styled(Link)<{ $linkDisabled: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-decoration: none;
    pointer-events: ${(props) => (props.$linkDisabled ? 'none' : 'auto')};
`;

const ProgressBarContainer = styled.div`
    position: absolute;
    bottom: 30px;
    display: flex;
    justify-content: center;
    width: 80%;
`;

const ProgressBar = styled.div`
    width: 100%;
    height: 3px;
    background-color: rgb(255 255 255 / 50%);
`;

interface ProgressFillProps {
    $progress: number;
}

const ProgressFill = styled.div.attrs<ProgressFillProps>(({ $progress }) => ({
    style: {
        width: `${$progress}%`,
    },
}))`
    height: 100%;
    background-color: white;
    transition: width 1s linear;
`;

interface SkipButtonProps {
    $isClickable?: boolean;
    $skiptimeRemaining?: number;
}

const SkipButton = styled.div<SkipButtonProps>`
    position: absolute;
    top: 10px;
    right: 10px;
    display: ${(props) =>
        props.$skiptimeRemaining !== undefined && props.$skiptimeRemaining <= 10
            ? 'flex'
            : 'none'};
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: bold;
    color: white;
    pointer-events: ${(props) => (props.$isClickable ? 'auto' : 'none')};
    cursor: pointer;
    background-color: rgb(0 0 0 / 50%);
    border-radius: 4px;

    &::after {
        display: inline-block;
        width: 24px;
        height: 24px;
        content: '';
        background-image: url(${skipIcon});
        background-repeat: no-repeat;
        background-size: contain;
    }
`;

/**
 * ダイアログ画像をラップするコンポーネント
 */
const DialogImageWrapper = styled.div`
    position: absolute;
    top: -95px;
    left: 50%;
    transform: translateX(-50%);
`;

const DialogImage = styled.img`
    width: 190px;
`;

interface PkScreenFailProps {
    nextEventItem?: SugorokuItem;
    setHideOriginalPkScreen: React.Dispatch<React.SetStateAction<boolean>>;
}

const PkScreenFail: React.FC<PkScreenFailProps> = ({
    nextEventItem,
    setHideOriginalPkScreen,
}) => {
    const [showMessageDialogueContainer, setShowMessageDialogueContainer] =
        useState(false);
    const [showPkScreen, setShowPkScreen] = useState(false);
    const [showFullscreenVideo, setShowFullscreenVideo] = useState(false);
    const [clubAd, setClubAd] = useState<ClubAd | null>(null);
    // 広告の長さ
    const AD_DURATION = 24;
    // スキップボタンを表示するまでの時間
    const SKIP_BUTTON_DISPLAY_TIME = 10;
    const [skiptimeRemaining, setSkipTimeRemaining] = useState(
        SKIP_BUTTON_DISPLAY_TIME
    );
    const [progress, setProgress] = useState(0);

    const startTimeRef = useRef<number | null>(null);
    const [elapsedTime, setElapsedTime] = useState(0);
    const [hasError, setHasError] = useState(false);
    const [
        hasShownMessageDialogueContainer,
        setHasShownMessageDialogueContainer,
    ] = useState(false);
    const fullscreenContentsRef = useRef<HTMLDivElement>(null);
    const hasCalledSendWatchingTimeRef = useRef(false);

    useEffect(() => {
        if (fullscreenContentsRef.current && clubAd?.id) {
            ga4PushEvent(GA4_CUSTOM_EVENT.DISPLAY_CLUB_AD_PAGE(clubAd.id));
        }
    }, [clubAd?.id]);

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout> | null = null;

        if (!hasShownMessageDialogueContainer) {
            timer = setTimeout(() => {
                setShowMessageDialogueContainer(true);
                setHasShownMessageDialogueContainer(true);
            }, 2000);
        }
        startTimeRef.current = Date.now();
        const videoTimer = setInterval(async () => {
            if (
                clubAd &&
                showFullscreenVideo &&
                startTimeRef.current !== null
            ) {
                const currentTime = Date.now() - startTimeRef.current;
                const newElapsedTime = currentTime / 1000;
                setElapsedTime(newElapsedTime);

                if (newElapsedTime >= SKIP_BUTTON_DISPLAY_TIME) {
                    let newSkipTimeRemaining =
                        SKIP_BUTTON_DISPLAY_TIME - newElapsedTime;
                    newSkipTimeRemaining = Math.max(
                        0,
                        Math.ceil(newSkipTimeRemaining)
                    );
                    setSkipTimeRemaining(newSkipTimeRemaining);
                } else {
                    setSkipTimeRemaining(
                        SKIP_BUTTON_DISPLAY_TIME - newElapsedTime
                    );
                }

                setProgress((newElapsedTime / AD_DURATION) * 100);

                if (
                    AD_DURATION <= newElapsedTime &&
                    !hasCalledSendWatchingTimeRef.current
                ) {
                    try {
                        hasCalledSendWatchingTimeRef.current = true;
                        // Send the watching time to the backend
                        await sendWatchingTime(AD_DURATION, clubAd.id);
                    } catch (error) {
                        console.error(
                            'Error sending watching time to the backend:',
                            error
                        );
                    }
                    // Continue with the rest of the logic
                    setShowFullscreenVideo(false);
                    // PKScreenが二重で出る問題の対策 もともと出ていたPK画面を非表示にする
                    setShowPkScreen(true);
                    if (setHideOriginalPkScreen !== null) {
                        setHideOriginalPkScreen(true);
                    }
                }
            }
        }, 100);

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
            clearInterval(videoTimer);
        };
    }, [
        showFullscreenVideo,
        AD_DURATION,
        SKIP_BUTTON_DISPLAY_TIME,
        clubAd,
        hasShownMessageDialogueContainer,
        setHideOriginalPkScreen,
    ]);

    const handleOkClick = async () => {
        try {
            // ダイアログを閉じる
            setShowMessageDialogueContainer(false);
            const clubAd = await getClubAd();
            if (!clubAd) {
                console.error('no ads found');
                setHasError(true);
                return;
            }
            setClubAd(clubAd);
            setShowFullscreenVideo(true);
        } catch (error) {
            console.error('Error fetching club ad:', error);
            setHasError(true);
        }
    };

    const handleSkipClick = async () => {
        // TSにわからせるための分岐。たぶんこの分岐に入ることはない
        if (!clubAd) {
            console.error('No club ad found');
            return;
        }
        try {
            // Send the watching time to the backend
            await sendWatchingTime(elapsedTime, clubAd.id);

            // Continue with the rest of the logic
            setShowFullscreenVideo(false);
            // PKScreenが二重で出る問題の対策 もともと出ていたPK画面を非表示にする
            if (setHideOriginalPkScreen !== null) {
                setHideOriginalPkScreen(true);
            }
            setShowPkScreen(true);
        } catch (error) {
            console.error('Error sending watching time to the backend:', error);
        }
    };

    const handleCancelClick = () => {
        ga4PushEvent(
            GA4_CUSTOM_EVENT.PRESSED_MINI_GAME_FAILURE_DIALOG_BACK_BUTTON
        );
        // リロードさせておけばトップに戻れるはず
        window.location.reload();
    };

    /**
     * 広告リソースに含まれるコンテンツが動画か静止画かを拡張子で判別する
     * @param resource 広告リソース
     * @returns 広告リソースが動画か、静止画か
     */
    const isVideo = (resource: ClubAd['resource']) => {
        // 一旦「動画じゃなければ静止画だろ」ってことで行かせてもらいます
        return AD_VIDEO_EXTENSIONS.some((ext) => resource.endsWith(ext));
    };

    /**
     * ビデオ1フレームごとにisVideoが呼ばれるのが嫌なのでMemo化します
     */
    const isVideoResource = useMemo(
        () => isVideo(clubAd?.resource ?? ''),
        [clubAd?.resource]
    );

    const PkDialogFail: React.FC = () => {
        const { ref } = useDialog();

        return (
            <Dialog
                ref={ref}
                isOpen={true}
                DialogImageWrapper={
                    <DialogImageWrapper>
                        <DialogImage src={crying_dole} alt="DoleCry" />
                    </DialogImageWrapper>
                }
                closeModal={() => {}}
                showCloseButton={false}
            >
                <PkFailText>ハズレ&hellip;</PkFailText>
                <PkAdText>広告を閲覧して再挑戦しますか？</PkAdText>

                <ReturnText>再挑戦しないと1マス前に戻ります</ReturnText>
                <ButtonContainer>
                    <RedButton onClick={handleOkClick}>広告を見る</RedButton>
                    <WhiteButton onClick={handleCancelClick}>
                        再挑戦せずに1マス戻る
                    </WhiteButton>
                </ButtonContainer>
            </Dialog>
        );
    };

    const handleClubAdLinkClick = (
        clubAdId: number
    ): React.MouseEventHandler<HTMLAnchorElement> => {
        return () => {
            ga4PushEvent(GA4_CUSTOM_EVENT.PRESSED_CLUB_AD_LINK(clubAdId));
        };
    };

    return (
        <>
            {hasError && <SugorokuErrorDialog />}
            {showPkScreen && (
                <PkScreen
                    nextEventItem={nextEventItem}
                    isFirstRequest={false}
                />
            )}
            {clubAd && showFullscreenVideo && (
                <FullscreenContents ref={fullscreenContentsRef}>
                    <FullscreenLink
                        to={clubAd.url}
                        target="_blank"
                        rel="noreferrer"
                        $linkDisabled={clubAd.url === ''}
                        onClick={handleClubAdLinkClick(clubAd.id)}
                    >
                        {isVideoResource ? (
                            <FullScreenVideo
                                autoPlay
                                loop
                                playsInline={true}
                                controls={false}
                            >
                                <source src={clubAd.resource} />
                            </FullScreenVideo>
                        ) : (
                            <FullscreenImage
                                src={clubAd.resource}
                                alt="ad contents"
                            />
                        )}
                        <DetailsText>詳しく見る＞</DetailsText>
                    </FullscreenLink>
                    <ProgressBarContainer>
                        <ProgressBar>
                            <ProgressFill $progress={progress} />
                        </ProgressBar>
                    </ProgressBarContainer>
                    <SkipButton
                        $skiptimeRemaining={skiptimeRemaining}
                        $isClickable={skiptimeRemaining <= 1}
                        onClick={handleSkipClick}
                    >
                        {skiptimeRemaining > 1
                            ? `スキップ ${Math.floor(skiptimeRemaining)}s`
                            : 'スキップ'}
                    </SkipButton>
                </FullscreenContents>
            )}

            {showMessageDialogueContainer && <PkDialogFail />}
        </>
    );
};

export default PkScreenFail;
