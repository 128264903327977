import React from 'react';
import styled from 'styled-components';
import { Link as RouterLink, generatePath } from 'react-router-dom';
import { PATH_MAPPING } from '../constants/pathMapping';
import { ga4PushEvent } from '../ga4';

// EventLinkコンポーネントのプロパティ型を定義
type EventLinkProps = {
    eventId?: string | number; // イベントIDを受け取るプロパティ (オプショナル)
    ga4CustomEvent?: string; // GA4のカスタムイベント名を受け取るプロパティ (オプショナル)
    children: React.ReactNode; // 子要素を受け取るプロパティ
};

// react-router-domのLinkコンポーネントをスタイル付きで拡張したコンポーネント
export const StyledLink = styled(RouterLink)`
    color: inherit;
    text-decoration: none;
`;

/**
 * EventLinkコンポーネント (NFT詳細ページへのリンクコンポーネント)
 * @param eventId イベントID
 * @param ga4CustomEvent GA4のカスタムイベント名
 * @param children 子要素
 * @returns NFT詳細ページへのリンクコンポーネント
 */
export const EventLink: React.FC<EventLinkProps> = ({
    eventId,
    ga4CustomEvent,
    children,
}) => {
    const handleOnClick = () => {
        if (ga4CustomEvent) {
            ga4PushEvent(ga4CustomEvent);
        }
    };
    // StyledLinkコンポーネントを使ってリンクを作成し、子要素をレンダリング
    return (
        <StyledLink
            to={generatePath(PATH_MAPPING.NFT_DETAILS, {
                eventId: eventId,
            })}
            onClick={handleOnClick}
        >
            {children}
        </StyledLink>
    );
};

export default EventLink;
