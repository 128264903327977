import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import CookieManager from '../lib/cookieManager';
import SessionStorageManager from '../lib/sessionStorageManager';
import { PATH_MAPPING } from '../constants/pathMapping';

export type LogoutFunction = (dialogMessage?: string) => void;

/**
 * ログアウト処理を行う
 * @returns {function} ログアウト関数
 */
const useLogout = (): LogoutFunction => {
    const navigate = useNavigate();

    const logout = useCallback(
        (dialogMessage?: string) => {
            // 参照できるCookieをすべて削除する
            CookieManager.deleteAllCookies();
            // 参照できるSessionStorageをすべて削除する
            SessionStorageManager.deleteAllSessionStorageValues();

            // ルートのパスに遷移
            if (!dialogMessage) {
                navigate(PATH_MAPPING.ROOT);
            }

            navigate(PATH_MAPPING.ROOT, {
                state: {
                    message: dialogMessage,
                },
            });
            return;
        },
        [navigate]
    );

    return logout;
};

export default useLogout;
