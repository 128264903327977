import Picture from '../../types/pictureType';

export const pictureValidator = (picture: Picture) => {
    if (!picture) {
        return false;
    }
    if (!picture.id) {
        return false;
    }
    if (!picture.account_pictures_url) {
        return false;
    }
    return true;
};
