import {
    GoogleMap as GoogleMapComponent,
    CircleF,
    MarkerF,
} from '@react-google-maps/api';
import { FC } from 'react';
import { useMap } from './useMap';
import styled from 'styled-components';

type Props = {
    defaultPosition: {
        lat: number;
        lng: number;
    };
    eventPosition?: {
        lat: number;
        lng: number;
    } | null;
    radius: number | null;
    currentPosition: { currentlat: number; currentlng: number } | null;
};

const LoadingArea = styled.div`
    width: 100%;
    height: 100%;
    border: 2px solid #d3d3d3;
    border-radius: 3px;
`;

const AbleToDistributePointText = styled.div`
    margin: 40px 0 20px;
    font-size: 16px;
    font-weight: bold;
`;

const GoogleMap: FC<Props> = (props) => {
    const { isLoaded, onLoad } = useMap({
        defaultPosition: props.defaultPosition,
    });

    const containerStyle = {
        width: 'calc(100vw - 50px)',
        height: '360px',
        border: '1px solid #d3d3d3',
        borderRadius: '3px',
        maxWidth: '360px',
    };

    const centerPosition = props.eventPosition || props.defaultPosition;

    return (
        <>
            <AbleToDistributePointText>
                配布できるポイント
            </AbleToDistributePointText>
            {isLoaded ? (
                <GoogleMapComponent
                    mapContainerStyle={containerStyle}
                    center={centerPosition}
                    onLoad={onLoad}
                    options={{
                        minZoom: 14,
                        maxZoom: 16,
                        streetViewControl: false,
                        fullscreenControl: false,
                        mapTypeControl: false,
                    }}
                >
                    {props.eventPosition && props.radius && (
                        <CircleF
                            center={props.eventPosition}
                            radius={props.radius}
                            options={{
                                strokeColor: '#3399FF', // blue color
                                strokeOpacity: 0.3,
                                strokeWeight: 2,
                                fillColor: '#3399FF', // blue color
                                fillOpacity: 0.2,
                            }}
                        />
                    )}
                    {props.currentPosition && (
                        <MarkerF
                            position={{
                                lat: props.currentPosition.currentlat,
                                lng: props.currentPosition.currentlng,
                            }}
                        />
                    )}
                </GoogleMapComponent>
            ) : (
                <LoadingArea>Loading...</LoadingArea>
            )}
        </>
    );
};
export default GoogleMap;
