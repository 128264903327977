import React from 'react';
import styled from 'styled-components';

const PlayerMilestoneContainer = styled.div`
    position: relative;
    top: -56px;
    z-index: 3;
    display: flex;
    flex-direction: column;
    gap: 4px;
    min-width: 70px;
    margin-left: 95px;
    font-weight: 900;
    color: black;
`;

const GoalTitleText = styled.div`
    margin-left: 14px;
    font-size: 10px;
    font-weight: 900;
    text-align: left;
`;

const GoalNameText = styled.div`
    margin: 0 16px;
    font-size: 12px;
    font-weight: 900;
`;

const SugorokuNextGoalRemainingCountContainer = styled.div`
    margin: 0 16px 0 14px;
    font-size: 12px;
    font-weight: 900;
    text-align: right;
`;

const MileCount = styled.span`
    font-family: Arial, sans-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 1;
    color: #e40513;
    vertical-align: -1px;
`;

interface PlayerMilestoneProps {
    remainDiceValue: number;
    remainingTiles: number;
    remainingEventTiles: number;
    isFinished: boolean;
    isSugorokuStarted: boolean;
    isSugorokuDisabled: boolean;
    nextEventName: string | null | undefined;
}

const PlayerMilestone: React.FC<PlayerMilestoneProps> = ({
    remainDiceValue,
    remainingTiles,
    remainingEventTiles,
    isFinished,
    isSugorokuStarted,
    isSugorokuDisabled,
    nextEventName,
}) => {
    const calculateRemainingTilesInEventsGoal = (
        remainDiceValue: number,
        remainingTiles: number,
        remainingEventTiles: number
    ) => {
        if (remainingTiles === 2) {
            return 1;
        } else if (remainingTiles === 1) {
            return 0;
        } else {
            return remainingEventTiles + remainDiceValue;
        }
    };

    return (
        <PlayerMilestoneContainer>
            <GoalTitleText>次の目標</GoalTitleText>
            <GoalNameText>
                {isFinished
                    ? 'ゴールおめでとう 🎉'
                    : !isSugorokuStarted || isSugorokuDisabled
                      ? '-'
                      : nextEventName}
            </GoalNameText>
            <SugorokuNextGoalRemainingCountContainer>
                あと{' '}
                <MileCount>
                    {isFinished
                        ? 0
                        : !isSugorokuStarted || isSugorokuDisabled
                          ? '-'
                          : calculateRemainingTilesInEventsGoal(
                                remainDiceValue,
                                remainingTiles,
                                remainingEventTiles
                            )}
                </MileCount>{' '}
                マス
            </SugorokuNextGoalRemainingCountContainer>
        </PlayerMilestoneContainer>
    );
};

export default PlayerMilestone;
