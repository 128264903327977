import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import stadium from '../assets/images/stadium_icon.svg';
import EventType from '../types/event';
import DateFormatter from '../lib/dateFormatter';
import { EventName } from './EventName';

// イベントのスタイルを定義
const EventContent = styled.div`
    display: flex;
    column-gap: 20px;
    align-items: center;
    padding: 18px;
    font-size: 14px;
    line-height: 18px;
    background-color: #ffffff;
`;

export const EventContainer = styled.div`
    width: 100%;
    height: auto;
    margin-top: 40px;
    background-color: #ffffff;
`;

const EventNameLabel = styled.span`
    font-size: 12px;
`;

/**
 * イベントのコンポーネント
 * @param event イベント情報
 * @returns イベントのコンポーネント
 */
const Event: React.FC<EventType> = (event: EventType) => {
    const eventDate: string = DateFormatter.formatDate(event.date);
    const eventName: string = event.name;

    return (
        <EventContent>
            <img src={stadium} />
            <span>{eventDate}</span>
            <EventNameLabel>
                <EventName name={eventName} />
            </EventNameLabel>
        </EventContent>
    );
};

// 型のバリデーションを設定、必須かどうかを指定できる
Event.propTypes = {
    date: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
};

export default Event;
