import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import HeaderLogo from './HeaderLogo';
import HamburgerMenu from './HamburgerMenu';
import BellIcon from './BellIcon';
import { getUnreadNotificationCount } from './api/getUnreadNotificationCount';

// ヘッダーのスタイルを定義
const HeaderContainer = styled.div`
    position: sticky;
    z-index: 20;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: #000000;
`;

/**
 * ヘッダーの共通コンポーネント
 * @returns ヘッダーの共通コンポーネント
 */
const Header: React.FC = () => {
    const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
    const location = useLocation();

    useEffect(() => {
        const fetchUnreadNotificationCount = async () => {
            try {
                const count = await getUnreadNotificationCount();
                setUnreadNotificationCount(count);
            } catch (error) {
                console.error(
                    'Error fetching unread notification count:',
                    error
                );
            }
        };

        fetchUnreadNotificationCount();
    }, [location]);

    return (
        <HeaderContainer>
            <HamburgerMenu />
            <HeaderLogo />
            <BellIcon unreadNotificationCount={unreadNotificationCount} />
        </HeaderContainer>
    );
};

export default Header;
