import React from 'react';
import Dialog from '../Dialog';
import doleWakuwaku from '../../assets/images/dole_wakuwaku.svg';
import { useDialog } from '../UseDialog';
import styled from 'styled-components';
import { SugorokuItem } from '../../types/sugorokuMasterType';

/**
 * ダイアログ画像をラップするコンポーネント
 */
const DialogImageWrapper = styled.div`
    position: absolute;
    top: -85px;
    left: 50%;
    transform: translateX(-50%);
`;

const DialogImage = styled.img`
    width: 183px;
`;

const DialogTextContainer = styled.div`
    display: grid;
    row-gap: 10px;
    text-align: center;
`;

const DialogMainText = styled.span`
    font-size: 22px;
    font-weight: 900;
    line-height: 30px;
    color: #d71920;
`;

const DialogSubText = styled.span`
    font-size: 16px;
    font-weight: 900;
    line-height: 30px;
`;

const StyledButton = styled.button`
    width: 200px;
    padding: 13px 0;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 900;
    color: #000000;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid #000000;
    border-radius: 25px;

    /* アクセシビリティ的によくないが、最初からネガティブな選択肢にフォーカスがあたっているのが気に食わないので… */
    &:focus-visible {
        outline: none;
    }
`;

const OkButton = styled(StyledButton)`
    font-weight: bold;
    color: white;
    background-color: #d71920;
    border: 1px solid #d71920;
`;

interface PkDialogProps {
    nextEventItem: SugorokuItem | null;
    handlePkDialogButtonClick: () => void;
}

const PkDialog: React.FC<PkDialogProps> = ({
    nextEventItem,
    handlePkDialogButtonClick,
}) => {
    const { ref } = useDialog();

    return (
        <Dialog
            ref={ref}
            isOpen={true}
            DialogImageWrapper={
                <DialogImageWrapper>
                    <DialogImage src={doleWakuwaku} alt="dolekun" />
                </DialogImageWrapper>
            }
            closeModal={() => {}}
            showCloseButton={false}
        >
            <DialogTextContainer>
                <DialogMainText>
                    {nextEventItem?.clubPlayerPresentationName
                        ? `${nextEventItem?.clubPlayerPresentationName}の㊙情報`
                        : 'アイテム'}
                    <br />
                    獲得のチャンス！
                </DialogMainText>
                <DialogSubText>3つの的からアタリを選ぼう！</DialogSubText>
            </DialogTextContainer>
            <OkButton onClick={handlePkDialogButtonClick}>
                ミニゲームへ進む
            </OkButton>
        </Dialog>
    );
};

export default PkDialog;
