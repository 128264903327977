import { AxiosResponse } from 'axios';
import { createAxiosInstance } from '../lib/createAxiosInstance';
import { API_PATHS } from './constants/apiPaths';
import { GetUnreadNotificationCount } from './types/getUnreadNotificationCount';

/**
 * 未読お知らせ件数取得APIを呼び出す
 * @returns {number} 未読状態のお知らせ数
 * @throws {Error} API呼び出しに失敗した場合にエラーを投げる
 */
export const getUnreadNotificationCount = async (): Promise<number> => {
    try {
        const instance = createAxiosInstance();
        const response: AxiosResponse = await instance.get(
            API_PATHS.GET_UNREAD_NOTIFICATION_COUNT
        );
        const responseBody: GetUnreadNotificationCount = response.data ?? {};
        return responseBody.unread_counts ?? 0;
    } catch (error) {
        console.error('Error fetching unread notification:', error);
        throw error;
    }
};
