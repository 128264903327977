import AccountSugorokuProgress from '../types/accountSugorokuType';
import { createAxiosInstance } from '../lib/createAxiosInstance';
import GetAccountSugorokuProgressResponseBody from './types/getAccountSugorokuProgressType';
import { accountSugorokuProgressValidator } from './validators/accountSugorokuProgressValidator';
import { API_PATHS } from './constants/apiPaths';
import { AxiosResponse } from 'axios';

/**
 * すごろく進行情報APIを呼び出す
 * @returns {ClubAd} すごろく進行情報 or null
 * @throws {Error} すごろく進行情報取得失敗時にエラーを投げる
 */
export const getAccountSugorokuProgress =
    async (): Promise<AccountSugorokuProgress> => {
        try {
            const instance = createAxiosInstance();
            const response: AxiosResponse = await instance.get(
                API_PATHS.GET_ACCOUNT_SUGOROKU_PROGRESS
            );
            const responseBody: GetAccountSugorokuProgressResponseBody =
                response.data ?? {};
            // 特にキャストしたいとかそういうこともないので、レスポンスをそのまま使う
            const accountSugorokuProgress: AccountSugorokuProgress =
                responseBody;
            const isValid = accountSugorokuProgressValidator(
                accountSugorokuProgress
            );
            if (!isValid) {
                throw Error;
            }
            return accountSugorokuProgress;
        } catch (error) {
            console.error('Error fetching account sugoroku progress:', error);
            throw error;
        }
    };
