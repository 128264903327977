import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import iconExternalLink from './assets/images/icon-externallink.svg';
import { EXTERNAL_LINKS } from './constants/externalLinks';
import useLogout from './hooks/useLogout';
import arrow from './assets/images/whitearrowhead_right.svg';
import { getJLeagueLogoutUrl } from './api/getJleagueLogoutUrl';
import { GA4_CUSTOM_EVENT } from './constants/ga4CustomEvent';
import { ga4PushEvent } from './ga4';

export const StyledBurger = styled.button<{ open: boolean }>`
    position: relative;
    top: 25%;
    left: 1.5rem;
    z-index: 11;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 20px;
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: none;

    span {
        width: 30px;

        /* なぜ2pxじゃなくて2.4pxなのかというと、2.4pxだメニューボタンが綺麗に見えるからです */
        height: ${({ open }) => (open ? '2.4px' : '1px')};
        background-color: ${({ open }) => (open ? '#f3f3f3' : '#f3f3f3')};
        border-radius: 10px;
        transition:
            transform 0.3s ease,
            opacity 0.3s ease;
        transform-origin: 1rem;

        &:first-child {
            transform: ${({ open }) =>
                open ? 'rotate(47deg) translateY(11px)' : 'rotate(0)'};
        }

        &:nth-child(2) {
            opacity: ${({ open }) => (open ? '0' : '1')};
            transform: ${({ open }) =>
                open ? 'translateX(20px)' : 'translateX(0)'};
        }

        &:last-child {
            transform: ${({ open }) =>
                open ? 'rotate(-49deg) translateY(-11px)' : 'rotate(0)'};
        }
    }
`;

interface MenuProps {
    open: boolean;
}

const Menu = styled.div<MenuProps>`
    position: fixed;
    top: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
    background-color: rgb(0 0 0 / 80%);
    transition:
        transform 0.08s ease-in-out,
        visibility 0.02s ease-in-out;
    transform: ${(props) =>
        props.open ? 'translateX(0px)' : 'translateX(-100%)'};

    @media (width >= 768px) {
        width: 40%;
    }
`;

const MenuItem = styled(Link)`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px;
    font-size: 20px;
    font-weight: bold;
    color: white;
    text-decoration: none;
    border-bottom: 1px solid #d9e0e8;

    &:first-child {
        margin-top: 70px;
    }

    &:hover {
        background-color: rgb(255 255 255 / 10%);
    }

    &::after {
        position: fixed;
        right: 5%;
        width: 7.5px;
        content: url(${arrow});
    }
`;

// 外部リンクへ遷移する画像のスタイル
const LinkImage = styled.img`
    width: 18px;
    height: 12px;
    margin: 0 3px;
    fill: white;
`;

const HamburgerMenu: React.FC = () => {
    const logout = useLogout();

    const handleLogout = () => {
        ga4PushEvent(GA4_CUSTOM_EVENT.PRESSED_LOGOUT_BUTTON);
        logout();
    };

    const [isOpen, setIsOpen] = useState(false);
    const node = useRef<HTMLDivElement>(null);

    const handleClickOutside = (e: MouseEvent) => {
        if (node.current && !node.current.contains(e.target as Node)) {
            setIsOpen(false);
        }
    };

    const handleBurgerClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        setIsOpen(!isOpen);
    };

    /**
     * JリーグID認証基盤からログアウトする
     */
    const handleJLeagueLogout = async () => {
        ga4PushEvent(GA4_CUSTOM_EVENT.PRESSED_ACCOUNT_SWITCH_LINK);
        try {
            const logoutUrl = await getJLeagueLogoutUrl();
            window.location.href = logoutUrl;
        } catch (error) {
            // とりあえずログアウトはさせておく？
            console.error('Failed to logout:', error);
            logout();
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <>
            <StyledBurger open={isOpen} onClick={handleBurgerClick}>
                <span />
                <span />
                <span />
            </StyledBurger>
            <Menu open={isOpen} ref={node}>
                <MenuItem
                    to={EXTERNAL_LINKS.NFT_PASSPORT_BASE_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() =>
                        ga4PushEvent(
                            GA4_CUSTOM_EVENT.PRESSED_CONFT_ANNOUNCE_LINK
                        )
                    }
                >
                    CONFTとは
                    <LinkImage src={iconExternalLink} />
                </MenuItem>
                <MenuItem
                    to={EXTERNAL_LINKS.NFT_PASSPORT_FAQ_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() =>
                        ga4PushEvent(GA4_CUSTOM_EVENT.PRESSED_FAQ_LINK)
                    }
                >
                    FAQ
                    <LinkImage src={iconExternalLink} />
                </MenuItem>
                <MenuItem
                    to={EXTERNAL_LINKS.SUPPORT_EMAIL}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    お問い合わせ
                    <LinkImage src={iconExternalLink} />
                </MenuItem>
                <MenuItem to="#" onClick={handleLogout}>
                    ログアウト
                </MenuItem>
                <MenuItem to="#" onClick={handleJLeagueLogout}>
                    アカウント切り替え
                </MenuItem>
            </Menu>
        </>
    );
};

export default HamburgerMenu;
