import styled from 'styled-components';
import ground from '../assets/images/bg_ground.png';
import bric_wall from '../assets/images/bric_wall.svg';

const Container = styled.div`
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    min-height: calc(100dvh - 48px - 82px);
    padding: 0 20px;
    margin: 0 auto;
    overflow: hidden;
`;

const BricWall = styled.div`
    position: absolute;
    width: 100%;
    height: 20%;
    overflow: hidden;
    background-image: url(${bric_wall});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
`;

const Ground = styled.div`
    position: absolute;
    bottom: -5%;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 85%;
    background-image: url(${ground});
    background-position: center;
    background-size: cover;
`;

const Goal = styled.div`
    position: absolute;
    top: 35px;
    z-index: 1;
`;

const HorizontalLine = styled.div`
    position: absolute;
    top: 98%;
    left: -28px;
    z-index: 2;
    width: 118%;
    height: 2px;
    background-color: white;
`;

const TargetsRow = styled.span`
    position: absolute;
    top: 48px;
    left: 50%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110%;
    transform: translateX(-50%);
`;

const Target = styled.img`
    position: relative;
    z-index: 1;
    width: 26%;
`;

export {
    Container,
    BricWall,
    Ground,
    Goal,
    HorizontalLine,
    TargetsRow,
    Target,
};
