export const COOKIE_KEYS = {
    // JWT
    API_TOKEN: 'api_token',
    REFRESH_TOKEN: 'refresh_token',
    NONCE: 'nonce',
    PUSH_NOTIFICATION_DIALOG_CLOSED: 'push_notification_dialog_closed',
    PUSH_NOTIFICATION_PERMISSION: 'push_notification_permission',
};

/**
 * deleteAllCookiesの実行時に削除対象外とするCookieのキー
 */
export const PRESERVED_COOKIE_KEYS = [
    COOKIE_KEYS.PUSH_NOTIFICATION_DIALOG_CLOSED,
    COOKIE_KEYS.PUSH_NOTIFICATION_PERMISSION,
];

/**
 * Cookieの有効期限（日数）
 */
export const COOKIE_EXPIRATION_DAYS = {
    [COOKIE_KEYS.PUSH_NOTIFICATION_DIALOG_CLOSED]: 730,
    [COOKIE_KEYS.PUSH_NOTIFICATION_PERMISSION]: 730,
};
