import CookieManager from './cookieManager';
import { PATH_MAPPING } from '../constants/pathMapping';

/**
 * JリーグIDログインに関するユーティリティ
 * @example
 * // ログインURLの取得
 * const loginUrl: string = JLeagueIdLoginUtility.getRequestUrl('oauth/authorize');
 * // 新規登録URLの取得
 * const signupUrl: string = JLeagueIdLoginUtility.getRequestUrl('signup');
 */
class JLeagueIdLoginUtility {
    /**
     * stateをCookieに設定する
     */
    private static setStateCookie(): void {
        const state = crypto.randomUUID();
        CookieManager.setCookieValue('app-state', state);
    }

    /**
     * リダイレクトURL（ログイン・新規登録後に戻るURL）を取得する
     * @return リダイレクトURL
     */
    static getRedirectUrl(): string {
        const rootUrl = window.location.origin;
        return new URL(PATH_MAPPING.OPENID_CALLBACK, rootUrl).toString();
    }

    /**
     * JリーグIDに対するリクエストに必要なクエリを生成する
     * @param redirectUri リダイレクトURL
     * @return クエリ
     */
    private static getQuery(redirectUri: string): URLSearchParams {
        const query = new URLSearchParams({
            client_id: import.meta.env.VITE_JLEAGUE_CLIENT_ID,
            state: CookieManager.getCookieValue('app-state') || '',
            redirect_uri: redirectUri.toString(),
            response_type: import.meta.env.VITE_JLEAGUE_RESPONSE_TYPE,
        });
        return query;
    }

    /**
     * Jリーグに対するリクエストURLを生成する
     * @params string 操作を行うパス
     * @return リクエストURL
     */
    static getRequestUrl(path: string): string {
        this.setStateCookie();
        const redirectUri = this.getRedirectUrl();
        const query = this.getQuery(redirectUri);
        const requestUrl = new URL(path, import.meta.env.VITE_JLEAGUE_BASE_URL);
        const requestUrlWithQuery = `${requestUrl.toString()}?${query.toString()}`;
        return requestUrlWithQuery;
    }
}

export default JLeagueIdLoginUtility;
