/**
 * 非同期関数を実行し、最低でもwaitMilliseconds秒待機させる
 *
 * @param asyncFunction Promiseを待つ非同期関数
 * @param waitMilliseconds 最低待機時間（ミリ秒）
 * @returns 非同期関数の実行結果
 */
export const waitAsyncFunctionExecuted = async <T>(
    asyncFunction: () => Promise<T>,
    waitMilliseconds: number
): Promise<T> => {
    const startTime = Date.now();
    const result = await asyncFunction();
    const endTime = Date.now();
    const elapsedTime = endTime - startTime;
    const remainingTime = Math.max(0, waitMilliseconds - elapsedTime);
    if (remainingTime > 0) {
        await new Promise((resolve) => setTimeout(resolve, remainingTime));
    }
    return result;
};
