import jLeagueLogoutUrlType from '../types/jLeagueLogoutUrl';
import { createAxiosInstance } from '../lib/createAxiosInstance';
import { API_PATHS } from './constants/apiPaths';
import { jLeagueLogoutUrlValidator } from './validators/jLeagueLogoutUrlValidator';
import { AxiosResponse } from 'axios';

/**
 * JリーグログアウトURL取得APIを呼び出す
 * @returns JリーグログアウトURL
 * @throws JリーグログアウトURL取得失敗時にエラーを投げる
 */
export const getJLeagueLogoutUrl = async (): Promise<string> => {
    try {
        const instance = createAxiosInstance();
        const response: AxiosResponse = await instance.get(
            API_PATHS.GET_JLEAGUE_LOGOUT_URL
        );
        const jLeagueLogoutUrlData: jLeagueLogoutUrlType = response.data ?? {};

        if (jLeagueLogoutUrlValidator(jLeagueLogoutUrlData)) {
            return jLeagueLogoutUrlData.jleague_logout_url;
        } else {
            console.error('Invalid jLeague Logout URL:', jLeagueLogoutUrlData);
            throw Error;
        }
    } catch (error) {
        console.error('Error fetching JLeague Logout URL:', error);
        throw error;
    }
};
