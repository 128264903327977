import React, { useRef, useEffect } from 'react';
import ReactDice, { ReactDiceRef } from 'react-dice-complete';
import styled from 'styled-components';

const FullScreenDiceContainer = styled.div`
    position: fixed;
    top: 40%;
    left: 50%;
    z-index: 6;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);
`;

interface DiceRollProps {
    handleRoll: (totalValue: number) => void;
    diceValue: number;
    isRollingDice: boolean;
    setIsRollingDice: React.Dispatch<React.SetStateAction<boolean>>;
}

const DiceRoll: React.FC<DiceRollProps> = ({
    diceValue,
    isRollingDice,
    setIsRollingDice,
}) => {
    const reactDice = useRef<ReactDiceRef>(null);

    const rollDone = () => {
        if (isRollingDice) {
            setIsRollingDice(false);
        }
    };

    useEffect(() => {
        const rollAll = () => {
            reactDice.current?.rollAll([diceValue]);
        };
        if (isRollingDice) {
            rollAll();
        }
    }, [isRollingDice, diceValue]);

    return (
        <FullScreenDiceContainer>
            <ReactDice
                numDice={1}
                ref={reactDice}
                rollDone={rollDone}
                outline={true}
                faceColor="white"
                dotColor="black"
                disableIndividual={true}
                disableRandom={false}
                dieSize={120}
                rollTime={1}
                dieCornerRadius={10}
                outlineColor="grey"
            />
        </FullScreenDiceContainer>
    );
};

export default DiceRoll;
