import { createAxiosInstance } from '../lib/createAxiosInstance';
import { API_PATHS } from './constants/apiPaths';
import { AxiosResponse } from 'axios';
import PostStageEventResultResponseBody from './types/PostStageEventType';
import stageEventResult from '../types/stageEventResult';
import { postStageEventResultValidator } from './validators/postStageEventResultValidator';

/**
 * ステージイベント結果取得APIを呼び出す
 * @param isFirstRequest ステージイベントのリクエストパラメータ
 * @returns ステージイベントの結果
 * @throws {Error} ステージイベントの処理中にエラーが発生した場合、エラーを投げる
 */
export const postStageEvent = async (
    isFirstRequest: boolean
): Promise<stageEventResult> => {
    try {
        const instance = createAxiosInstance();
        const response: AxiosResponse = await instance.post(
            API_PATHS.POST_STAGE_EVENT,
            {
                is_first_request: isFirstRequest,
            }
        );
        const responseBody: PostStageEventResultResponseBody =
            response.data ?? {};
        const result: stageEventResult = {
            isStageEventSuccess: responseBody.is_stage_event_success,
            beforeSugorokuStageCode: responseBody.before_sugoroku_stage_code,
            afterSugorokuStageCode: responseBody.after_sugoroku_stage_code,
            beforeCell: responseBody.before_cell,
            afterCell: responseBody.after_cell,
            remainDice: responseBody.remain_dice,
            isFinished: responseBody.is_finished,
        } as stageEventResult;

        const isValid = postStageEventResultValidator(result);
        if (!isValid) {
            throw new Error('Invalid StageEventResult');
        }
        return result;
    } catch (error) {
        console.error('Stage Event failed:', error);
        throw error;
    }
};
