import { defer, redirect } from 'react-router-dom';
import { getNotificationDetail } from '../api/getNotificationDetail';
import { NotificationDetailLoaderParams } from '../types/notificationDetailLoaderParams';
import { PATH_MAPPING } from '../constants/pathMapping';

/**
 * Notification詳細画面のローダー
 * @returns Notification詳細画面で使うデータ
 */
export const notificationDetailLoader = async ({
    params,
}: {
    params: NotificationDetailLoaderParams;
}) => {
    try {
        const { notificationId } = params;
        // Ensure `notificationId` is correctly passed and a number
        if (!notificationId || isNaN(notificationId)) {
            throw new Error('Notification ID is not provided or invalid.');
        }
        const notification = getNotificationDetail(notificationId);
        return defer({ notification: notification });
    } catch (error) {
        console.error('Error fetching notification detail:', error);
        if (error instanceof Error) {
            // Redirect to an error page or handle the error in a different way
            return redirect(
                `${PATH_MAPPING.ERROR}?message=${encodeURIComponent(error.message)}`
            );
        } else {
            // Redirect to an error page or handle the error in a different way
            return redirect(
                `${PATH_MAPPING.ERROR}?message=Unknown error occurred`
            );
        }
    }
};
