import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { EXTERNAL_LINKS } from '../constants/externalLinks';
import { ga4PushEvent } from '../ga4';
import { GA4_CUSTOM_EVENT } from '../constants/ga4CustomEvent';

// 画像のインポート
import conftTitle from '../assets/images/conft-title.svg';
import dolekunEyes from '../assets/images/dolekun-eyes.svg';
import emblem_bw from '../assets/images/emblem_bw.svg';
import JLeagueAuthentication from '../components/JLeagueAuthentication';
import Dialog from '../components/Dialog';
import { useDialog } from '../components/UseDialog';
import CookieManager from '../lib/cookieManager';
import dolekunDauntingPoseImage from '../assets/images/dolekun-daunting-pose.svg';
import iconExternalLink from '../assets/images/icon-externallink.svg';
import { LoadingSpinnerOverlay } from '../Spinner';
import SessionStorageManager from '../lib/sessionStorageManager';
import { TokenManager } from '../lib/tokenManager';
import { PATH_MAPPING } from '../constants/pathMapping';

// Wrapper コンポーネント: 画面全体のラッパーを定義
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100dvh;
    color: #ffffff;
    background-color: #000000;
`;

// Container コンポーネント: 各コンポーネントを集約するコンテナを定義
const Container = styled.div`
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
    min-height: 100dvh;
    padding: 0 20px 20px;
`;

const Border = styled.div`
    position: absolute;
    top: 0;
    right: 44px;
    z-index: -1;
    width: 60px;
    height: 100%;
    background: repeating-linear-gradient(
        90deg,
        black,
        black 6px,
        #cb000b 6px,
        #cb000b 12px
    );
`;

// ロゴのスタイルを定義する ConsadoleLogo コンポーネント
const ConsadoleLogoContainer = styled.div`
    img {
        width: 84.48px;
        height: 92px;
    }

    @media (width < 768px) {
        margin-top: 40px;
    }

    margin-top: 107.15px;
`;

// 会社名のスタイルを定義する CompanyName コンポーネント
const CompanyName = styled.span`
    margin-top: 4px;
    font-family: Outfit, sans-serif;
    font-size: 8px;
    font-weight: normal;
`;

// タイトルのスタイルを定義する ConftTitle コンポーネント
const ConftTitleContainer = styled.div`
    margin-top: 60px;

    img {
        width: 273.3px;
        height: 48px;
    }
`;

// リンクテキストのスタイルを定義する LinkText コンポーネント
const LinkText = styled(Link)`
    color: #ffffff;
`;

// ドーレくんの眼の画像スタイルを定義する DolekunEyes コンポーネント
const DolekunEyesContainer = styled.div`
    margin-top: 47.93px;
    margin-bottom: 9.93px;
`;

// 著作権表示のスタイルを定義する Copyright コンポーネント
const CopyrightContainer = styled.div`
    margin-top: 30px;
    font-size: 8px;
    color: #8d8d8d;
`;

const LoginFailedDialogImageWrapper = styled.div`
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
`;

const DialogImage = styled.img`
    width: 70px;
`;

// ダイアログのメッセージをラップする
const DialogMessageWrapper = styled.span`
    font-weight: bold;
`;

// 外部リンクへ遷移する画像のスタイル
const LinkImage = styled.img`
    width: 16px;
    height: 12px;
    margin: 0 3px;

    /* #ffffff に相当するfilter */
    filter: brightness(0) saturate(100%) invert(85%) sepia(96%) saturate(1%)
        hue-rotate(199deg) brightness(109%) contrast(100%);
`;

/**
 * トップページ
 * @returns トップページ
 */
const Top: React.FC = () => {
    const { ref, closeModal } = useDialog();
    const [showLoader, setShowLoader] = useState(true);
    const [showDialog, setShowDialog] = useState(false);
    const location = useLocation();
    const message = location.state?.message || '';
    const waitTimeMilliseconds = 1000;
    const navigate = useNavigate();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (!initialized) {
            ga4PushEvent(GA4_CUSTOM_EVENT.DISPLAY_TOP_PAGE);
        }
        setInitialized(true);
    }, [initialized]);

    useEffect(() => {
        if (TokenManager.getAccessToken()) {
            navigate(PATH_MAPPING.CONNECT_TO_WEB3AUTH);
            return;
        }
        CookieManager.deleteAllCookies();
        SessionStorageManager.deleteAllSessionStorageValues();
        // 1秒待ってからローダーを非表示にしてダイアログを表示する
        setTimeout(() => {
            setShowLoader(false);
            setShowDialog(true);
        }, waitTimeMilliseconds);
    }, [navigate]);

    /**
     * 「CONFTとは」リンクをクリックした時にGA4イベントを送信する
     */
    const onClickNftPassportBaseLink = () => {
        ga4PushEvent(GA4_CUSTOM_EVENT.PRESSED_CONFT_ANNOUNCE_LINK);
    };
    return (
        <Wrapper>
            {showLoader && <LoadingSpinnerOverlay opacity={1} />}
            <Container>
                <Border />
                <ConsadoleLogoContainer>
                    <img src={emblem_bw} alt="コンサドーレ ロゴ" />
                </ConsadoleLogoContainer>

                <CompanyName>@1996 CONSADOLE</CompanyName>

                <ConftTitleContainer>
                    <img src={conftTitle} alt="CONFT タイトル" />
                </ConftTitleContainer>

                <JLeagueAuthentication />

                <DolekunEyesContainer>
                    <img src={dolekunEyes} alt="ドーレくんの眼" />
                </DolekunEyesContainer>

                <LinkText
                    to={EXTERNAL_LINKS.NFT_PASSPORT_BASE_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={onClickNftPassportBaseLink}
                >
                    CONFTとは
                    <LinkImage src={iconExternalLink} />
                </LinkText>

                <CopyrightContainer>
                    <span>©︎CONSADOLE Co.,Ltd.All Rights Reserved.</span>
                </CopyrightContainer>
                {message === '' ? null : (
                    <Dialog
                        ref={ref}
                        isOpen={showDialog}
                        DialogImageWrapper={
                            <LoginFailedDialogImageWrapper>
                                <DialogImage
                                    src={dolekunDauntingPoseImage}
                                    alt="dolekun"
                                />
                            </LoginFailedDialogImageWrapper>
                        }
                        closeModal={closeModal}
                    >
                        {/*
                            React.FCが使用できないため、dangerouslySetInnerHTMLを使用します。
                        */}
                        <DialogMessageWrapper
                            dangerouslySetInnerHTML={{ __html: message }}
                        />
                    </Dialog>
                )}
            </Container>
        </Wrapper>
    );
};

export default Top;
