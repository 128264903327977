import { LoadingSpinnerOverlay } from '../Spinner';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { getAccountsInfo } from '../api/getAccountsInfo';
import AccountInfoType from '../types/accountInfo';
import { PATH_MAPPING } from '../constants/pathMapping';
import { generateAccountWallet } from '../lib/web3auth/generateAccountWallet';
import CookieManager from '../lib/cookieManager';
import { COOKIE_KEYS } from '../constants/cookieKeys';
import SaveWalletAddressResult from '../types/saveWalletAddress';
import { postWalletAddress } from '../api/postWalletAddress';
import { getTokenFromNonce } from '../api/getTokenFromNonce';

const ConnectToWeb3Auth: React.FC = () => {
    const navigate = useNavigate();
    useEffect(() => {
        const generateAccountWalletOnFirstLogin = async () => {
            // Note: リダイレクトの都合上、キャッシュの取り回しが難しいため都度取得する。
            // iOSにおいて、ここでCookie保持していたトークンが飛んでしまう。
            // Web3Auth側から戻ってくるときにクエリパラメータについてくるNonceを使ってトークンを取り直す。
            const queryNonce = new URLSearchParams(window.location.search).get(
                'nonce'
            );
            if (queryNonce) {
                try {
                    // トークンをNonceから取って処理を続行
                    await getTokenFromNonce(queryNonce);
                } catch (error) {
                    // エラーにする
                    navigate(PATH_MAPPING.ERROR);
                    return;
                }
            }
            try {
                // アカウント情報を取得
                const accountInfo: AccountInfoType | null =
                    await getAccountsInfo();
                if (accountInfo?.address) {
                    // アドレスが見つかった場合はマイページに遷移
                    navigate(PATH_MAPPING.MY_PAGE);
                    return;
                }
            } catch (error) {
                // ウォレットがない状態でマイページを見れてもいいのかもしれない
                navigate(PATH_MAPPING.MY_PAGE);
                return;
            }
            // クッキーからJWTとNonceを取得
            const jwt: string | undefined = CookieManager.getCookieValue(
                COOKIE_KEYS.API_TOKEN
            );
            const nonce: string | undefined = CookieManager.getCookieValue(
                COOKIE_KEYS.NONCE
            );

            // JWTとNonceを使って新しいアカウントウォレットを生成
            const address: string | undefined = await generateAccountWallet(
                jwt,
                nonce
            );

            if (!address) {
                // Note: エラー画面に遷移させていたが、Web3Auth関連の不具合によってログインできない状況を避けるため、エラー画面に遷移させずにこのまま処理を終了する。
                return;
            }

            // 新しいウォレットアドレスを保存
            const result: SaveWalletAddressResult =
                await postWalletAddress(address);

            if (!result.success) {
                // ウォレットアドレスの保存に失敗した場合はエラーページに遷移
                navigate(PATH_MAPPING.ERROR);
                return;
            }
            // 成功した場合はマイページに遷移
            navigate(PATH_MAPPING.MY_PAGE);
            return;
        };

        // 初回ログイン時にアカウントウォレットを生成する関数を呼び出し
        generateAccountWalletOnFirstLogin();
    }, [navigate]);

    // 処理中にローディングスピナーオーバーレイを表示
    return <LoadingSpinnerOverlay />;
};

export default ConnectToWeb3Auth;
