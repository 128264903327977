import styled from 'styled-components';
import dolekunEyes from './assets/images/dolekun-eyes.svg';

const DolekunEyes = styled.img`
    margin-bottom: 20px;
    opacity: 1;
    animation: blink 2s linear infinite;

    @keyframes blink {
        0%,
        100% {
            opacity: 1;
        }

        50% {
            opacity: 0;
        }
    }
`;

// styled-components を使用して作成した Spinner スタイル
const Spinner = styled.div`
    width: 120px;
    height: 120px;
    border: 16px solid rgb(0 0 0 / 3%);
    border-top: 16px solid red;
    border-radius: 50%;
    animation: spin 2s linear infinite;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
`;

/**
 * ローディング時のスピナーを表示するオーバーレイ
 * @param opacity 背景のオーバーレイの透明度
 */
const LoadingOverlay = styled.div<{ opacity: number }>`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 16px;
    color: white;
    background-color: rgb(0 0 0 / ${(props) => props.opacity});

    ${Spinner} {
        margin-top: 0;
        margin-left: 0;
    }
`;

const LoadingText = styled.span`
    margin-top: 20px;
`;

/**
 * スピナーと背景のオーバーレイを併せ持つコンポーネント
 * @param opacity 背景のオーバーレイの透明度 デフォ0.8
 * @returns コンポーネント
 */
const LoadingSpinnerOverlay: React.FC<{ opacity?: number }> = ({
    opacity = 0.8,
}) => {
    return (
        <LoadingOverlay opacity={opacity}>
            <DolekunEyes src={dolekunEyes} />
            <Spinner />
            <LoadingText>Loading...</LoadingText>
        </LoadingOverlay>
    );
};
export { LoadingSpinnerOverlay, Spinner };
