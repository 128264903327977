import React from 'react';
import styled from 'styled-components';
import SugorokuRewardSvg from '../../assets/images/sugoroku-reward.svg';
import rightArrowWhitePng from '../../assets/images/right_arrow_white.png';

const PlayerListContainer = styled.div<{
    $ableToPush: boolean;
}>`
    position: relative;
    display: flex;
    gap: 4px;
    align-items: center;
    width: 170px;
    height: 50px;
    padding: 0 10px;
    pointer-events: ${(props) => (props.$ableToPush ? 'auto' : 'none')};
    cursor: pointer;
    background-color: #e40513;
    border-radius: 0 45px 45px 0;
    box-shadow: 0 3px 3px 0 rgb(0 0 0 / 16%);
    opacity: ${(props) => (props.$ableToPush ? 1 : 0.3)};
`;

const SugorokuReward = styled.img`
    width: 25px;
    height: 25px;
    margin-bottom: 3px;
    cursor: pointer;
`;

const PlayerListContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: flex-start;
`;

const PlayerListText = styled.span`
    margin-bottom: 1px;
    font-size: 14px;
    font-weight: 900;
    color: white;
`;

const WhiteArrowIcon = styled.img`
    position: absolute;
    right: 10px;
    width: 17px;
    height: 13px;
`;

interface PlayerListComponentProps {
    $ableToPush: boolean;
    handleSugorokuRewardClick: () => void;
    availablePlayers: number;
    allPlayers: number;
    isSugorokuStarted: boolean;
    isSugorokuDisabled: boolean;
}

const PlayerListButtonComponent: React.FC<PlayerListComponentProps> = ({
    $ableToPush,
    handleSugorokuRewardClick,
}) => {
    return (
        <PlayerListContainer
            $ableToPush={$ableToPush}
            onClick={handleSugorokuRewardClick}
        >
            <SugorokuReward src={SugorokuRewardSvg} alt="SugorokuReward" />
            <PlayerListContent>
                <PlayerListText>すごろく特典一覧</PlayerListText>
            </PlayerListContent>
            <WhiteArrowIcon src={rightArrowWhitePng} alt="White Arrow" />
        </PlayerListContainer>
    );
};

export default PlayerListButtonComponent;
