import { useRef, useCallback } from 'react';

// ダイアログの表示・非表示を制御するカスタムフック
export const useDialog = () => {
    // ダイアログのDOMを参照している
    const ref = useRef<HTMLDialogElement>(null);

    const showModal = useCallback(() => {
        if (ref.current) {
            ref.current.showModal();
        }
    }, []);

    const closeModal = useCallback(() => {
        if (ref.current) {
            ref.current.close();
        }
    }, []);

    return { ref, showModal, closeModal };
};
