import { LoadingSpinnerOverlay } from '../Spinner';
import React from 'react';
import useLogout from '../hooks/useLogout';

/**
 * JリーグID認証基盤からのログアウトのハンドリングコンポーネント
 */
const LoggedOut: React.FC = () => {
    const logout = useLogout();
    React.useEffect(() => {
        logout();
    });
    return <LoadingSpinnerOverlay />;
};

export default LoggedOut;
