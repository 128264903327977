import { AppNotificationDetail } from '../../types/AppNotificationDetail';

export const notificationDetailValidator = (
    notification: AppNotificationDetail
): boolean => {
    if (!notification) {
        return false;
    }
    if (!notification.id) {
        return false;
    }
    if (!notification.releaseDateTime) {
        return false;
    }
    if (!notification.type) {
        return false;
    }
    if (!notification.title) {
        return false;
    }
    if (!notification.content) {
        return false;
    }
    if (typeof notification.typeBackgroundColor === 'undefined') {
        return false;
    }
    if (typeof notification.typeTextColor === 'undefined') {
        return false;
    }
    // Check for the presence of previousId and nextId, but they can be undefined
    return true;
};
