import {
    PRESERVED_COOKIE_KEYS,
    COOKIE_EXPIRATION_DAYS,
} from '../constants/cookieKeys';

/**
 * Cookieの取得・設定を行う
 * @example
 * // Cookieの取得
 * const apiToken: string | undefined = CookieManager.getCookieValue(COOKIE_KEYS.API_TOKEN);
 */
class CookieManager {
    static getCookieValue(key: string): string | undefined {
        const cookies = document.cookie.split(';');
        for (const cookie of cookies) {
            const [cookieKey, cookieValue] = cookie
                .split('=')
                .map((c) => c.trim());
            if (cookieKey === key) {
                return cookieValue;
            }
        }
        return undefined;
    }

    /**
     * JSON形式でCookieを取得する
     * ※ キーに合致するCookieが存在しない場合、undefinedが返却される。
     *
     * @param key Cookieのキー
     * @returns JSON形式のCookieもしくはundefined
     */
    static getCookieValueAsJSON(key: string) {
        const cookieValue = this.getCookieValue(key);
        if (cookieValue === undefined) {
            return cookieValue;
        }
        return JSON.parse(cookieValue);
    }

    static setCookieValue(key: string, value: string): void {
        CookieManager.deleteCookieValue(key);
        let cookieString = `${key}=${value}; Secure; SameSite=Lax; Path=/`;
        if (key in COOKIE_EXPIRATION_DAYS) {
            const expiresSecond = COOKIE_EXPIRATION_DAYS[key] * 24 * 60 * 60;
            cookieString = `${cookieString}; Max-Age=${expiresSecond}`;
        }
        document.cookie = cookieString;
    }

    static deleteCookieValue(key: string): void {
        document.cookie = `${key}=; Max-Age=-1; Secure; SameSite=Lax; Path=/`;
    }

    static deleteAllCookies(): void {
        const cookies = document.cookie.split(';');
        for (const cookie of cookies) {
            const cookieKey = cookie.split('=')[0].trim();
            if (PRESERVED_COOKIE_KEYS.includes(cookieKey)) {
                continue;
            }
            CookieManager.deleteCookieValue(cookieKey);
        }
    }
}

export default CookieManager;
