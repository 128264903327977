// OutsideVenueSection.tsx
import React from 'react';
import GoogleMap from './GoogleMap';
import { ButtonLink } from './button';
import Event, { EventContainer } from './Event';
import EventType from '../types/event';
import styled from 'styled-components';
import { EXTERNAL_LINKS } from '../constants/externalLinks';
import iconExternalLink from '../assets/images/icon-externallink.svg';
import { ga4PushEvent } from '../ga4';
import { GA4_CUSTOM_EVENT } from '../constants/ga4CustomEvent';

const OutsideVenueText = styled.div`
    margin-top: 40px;
    font-size: 20px;
    font-weight: bold;
`;

const OutSideVenueSubText = styled.div`
    margin-top: 20px;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    text-align: center;
`;

const ButtonWrapRecheck = styled.div`
    width: 100%;
    margin-top: 40px;

    & > a {
        display: inline-block;
    }
`;

const ButtonWrapFaq = styled.div`
    width: 100%;
    margin-top: 20px;

    & > a {
        display: inline-block;
    }
`;

// 外部リンクへ遷移する画像のスタイル
const LinkImage = styled.img`
    position: absolute;
    top: 50%;
    right: 25px;
    width: 16px;
    height: 12px;
    margin: 0 3px;

    /* #141313 に相当するfilter */
    filter: brightness(0) saturate(100%) invert(5%) sepia(4%) saturate(486%)
        hue-rotate(314deg) brightness(100%) contrast(95%);
    transform: translateY(-50%);
`;

/**
 * 「よくある質問」リンクをクリックした時にGA4イベントを送信する
 */
const onClickFAQLink = () => {
    ga4PushEvent(GA4_CUSTOM_EVENT.PRESSED_NOT_IS_IN_SCOPE_FAQ_LINK);
};

const OutsideVenueSection: React.FC<{
    events: EventType[];
    eventPosition: { lat: number; lng: number } | null;
    currentPosition: { currentlat: number; currentlng: number } | null;
    radius: number | null;
    onRecheck: () => void;
}> = ({ events, eventPosition, currentPosition, radius, onRecheck }) => {
    /**
     * onRecheck(初期化処理)が走る前にGA4イベントを送信する
     */
    const onRecheckFunctionWrapper = () => {
        ga4PushEvent(GA4_CUSTOM_EVENT.PRESSED_CHECK_IN_BUTTON);
        onRecheck();
    };
    return (
        <>
            {events.map((event: EventType, index: number) => (
                <EventContainer key={index}>
                    <Event {...event} />
                </EventContainer>
            ))}
            <OutsideVenueText>会場外エラー</OutsideVenueText>
            <OutSideVenueSubText>
                上記イベントの来場チェックは<br></br>
                スタジアム敷地内でのみ可能です。
            </OutSideVenueSubText>
            <ButtonWrapRecheck>
                <ButtonLink
                    to="#"
                    bgColor="#000000"
                    textColor="#ffffff"
                    onClick={onRecheckFunctionWrapper}
                >
                    再チェック
                </ButtonLink>
            </ButtonWrapRecheck>
            <ButtonWrapFaq>
                <ButtonLink
                    to={EXTERNAL_LINKS.NFT_PASSPORT_OUTSIDE_VENUE_LINK}
                    position="relative"
                    textColor="#141313"
                    borderColor="#000000"
                    lineHeight="22px"
                    onClick={onClickFAQLink}
                    openInNewTab={true}
                >
                    会場内にいるが、<br></br>会場外エラーとなる場合
                    <LinkImage src={iconExternalLink} />
                </ButtonLink>
            </ButtonWrapFaq>
            <GoogleMap
                defaultPosition={
                    eventPosition ? eventPosition : { lat: 0, lng: 0 }
                }
                eventPosition={eventPosition}
                radius={radius}
                currentPosition={currentPosition}
            />
        </>
    );
};

export default OutsideVenueSection;
