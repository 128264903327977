import React from 'react';
import styled from 'styled-components';

const SaikoroContainer = styled.div<{
    $isSugorokuStarted: boolean;
}>`
    position: absolute;
    right: 0;
    bottom: 14px;
    z-index: 4;
    display: flex;
    flex-direction: column;
    width: 320px;
    overflow: hidden;
    filter: ${(props) =>
        props.$isSugorokuStarted ? 'none' : 'brightness(0.7)'};
    border: 3px solid #e40513;
    border-right: 0;
    border-radius: 21px 0 0 21px;
`;

const TopPart = styled.div`
    display: flex;
    gap: 12px;
    align-items: baseline;
    padding: 10px 0 10px 15px;
    font-size: 14px;
    font-weight: 900;
    color: white;
    background-color: #e40513;

    @media (width >= 406px) {
        font-size: 14px;
    }
`;

const RemainingDiceContainer = styled.div`
    display: flex;
    gap: 3px;
    align-items: baseline;
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
`;

const RemainingDiceCount = styled.span`
    font-size: 32px;
`;

const BottomPart = styled.div`
    display: grid;
    gap: 5px;
    align-items: baseline;
    padding: 8px 0 6px 15px;
    font-size: 13px;
    font-weight: 900;
    color: black;
    background-color: white;
`;

const BottomPartInformationContainer = styled.div`
    display: flex;
    gap: 10px;
`;

const BottomPartInformation = styled.div`
    display: flex;
    gap: 6px;
    align-items: baseline;
`;

const MileCount = styled.span`
    font-family: Arial, sans-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 1;
    color: #e40513;
    vertical-align: -1px;
`;

interface SaikoroContainerProps {
    isSugorokuStarted: boolean;
    diceCount: number | null;
    tilePosition: number;
    remainingTiles: number;
    totalCells: number;
    isFinished: boolean;
}

const SaikoroContainerComponent: React.FC<SaikoroContainerProps> = ({
    isSugorokuStarted,
    diceCount,
    tilePosition,
    remainingTiles,
    totalCells,
    isFinished,
}) => {
    /**
     * これまでに進んだマスと今回進むマスを足し合わせる
     * @param tilePosition 現在いるマス数
     * @param remainingTiles 残りのマス数
     * @param totalCells 総マス数
     * @returns たされたマス数
     */
    const calculateTraversedCells = (
        totalCells: number,
        tilePosition: number,
        remainingTiles: number
    ) => {
        if (remainingTiles === 2) {
            return tilePosition + 2;
        } else if (remainingTiles === 1) {
            if (tilePosition + 3 > totalCells) {
                return tilePosition + 2;
            } else {
                return tilePosition + 3;
            }
        } else {
            return tilePosition + 1;
        }
    };

    //最終の残りの3マス数の表示値を計算する関数には条件を追加(remainingTiles === 2&&remainingTiles === 1追加.
    //最終の３マスにはtilePosition無効なため
    const calculateRemainingTiles = (
        totalCells: number,
        tilePosition: number,
        remainingTiles: number
    ) => {
        if (remainingTiles === 2) {
            return 1;
        } else if (remainingTiles === 1) {
            return 0;
        } else {
            const distance = Math.abs(totalCells - tilePosition - 1);
            return distance;
        }
    };

    return (
        <SaikoroContainer $isSugorokuStarted={isSugorokuStarted}>
            <TopPart>
                サイコロ残数
                <RemainingDiceContainer>
                    <RemainingDiceCount>
                        {isFinished || !isSugorokuStarted ? '-' : diceCount}
                    </RemainingDiceCount>
                    個
                </RemainingDiceContainer>
            </TopPart>
            <BottomPart>
                選手の地元を巡っていこう!
                <BottomPartInformationContainer>
                    <BottomPartInformation>
                        現在地
                        <div>
                            <MileCount>
                                {isFinished
                                    ? tilePosition + 1
                                    : !isSugorokuStarted
                                      ? '-'
                                      : calculateTraversedCells(
                                            totalCells,
                                            tilePosition,
                                            remainingTiles
                                        )}
                            </MileCount>{' '}
                            マス目
                        </div>
                    </BottomPartInformation>
                    <BottomPartInformation>
                        残りマス
                        <div>
                            <MileCount>
                                {isFinished
                                    ? 0
                                    : !isSugorokuStarted
                                      ? '-'
                                      : calculateRemainingTiles(
                                            totalCells,
                                            tilePosition,
                                            remainingTiles
                                        )}
                            </MileCount>{' '}
                            マス
                        </div>
                    </BottomPartInformation>
                </BottomPartInformationContainer>
            </BottomPart>
        </SaikoroContainer>
    );
};

export default SaikoroContainerComponent;
