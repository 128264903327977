import JLeagueAccountInfoType from '../types/jLeagueAccountInfo';
import { createAxiosInstance } from '../lib/createAxiosInstance';
import { API_PATHS } from './constants/apiPaths';
import { jLeagueAccountInfoValidator } from './validators/jLeagueaccountInfoValidator';
import { AxiosResponse } from 'axios';

/**
 * Jリーグ会員情報取得APIを呼び出す
 * @returns Jリーグ会員情報
 * @throws 会員情報取得失敗時にエラーを投げる
 */
export const getJLeagueAccountInfo =
    async (): Promise<JLeagueAccountInfoType | null> => {
        try {
            const instance = createAxiosInstance();
            const response: AxiosResponse = await instance.get(
                API_PATHS.GET_JLEAGUE_ACCOUNT_INFO
            );
            const accountInfo: JLeagueAccountInfoType =
                response.data?.jleague_account_info;

            if (jLeagueAccountInfoValidator(accountInfo)) {
                return accountInfo;
            } else {
                console.error('Invalid account information:', accountInfo);
                return null;
            }
        } catch (error) {
            console.error('Error fetching Account Information:', error);
            throw error;
        }
    };
