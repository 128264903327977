import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import goal from '../assets/images/goal.svg';
import football from '../assets/images/football.svg';
import target from '../assets/images/target.svg';
import gesture from '../assets/images/gesture.svg';
import arrow from '../assets/images/arrow.svg';
import strike_dole from '../assets/images/strikes_dole.png';
import PkScreenGoal from '../components/PkScreenGoal';
import PkScreenFail from '../components/PkScreenFail';
import GoalScreen from './GoalScreen';
import stageEventResult from '../types/stageEventResult';
import { postStageEvent } from '../api/postStageEvent';
import { SugorokuItem } from '../types/sugorokuMasterType';
import { waitAsyncFunctionExecuted } from '../lib/asyncUtils';
import PlayerDetail from '../components/PlayerDetail';
import {
    Container,
    BricWall,
    Ground,
    Goal,
    HorizontalLine,
    TargetsRow,
    Target,
} from './SharedScreenElements';
import SessionStorageManager from '../lib/sessionStorageManager';
import { SESSION_STORAGE_KEYS } from '../constants/sessionStorageKeys';
import { SugorokuErrorDialog } from './SugorokuErrorDialog';
import { ga4PushEvent } from '../ga4';
import { GA4_CUSTOM_EVENT } from '../constants/ga4CustomEvent';

const Football = styled.img<{ $isStageEventSuccess: boolean | null }>`
    position: absolute;
    bottom: 20%;
    z-index: 4;
    display: ${({ $isStageEventSuccess }) =>
        $isStageEventSuccess === null
            ? 'block'
            : $isStageEventSuccess
              ? 'none'
              : 'block'};
    width: 30%;
`;

const Gesture = styled.img`
    position: absolute;
    bottom: 14%;
    z-index: 4;
    width: 20%;
    pointer-events: none;
    opacity: 0.3;
    animation: gesture-animation 1s infinite;

    @keyframes gesture-animation {
        0% {
            transform: translateY(0);
        }

        50% {
            transform: translateY(50%);
        }

        100% {
            transform: translateY(0);
        }
    }
`;

const GestureMessage = styled.div`
    position: absolute;
    top: 45%;
    left: 50%;
    z-index: 3;
    width: 242px;
    font-size: 22px;
    font-weight: 900;
    color: white;
    text-align: center;
    text-shadow: 2px 2px 1px rgb(0 0 0 / 10%);
    transform: translateX(-50%);

    .highlight {
        color: #fd0b14;
    }
`;

const Arrow = styled.img<{
    $isStageEventSuccess: boolean | null;
    direction: 'left' | 'top' | 'right' | null;
}>`
    position: absolute;
    top: 50%;
    z-index: 1;
    display: ${({ direction }) => (!direction ? 'none' : 'block')};
    width: 50%;
    opacity: 0.7;
    transition: transform 0.3s ease-in-out;
`;

const StrikeDoleFrame = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const StrikeDoleAnimation = styled.div`
    position: absolute;
    z-index: 5;
    animation: zoom-in-animation 3s ease-in-out forwards;

    @keyframes zoom-in-animation {
        0% {
            transform: scale(0.8);
        }

        100% {
            transform: scale(1);
        }
    }
`;

const StrikeDole = styled.img`
    width: 100%;
`;

interface PkScreenProps {
    nextEventItem?: SugorokuItem;
    isFirstRequest?: boolean;
}

const PkScreen: React.FC<PkScreenProps> = ({
    nextEventItem,
    isFirstRequest = true,
}) => {
    const [hasError, setHasError] = useState(false);
    const [clubPlayerCode, setClubPlayerCode] = useState<string | null>(null);

    const [direction, setDirection] = useState<'left' | 'right' | 'top' | null>(
        null
    );
    const [showStrikeFrame, setShowStrikeFrame] = useState(false);

    const footballRef = useRef<HTMLImageElement>(null);
    const initialPosition = useRef<{ x: number; y: number } | null>(null);
    const [isStageEventSuccess, setIsStageEventSuccess] = useState<
        boolean | null
    >(null);
    const [ballHeight, setBallHeight] = useState(0);
    const [arrowScale, setArrowScale] = useState(1);
    const [bottomPosition, setBottomPosition] = useState(20);
    const MAX_ARROW_SCALE = 1.5;
    const [vibration, setVibration] = useState({ x: 0, y: 0 });
    const [showGesture, setShowGesture] = useState(true);
    const [showGestureMessage, setShowGestureMessage] = useState(true);
    const [showGoalScreen, setShowGoalScreen] = useState(false);
    const [hasCalledApi, setHasCalledApi] = useState(false);
    const [hidePkScreen, setHidePkScreen] = useState(false);
    const [isFootballKicked, setIsFootballKicked] = useState(false);
    const [initialized, setInitialized] = useState(false);

    const isMouseEvent = (
        event: React.MouseEvent | React.TouchEvent
    ): event is React.MouseEvent => {
        return event.type.startsWith('mouse');
    };

    // スクロールを無効化
    const disableScroll = useCallback((e: TouchEvent) => {
        if (e.cancelable) {
            e.preventDefault();
        }
    }, []);

    useEffect(() => {
        if (!initialized) {
            ga4PushEvent(GA4_CUSTOM_EVENT.DISPLAY_MINI_GAME_PAGE);
        }
        setInitialized(true);
    }, [initialized]);

    useEffect(() => {
        const footballElement = footballRef.current;
        MAX_ARROW_SCALE;
        if (footballElement) {
            const rect = footballElement.getBoundingClientRect();
            setBallHeight(rect.top);
        }
    }, [footballRef]);

    const isFingerOverBall = (
        ballX: number,
        ballY: number,
        fingerX: number,
        fingerY: number
    ): boolean => {
        const distance = Math.sqrt(
            (ballX - fingerX) ** 2 + (ballY - fingerY) ** 2
        );
        return distance < 50;
    };

    const getClientPosition = (
        event: React.MouseEvent | React.TouchEvent
    ): { clientX: number; clientY: number } => {
        if (isMouseEvent(event)) {
            return { clientX: event.clientX, clientY: event.clientY };
        } else {
            return {
                clientX: event.touches[0].clientX,
                clientY: event.touches[0].clientY,
            };
        }
    };

    /**
     * コンテナ要素のハンドラ
     */
    const handleContainerDragStart = () => {
        // ブラウザのスクロールを無効化
        document.addEventListener('touchmove', disableScroll, {
            passive: false,
        });
    };

    const handleDragStart = (
        event:
            | React.MouseEvent<HTMLImageElement>
            | React.TouchEvent<HTMLImageElement>
    ) => {
        // ブラウザのスクロールを無効化
        document.addEventListener('touchmove', disableScroll, {
            passive: false,
        });
        const { clientX, clientY } = getClientPosition(event);
        initialPosition.current = { x: clientX, y: clientY };
        const footballElement = footballRef.current;
        setShowGesture(false);
        setShowGestureMessage(false);
        if (footballElement) {
            const rect = footballElement.getBoundingClientRect();
            initialPosition.current = {
                x: rect.left + rect.width / 2,
                y: rect.top + rect.height / 2,
            };
        }
    };

    const handleDrag = (
        event:
            | React.MouseEvent<HTMLDivElement>
            | React.TouchEvent<HTMLDivElement>
    ) => {
        // PCでマウスオンするだけでボールがガクガクする対策
        if (isMouseEvent(event) && event.buttons === 0) return;
        if (!initialPosition.current) return;
        const { clientX, clientY } = getClientPosition(event);
        const dx = clientX - initialPosition.current.x;
        const dy = clientY - initialPosition.current.y;
        const angle = Math.atan2(dy, dx) * (180 / Math.PI);
        const adjustedAngle = (angle + 360) % 360;
        if (clientY < ballHeight) {
            // 指がボールの高さより上にある場合
            setDirection(null);
            setArrowScale(0.5);
            setBottomPosition(20);
            setVibration({ x: 0, y: 0 }); // 振動をリセット
        } else if (
            isFingerOverBall(
                initialPosition.current.x,
                initialPosition.current.y,
                clientX,
                clientY
            )
        ) {
            // 指がボールの上にある場合
            setDirection(null);
            setArrowScale(0.5);
            setBottomPosition(20);
            // 振動を適用
            setVibration({
                x: Math.random() * 10 - 5,
                y: Math.random() * 10 - 5,
            });
        } else if (adjustedAngle >= 0 && adjustedAngle < 60) {
            setDirection('left');
            MAX_ARROW_SCALE;
            setArrowScale(Math.min(0.5 + Math.abs(dx) / 300, MAX_ARROW_SCALE));
            setBottomPosition(20 - Math.abs(dy) / 15);
            // 振動を適用
            setVibration({
                x: Math.random() * 10 - 5,
                y: Math.random() * 10 - 5,
            });
        } else if (adjustedAngle >= 60 && adjustedAngle < 120) {
            setDirection('top');
            setArrowScale(Math.min(0.5 + Math.abs(dy) / 400, MAX_ARROW_SCALE));
            setBottomPosition(20 - Math.abs(dy) / 15);
            // 振動を適用
            setVibration({
                x: Math.random() * 10 - 5,
                y: Math.random() * 10 - 5,
            });
        } else if (adjustedAngle >= 120 && adjustedAngle < 180) {
            setDirection('right');
            setArrowScale(Math.min(0.5 + Math.abs(dx) / 300, MAX_ARROW_SCALE));
            setBottomPosition(20 - Math.abs(dy) / 15);
            // 振動を適用
            setVibration({
                x: Math.random() * 10 - 5,
                y: Math.random() * 10 - 5,
            });
        }
    };

    const handleDragEnd = () => {
        // イベントリスナーを削除してスクロールを再度有効化
        document.removeEventListener('touchmove', disableScroll);
    };

    const callPostStageEvent = useCallback(async () => {
        const stageEvent: stageEventResult = await waitAsyncFunctionExecuted(
            () => postStageEvent(isFirstRequest),
            1000
        );
        SessionStorageManager.setSessionStorageValue(
            SESSION_STORAGE_KEYS.MY_PAGE_SUGOROKU_REMAIN_DICE_VALUE,
            stageEvent.remainDice.toString()
        );
        setIsStageEventSuccess(stageEvent.isStageEventSuccess);
    }, [isFirstRequest, setIsStageEventSuccess]);

    useEffect(() => {
        setClubPlayerCode;
    }, [clubPlayerCode]);

    useEffect(() => {
        const handleMouseUp = async () => {
            if (direction !== null) {
                setShowStrikeFrame(true);
                // ボールを蹴った状態にする
                setIsFootballKicked(true);
                if (!hasCalledApi) {
                    setHasCalledApi(true);
                    try {
                        await callPostStageEvent();
                        setShowGoalScreen(true);
                    } catch (error) {
                        console.error(error);
                        setHasError(true);
                    }
                }
                setShowGoalScreen(true);
            }
        };

        window.addEventListener('mouseup', handleMouseUp);
        window.addEventListener('touchend', handleMouseUp);

        return () => {
            window.removeEventListener('mouseup', handleMouseUp);
            window.removeEventListener('touchend', handleMouseUp);
        };
    }, [direction, hasCalledApi, callPostStageEvent]);

    return (
        <div className="pk-screen">
            {hasError && <SugorokuErrorDialog />}
            {hidePkScreen ? null : showGoalScreen ? (
                clubPlayerCode ? (
                    <PlayerDetail code={clubPlayerCode} />
                ) : (
                    <GoalScreen
                        direction={direction}
                        isStageEventSuccess={isStageEventSuccess}
                    />
                )
            ) : (
                <>
                    {clubPlayerCode ? (
                        <PlayerDetail code={clubPlayerCode} />
                    ) : (
                        <Container
                            // endは使い回せるが、startは使い回せないのでFootballと別リスナーにする
                            onMouseDown={handleContainerDragStart}
                            onTouchStart={handleContainerDragStart}
                            onMouseUp={handleDragEnd}
                            onTouchEnd={handleDragEnd}
                            // ボールを蹴った後はボールのドラッグを無効にする
                            onMouseMove={
                                isFootballKicked ? undefined : handleDrag
                            }
                        >
                            <Ground />
                            <BricWall />
                            <Goal>
                                <img src={goal} alt="Goal" />
                                <TargetsRow>
                                    <Target src={target} alt="Target 1" />
                                    <Target src={target} alt="Target 2" />
                                    <Target src={target} alt="Target 3" />
                                </TargetsRow>
                                <HorizontalLine />
                            </Goal>
                            <Football
                                src={football}
                                alt="Football"
                                draggable={false}
                                ref={footballRef}
                                onMouseDown={handleDragStart}
                                onTouchStart={handleDragStart}
                                onMouseUp={handleDragEnd}
                                onTouchEnd={handleDragEnd}
                                // ボールを蹴った後はボールのドラッグを無効にする
                                onTouchMove={
                                    isFootballKicked ? undefined : handleDrag
                                }
                                $isStageEventSuccess={isStageEventSuccess}
                                style={{
                                    bottom: `${bottomPosition}%`,
                                    transform: `translate(${vibration.x}px, ${vibration.y}px)`,
                                }}
                            />
                            <Gesture
                                src={gesture}
                                alt="Gesture"
                                style={{
                                    display: showGesture ? 'block' : 'none',
                                }}
                            />
                            {showGestureMessage && (
                                <GestureMessage>
                                    指でボールを
                                    <span className="highlight">
                                        ひっぱって
                                    </span>
                                    狙いをさだめよう !
                                </GestureMessage>
                            )}
                            {direction === 'left' && (
                                <Arrow
                                    src={arrow}
                                    alt="Arrow"
                                    $isStageEventSuccess={isStageEventSuccess}
                                    direction={direction}
                                    style={{
                                        transform: `scaleX(${arrowScale}) skew(0deg, 15deg) rotateZ(-15deg)`,
                                    }}
                                />
                            )}
                            {direction === 'top' && (
                                <Arrow
                                    src={arrow}
                                    alt="Arrow"
                                    $isStageEventSuccess={isStageEventSuccess}
                                    direction={direction}
                                    style={{
                                        transform: `scaleY(${arrowScale}) rotate(0deg)`,
                                    }}
                                />
                            )}
                            {direction === 'right' && (
                                <Arrow
                                    src={arrow}
                                    alt="Arrow"
                                    $isStageEventSuccess={isStageEventSuccess}
                                    direction={direction}
                                    style={{
                                        transform: `scaleX(${arrowScale}) skew(0deg,-15deg) rotate(15deg)`,
                                    }}
                                />
                            )}
                            {showStrikeFrame && (
                                <StrikeDoleFrame>
                                    <StrikeDoleAnimation>
                                        <StrikeDole
                                            src={strike_dole}
                                            alt="Strike Dole"
                                        />
                                    </StrikeDoleAnimation>
                                </StrikeDoleFrame>
                            )}
                        </Container>
                    )}
                </>
            )}

            {isStageEventSuccess === true && (
                <PkScreenGoal
                    nextEventItem={nextEventItem}
                    clubPlayerCode={clubPlayerCode}
                    onPassClubPlayerCode={setClubPlayerCode}
                />
            )}
            {isStageEventSuccess === false && (
                <PkScreenFail
                    nextEventItem={nextEventItem}
                    setHideOriginalPkScreen={setHidePkScreen}
                />
            )}
        </div>
    );
};

export default PkScreen;
