/**
 * GA4のカスタムイベント名を定義する定数ファイル。
 */
export const GA4_CUSTOM_EVENT = {
    // トップページ（ログインページ）のイベント名
    DISPLAY_TOP_PAGE: 'display_top_page',
    // マイページのイベント名
    DISPLAY_MY_PAGE: 'display_my_page',
    // チェックイン画面のイベント名
    DISPLAY_CHECK_IN_PAGE: 'display_check_in_page',
    // チェックイン成功画面のイベント名
    DISPLAY_CHECK_IN_SUCCESSFUL_PAGE: 'display_check_in_successful_page',
    // チェックイン失敗-エリア外画面のイベント名
    DISPLAY_CHECK_IN_FAILED_BY_NOT_IS_IN_SCOPE_PAGE:
        'display_check_in_failed_by_not_is_in_scope_page',
    // チェックイン失敗-イベントなし画面のイベント名
    DISPLAY_CHECK_IN_FAILED_BY_NO_EVENT_PAGE:
        'display_check_in_failed_by_no_event_page',
    // チェックイン失敗-GPS NG画面のイベント名
    DISPLAY_CHECK_IN_FAILED_BY_NO_LOCATION_PAGE:
        'display_check_in_failed_by_no_location_page',
    // ログインボタンのイベント名
    PRESSED_LOGIN_BUTTON: 'pressed_login_button',
    // JリーグID取得ボタンのイベント名
    PRESSED_SIGN_UP_BUTTON: 'pressed_sign_up_button',
    // ログアウトボタンのイベント名
    PRESSED_LOGOUT_BUTTON: 'pressed_logout_button',
    // チェックインボタンのイベント名
    PRESSED_CHECK_IN_BUTTON: 'pressed_check_in_button',
    // 「CONFTとは」ボタンのイベント名
    PRESSED_CONFT_ANNOUNCE_LINK: 'pressed_conft_announce_link',
    // FAQリンクのイベント名
    PRESSED_FAQ_LINK: 'pressed_faq_link',
    // GPS NG画面のFAQリンクのイベント名
    PRESSED_GPS_ERROR_FAQ_LINK: 'pressed_gps_error_faq_link',
    // 範囲外画面のFAQリンクのイベント名
    PRESSED_NOT_IS_IN_SCOPE_FAQ_LINK: 'pressed_not_is_in_scope_faq_link',
    // iOS GPSエラーリンクのイベント名
    PRESSED_IOS_GPS_ERROR_LINK: 'pressed_ios_gps_error_link',
    // Android GPSエラーリンクのイベント名
    PRESSED_ANDROID_GPS_ERROR_LINK: 'pressed_android_gps_error_link',
    // 「詳細日程はこちら」リンクのイベント名
    PRESSED_GAME_SCHEDULE_LINK: 'pressed_game_schedule_link',

    // 〜STEP3から追加〜
    // 会員情報編集画面のイベント名
    DISPLAY_ACCOUNT_INFO_PAGE: 'ds_account_info_pg',
    // お知らせ一覧画面のイベント名
    DISPLAY_NOTIFICATION_LIST_PAGE: 'ds_notification_list_pg',
    // お知らせ詳細画面のイベント名
    DISPLAY_NOTIFICATION_DETAIL_PAGE: 'ds_notification_detail_pg',
    // 応援の軌跡一覧画面のイベント名
    DISPLAY_ATTENDANCE_HISTORY_PAGE: 'ds_attendance_history_pg',
    // 応援の軌跡詳細のイベント名
    DISPLAY_ATTENDANCE_HISTORY_DETAIL_PAGE: 'ds_notification_detail_pg',
    // すごろくマップ画面のイベント名
    DISPLAY_SUGOROKU_MAP_PAGE: 'ds_sugoroku_map_pg',
    // すごろくヘルプページのイベント名
    DISPLAY_SUGOROKU_HELP_PAGE: 'ds_sugoroku_help_pg',
    // すごろく選手一覧のイベント名
    DISPLAY_SUGOROKU_PLAYER_LIST_PAGE: 'ds_sugoroku_player_list_pg',
    // すごろく選手詳細のイベント名
    DISPLAY_SUGOROKU_PLAYER_DETAIL_PAGE: 'ds_sugoroku_player_detail_pg',
    // ミニゲームのイベント名
    DISPLAY_MINI_GAME_PAGE: 'ds_mini_game_pg',
    // クラブ広告のイベント名
    DISPLAY_CLUB_AD_PAGE: (clubAdId: number) => {
        return `ds_club_ad_page_${clubAdId}`;
    },
    // イベントダイアログを閉じた時のイベント名
    PRESSED_EVENT_DIALOG_CLOSE: 'pr_event_dialog_cl',
    // お知らせダイアログを閉じた時のイベント名
    PRESSED_NOTIFICATION_DIALOG_CLOSE: 'pr_notification_dialog_cl',
    // インストール訴求ダイアログを閉じた時のイベント名
    PRESSED_INSTALL_DIALOG_CLOSE: 'pr_install_dialog_cl',
    // ログインボーナスダイアログを閉じた時のイベント名
    PRESSED_LOGIN_BONUS_DIALOG_CLOSE: 'pr_login_bonus_dialog_cl',
    // 会員情報編集ボタンのイベント名
    PRESSED_ACCOUNT_INFO_BUTTON: 'pr_account_info_btn',
    // ミニゲーム成功ダイアログの選手詳細ボタンのイベント名
    PRESSED_MINI_GAME_SUCCESS_DIALOG_PLAYER_BUTTON:
        'pr_mini_game_success_dialog_player_btn',
    // ミニゲーム成功ダイアログの戻るボタンのイベント名
    PRESSED_MINI_GAME_SUCCESS_DIALOG_BACK_BUTTON:
        'pr_mini_game_success_dialog_back_btn',
    // ミニゲーム失敗ダイアログの1マス戻るボタンのイベント名
    PRESSED_MINI_GAME_FAILURE_DIALOG_BACK_BUTTON:
        'pr_mini_game_failure_dialog_back_btn',
    // サイコロを振るボタンのイベント名
    PRESSED_ROLL_DICE_BUTTON: 'pr_roll_dice_btn',
    // イベントダイアログの「チェックイン」リンクのイベント名
    PRESSED_EVENT_DIALOG_CHECK_IN_LINK: 'pr_event_dialog_check_in_li',
    // お知らせダイアログの「お知らせ詳細」リンクのイベント名
    PRESSED_NOTIFICATION_DIALOG_DETAIL_LINK: (notificationId: number) => {
        return `pr_notification_dialog_detail_link_${notificationId}`;
    },
    // ログインボーナスダイアログの「すごろくマップへ」リンクのイベント名
    PRESSED_LOGIN_BONUS_DIALOG_SUGOROKU_MAP_LINK:
        'pr_login_bonus_dialog_sugoroku_map_li',
    // クラブ広告リンクをクリックした際に送信するイベント名
    PRESSED_CLUB_AD_LINK: (clubAdId: number) => {
        return `pr_club_ad_link_${clubAdId}`;
    },
    // アカウント切替リンクのイベント名
    PRESSED_ACCOUNT_SWITCH_LINK: 'pr_account_switch_li',
    // カードDLリンクのイベント名
    PRESSED_CARD_DOWNLOAD_LINK: 'pr_card_download_li',
    // マイページのNFTカルーセルのイベント名
    PRESSED_MY_PAGE_NFT_CAROUSEL: 'pr_my_page_nft_carousel',
    // 履歴スタンプのイベント名
    PRESSED_ATTENDANCE_HISTORY_STAMP: 'pr_attendance_history_stamp',
    // 来場証明NFTのイベント名
    PRESSED_CHECK_IN_NFT: 'pr_check_in_nft',
};
