export const generateApiBaseUrl = (): string => {
    const baseUrl: string = import.meta.env.VITE_API_BASE_URL;
    // クエリパラメータからスキーマを取得
    const urlSearchParams: URLSearchParams = new URLSearchParams(
        window.location.search
    );
    const requestSchema: string | undefined =
        urlSearchParams.get('schema') ?? undefined;

    const currentSchema: string | undefined =
        sessionStorage.getItem('schema') ?? undefined;

    // パラメータで指定されたSchemaを優先します
    const useSchema: string = requestSchema || currentSchema || '';
    if (useSchema === '') {
        return baseUrl;
    }
    sessionStorage.setItem('schema', useSchema);
    // 最初に一致したconftという文字列を置換
    const regex: RegExp = /conft/;
    const schemaReplacedUrl = baseUrl.replace(regex, useSchema);

    return schemaReplacedUrl;
};
