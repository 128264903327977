import ClubAd from '../../types/clubAd';

export const clubAdValidator = (clubAd: ClubAd) => {
    if (!clubAd) {
        return false;
    }
    if (typeof clubAd.id !== 'number') {
        return false;
    }
    if (typeof clubAd.resource !== 'string') {
        return false;
    }
    if (typeof clubAd.url !== 'string') {
        return false;
    }
    return true;
};
