import { Outlet } from 'react-router-dom';
import Footer from './components/Footer';
import styled from 'styled-components';
import Header from './Header';
import { FC, useState } from 'react';
import { NoticeBadgeProvider } from './providers/NoticeBadgeProvider';
import { SugorokuLoginBonusDialog } from './components/SugorokuLoginBonusDialog';
import { MyPageLayoutContainer } from './components/MyPageLayoutContainer';
// マイページレイアウトのスタイルを定義

// アウターのスタイルを定義
const OuterWrapper = styled.div`
    max-height: calc(100dvh - 48px - 82px);
    overflow: hidden scroll;

    /* PCで見たときにスクロールバー分ずれるのを解消するスタイル */
    scrollbar-gutter: stable both-edges;
`;

/**
 * マイページレイアウトのコンポーネント
 * @returns マイページレイアウトのコンポーネント
 */
const MyPageLayout: FC = () => {
    const [checkingLoginDialog, setCheckingLoginDialog] =
        useState<boolean>(true);
    const [showLoginBonusDialog, setShowLoginBonusDialog] =
        useState<boolean>(false);

    return (
        <NoticeBadgeProvider>
            <MyPageLayoutContainer
                setShowLoginBonusDialog={setShowLoginBonusDialog}
                setCheckingLoginDialog={setCheckingLoginDialog}
            >
                <Header />
                <OuterWrapper>
                    {showLoginBonusDialog && (
                        <SugorokuLoginBonusDialog
                            setShowLoginBonusDialog={setShowLoginBonusDialog}
                            setCheckingLoginDialog={setCheckingLoginDialog}
                        />
                    )}
                    <Outlet context={checkingLoginDialog satisfies boolean} />
                </OuterWrapper>
                <Footer />
            </MyPageLayoutContainer>
        </NoticeBadgeProvider>
    );
};

export default MyPageLayout;
