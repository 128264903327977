import { AxiosError } from 'axios';
import { MESSAGES } from '../constants/messages';
import { LogoutFunction } from '../hooks/useLogout';

export type ErrorHandleState = {
    isRedirected: boolean;
};

/*
 * AxiosErrorが発生した場合のエラーハンドリングを行う。
 * @param {NavigateFunction} navigate ナビゲーション関数
 * @param {unknown} error エラーオブジェクト
 * @returns {ErrorHandleState} エラー処理のステータス
 */
export const handleAxiosError = (
    logout: LogoutFunction,
    error: unknown
): ErrorHandleState | undefined => {
    if (!(error instanceof AxiosError))
        return { isRedirected: false } as ErrorHandleState;
    console.error(`Error: ${error?.response?.status} ${error?.response?.data}`);

    // エラーのステータスコードに応じて適切な処理を行う。
    const { status } = error.response || {};
    switch (status) {
        case 401:
            logout(MESSAGES.API_TOKEN_EXPIRED);
            return {
                isRedirected: true,
            } as ErrorHandleState;
        default:
            logout(MESSAGES.API_ERROR_DIALOG_MESSAGE);
            return {
                isRedirected: true,
            } as ErrorHandleState;
    }

    return { isRedirected: false } as ErrorHandleState;
};
