import { createAxiosInstance } from '../lib/createAxiosInstance';
import { API_PATHS } from './constants/apiPaths';
import UseDiceResult from '../types/useDiceResult';
import PostUseDiceResultResponseBody from './types/PostUseDiceType';
import { AxiosResponse } from 'axios';
import { SUGOROKU_ITEM_CODE } from '../constants/sugorokuItemCode';
import { postUseDiceResultValidator } from './validators/postUseDiceResultValidator';

/**
 * サイコロ振るAPIを呼び出す
 * @returns サイコロ振った結果
 * @throws {Error} サイコロ振るのに失敗した時にエラーを投げる
 */
export const postUseDice = async (): Promise<UseDiceResult> => {
    try {
        const instance = createAxiosInstance();
        const response: AxiosResponse = await instance.post(
            API_PATHS.POST_USE_DICE,
            {
                item_code: SUGOROKU_ITEM_CODE.DICE,
            }
        );
        const responseBody: PostUseDiceResultResponseBody = response.data ?? {};
        const result: UseDiceResult = {
            accountId: responseBody.account_id,
            sugorokuMapCode: responseBody.sugoroku_map_code,
            sugorokuMapName: responseBody.sugoroku_map_name,
            sugorokuMapVersion: responseBody.sugoroku_map_version,
            currentStageCode: responseBody.current_stage_code,
            currentCell: responseBody.current_cell,
            isFinished: responseBody.is_finished,
            remainDiceValue: responseBody.remain_dice_value,
            diceValue: responseBody.dice_value,
        } as UseDiceResult;
        const isValid = postUseDiceResultValidator(result);
        if (!isValid) {
            throw new Error('Invalid UseDiceResult');
        }
        return result;
    } catch (error) {
        console.error('Use Dice failed:', error);
        throw error;
    }
};
