import { getAccountsInfoWithSessionStorage } from '../api/getAccountsInfo';
import { defer } from 'react-router-dom';
import { getJLeagueAccountInfo } from '../api/getJleagueAccountInfo';
import SessionStorageManager from '../lib/sessionStorageManager';
import { SESSION_STORAGE_KEYS } from '../constants/sessionStorageKeys';

/**
 * アカウント情報をページ描画前に取得する
 * @params reloadJLeagueAccountInfo - Jリーグのアカウント情報を再取得するか
 * @returns アカウント情報
 */
export const accountInfoLoader = async (reloadJLeagueAccountInfo: boolean) => {
    try {
        if (reloadJLeagueAccountInfo) {
            SessionStorageManager.deleteSessionStorageValue(
                SESSION_STORAGE_KEYS.USER_INFO
            );
            // APIコールはするが、アカウント情報は使用せずに捨てて後の会員情報取得に任せる。
            return defer({
                account_info: getJLeagueAccountInfo().then(() => {
                    return getAccountsInfoWithSessionStorage();
                }),
            });
        }
        const accountInfo = getAccountsInfoWithSessionStorage();
        return defer({ account_info: accountInfo });
    } catch (error) {
        console.error('Error fetching account info:', error);
        return defer({ account_info: [] });
    }
};
