import { createAxiosInstance } from '../lib/createAxiosInstance';
import { API_PATHS } from './constants/apiPaths';
import PostCheckInResponseBody from './types/postCheckInType';
import { SUCCESS } from './constants/apiStatusCodes';
import { AxiosResponse } from 'axios';
import SaveWalletAddressResult from '../types/saveWalletAddress';

/**
 * ウォレットアドレス登録APIを呼び出す
 * @param {string} address ウォレットアドレス
 * @returns {Promise<SaveWalletAddressResult>} ウォレットアドレス登録結果
 * @throws {Error} API呼び出し失敗時
 */
export const postWalletAddress = async (
    address: string
): Promise<SaveWalletAddressResult> => {
    try {
        const instance = createAxiosInstance();
        const response: AxiosResponse = await instance.post(
            API_PATHS.POST_WALLET_ADDRESS,
            {
                address: address,
            }
        );
        const responseBody: PostCheckInResponseBody = response.data ?? {};

        const result: SaveWalletAddressResult = {
            statusCode: response?.status ?? 0,
            success: Boolean(response?.status === SUCCESS) ?? false,
            message: responseBody?.process_message ?? '',
        } as SaveWalletAddressResult;

        return result;
    } catch (error) {
        console.error('postWalletAddress error:', error);
        throw error;
    }
};
