import React from 'react';
import styled from 'styled-components';
import { PATH_MAPPING } from './constants/pathMapping';
import { useNavigate } from 'react-router-dom';
import conftLogo from './assets/images/conft-logo.svg';

// ヘッダーロゴのスタイルを定義
const HeaderLogoContainer = styled.div`
    width: 120px;
    height: 24px;
    padding: 12px 0;
    cursor: pointer;
    background-image: ${`url("${conftLogo}")`};
    background-repeat: no-repeat;
    background-position: center;
    background-size: 120px auto;
`;

/**
 * ヘッダーのロゴ
 * @returns ヘッダーのロゴ
 */
const HeaderLogo: React.FC = () => {
    const navigate = useNavigate();

    const redirectMyPage = React.useCallback(() => {
        navigate(PATH_MAPPING.MY_PAGE);
    }, [navigate]);
    return <HeaderLogoContainer onClick={redirectMyPage} />;
};

export default HeaderLogo;
