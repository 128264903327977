/**
 * パスと名前のマッピング。
 *
 * ※※※※※※※※※※※※※※※※※※※※※※※※※※※
 * コード内で「/」や「/mypage」のようにベタ書きすると、
 * URLに変化があった際の修正箇所が膨大になるため、
 * 必ずPATH_MAPPING経由でリンクをパスを取得してください。
 *
 * 例）<Link to={PATH_MAPPING.MY_PAGE_ROOT}>
 * ※※※※※※※※※※※※※※※※※※※※※※※※※※※
 */
export const PATH_MAPPING = {
    ROOT: '/',
    TERMS: '/terms',
    MY_PAGE: '/mypage',
    CHECK_IN: '/mypage/checkin',
    NFT: '/mypage/nft',
    SUGOROKU: '/mypage/sugoroku',
    NFT_DETAILS: '/mypage/nft/:eventId',
    NOTIFICATIONS: '/mypage/notifications',
    NOTIFICATION_DETAIL: '/mypage/notifications/:notificationId',
    OPENID_CALLBACK: '/openidconnect/callback',
    CONNECT_TO_WEB3AUTH: '/web3auth/connect',
    LOGGED_OUT: '/logged-out',
    ERROR: '/error',
    ACCOUNT_INFO: '/mypage/accountinfo',
};
