import { LoadingSpinnerOverlay } from '../Spinner';
import CookieManager from '../lib/cookieManager';
import postLogin from '../api/postLogin';
import JLeagueIdLoginUtility from '../lib/JLeagueIdLoginUtillity';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { MESSAGES } from '../constants/messages';
import { PATH_MAPPING } from '../constants/pathMapping';

const OpenIDCallback: React.FC = () => {
    const navigate = useNavigate();
    // 苦渋の決断。開発中に2回Jリーグ認証基盤にリクエストが飛んでログインに失敗するので、無視するコードを入れる
    let called = false;
    React.useEffect(() => {
        const login = async () => {
            const currentURL = window.location.href;
            const params = new URL(currentURL).searchParams;
            // stateがあるとき = ログイン
            // stateがないとき = 新規登録 or 新規登録後のリダイレクト
            if (params.has('state')) {
                const state = params.get('state');
                const cookieState = CookieManager.getCookieValue('app-state');
                if (state !== cookieState) {
                    // stateチェックに失敗した場合はログインさせない
                    window.location.href = PATH_MAPPING.ROOT;
                } else {
                    // cookieからstateを消す
                    CookieManager.deleteCookieValue('app-state');
                }
            } else {
                const sid = params.get('sid');
                // 会員情報変更後「サービスに戻る」を押下した場合のハンドル
                if (sid === '3001') {
                    window.location.href = PATH_MAPPING.ACCOUNT_INFO;
                    return;
                }
                // 新規登録の場合はsid=2000でリダイレクトされる。
                // https://jleague.backlog.jp/alias/wiki/770213#loom-header-4
                // ログイン状態で新規会員登録リンクを踏むとsidなしでリダイレクトしてくるので、それも受け取る
                if (sid === null || sid === '2000') {
                    // 新規登録後、もう一度ログイン処理を行うためにログインURLへ遷移させる
                    window.location.href = JLeagueIdLoginUtility.getRequestUrl(
                        import.meta.env.VITE_JLEAGUE_LOGIN_PATH
                    );
                    return;
                }
                // その他のsidの場合はセッションを残さず、ログイン画面へ遷移させる
                window.location.href = PATH_MAPPING.ROOT;
                return;
            }
            try {
                const authorizationCode = params.get('code') || '';
                const redirectUri = JLeagueIdLoginUtility.getRedirectUrl();
                const isloggedIn: boolean = await postLogin(
                    authorizationCode,
                    redirectUri
                );
                if (isloggedIn) {
                    navigate(PATH_MAPPING.CONNECT_TO_WEB3AUTH);
                    return;
                }
            } catch (error) {
                if (
                    error instanceof AxiosError &&
                    error.response?.status === 400
                ) {
                    const userInfoUrl = `${import.meta.env.VITE_JLEAGUE_BASE_URL}${import.meta.env.VITE_JLEAGUE_CHANGE_MEMBER_INFO_PATH}`;
                    const message = `CONFTをご利用いただくには、都道府県・氏名の登録が必須です。<a href="${userInfoUrl}" target="_blank" rel="noopener noreferrer">こちら</a>から都道府県・氏名をご登録の上、再度ログインしてください。`;
                    navigate(PATH_MAPPING.ROOT, {
                        state: {
                            message: message,
                        },
                    });
                } else {
                    navigate(PATH_MAPPING.ROOT, {
                        state: {
                            message: MESSAGES.LOGIN_FAILED,
                        },
                    });
                }
            }
        };
        if (!called) {
            login();
        }
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            called = true;
        };
    }, [navigate]);
    return <LoadingSpinnerOverlay />;
};

export default OpenIDCallback;
