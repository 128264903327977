import AccountInfoType from '../../types/accountInfo';

export const accountInfoValidator = (accountInfo: AccountInfoType) => {
    if (!accountInfo) {
        return false;
    }
    if (!accountInfo.unique_key) {
        return false;
    }
    if (!accountInfo.first_name) {
        return false;
    }
    if (!accountInfo.last_name) {
        return false;
    }
    if (!accountInfo.attribute1) {
        return false;
    }
    if (!accountInfo.gender) {
        return false;
    }
    if (!accountInfo.icon_image_url) {
        return false;
    }

    return true;
};
