import { createAxiosInstance } from '../lib/createAxiosInstance';
import { API_PATHS } from './constants/apiPaths';
import DateFormatter from '../lib/dateFormatter';
import { Diary } from '../types/diaryType';
import { GetDiaryResponseBody } from './types/getDiaryResponseBody';
import { diaryValidator } from './validators/diaryValidator';
import { AxiosResponse } from 'axios';

/**
 * 日記取得APIを呼び出す
 * @returns {Diary} 日記情報
 * @throws {Error} API呼び出しに失敗した場合にエラーを投げる
 */
export const getDiary = async (accountNftId: number): Promise<Diary> => {
    try {
        const instance = createAxiosInstance();
        const response: AxiosResponse = await instance.get(
            API_PATHS.GET_DIARY(accountNftId)
        );
        const diaryResponse: GetDiaryResponseBody = response.data ?? {};

        const convertedDiary: Diary = {
            content: diaryResponse.content,
            updatedAt:
                diaryResponse.updated_at !== ''
                    ? DateFormatter.formatDate(diaryResponse.updated_at)
                    : '',
        } as Diary;

        const isValid: boolean = diaryValidator(convertedDiary);
        if (!isValid) {
            throw Error;
        }

        return convertedDiary;
    } catch (error) {
        console.error('Error fetching diary:', error);
        throw error;
    }
};
