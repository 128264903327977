import axios, { AxiosError, AxiosInstance } from 'axios';
import { createAxiosInstance } from '../lib/createAxiosInstance';
import { API_PATHS } from '../api/constants/apiPaths';

/**
 * ユーザーがビデオを視聴した時間をバックエンドのAPIに送信します。
 * @param {number} watchingTime - ユーザーがビデオを視聴した時間。
 * @returns {Promise<void>} - APIコールが成功した場合に解決されるPromise。
 * @throws {Error} - APIコールが失敗した場合にエラーをスローします。
 */
export const sendWatchingTime = async (
    watchingTime: number,
    adId: number
): Promise<void> => {
    try {
        const instance: AxiosInstance = createAxiosInstance();
        await instance.post(API_PATHS.POST_CLUB_AD_WATCHING_TIME(adId), {
            watching_time: watchingTime,
        });
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const axiosError = error as AxiosError;
            console.error(`
            Error sending watching time to the backend:
            - Response Data: ${axiosError.response?.data}
            - Request: ${axiosError.request}
            - Message: ${axiosError.message}
            `);
        } else {
            console.error(`
            Unexpected error sending watching time to the backend:
            - Error: ${error}
            `);
        }
        throw error;
    }
};
