import { useEffect, ComponentType } from 'react';
import CookieManager from '../lib/cookieManager';
import useLogout from '../hooks/useLogout';

/**
 * 認証済みのユーザーのみアクセスを許可するhook
 * @param WrappedComponent ラップするコンポーネント
 * @return APIトークンがある場合はラップされたコンポーネント、ないときはnull
 */
export function withAuthentication(
    WrappedComponent: ComponentType<Record<string, unknown>>
) {
    const WithAuthentication = (props: Record<string, unknown>) => {
        const logout = useLogout();
        const apiToken = CookieManager.getCookieValue('api_token');

        useEffect(() => {
            const apiToken = CookieManager.getCookieValue('api_token');
            if (!apiToken) {
                logout();
            }
        }, [logout, apiToken]);

        return apiToken ? <WrappedComponent {...props} /> : null;
    };

    WithAuthentication.displayName = `WithAuthentication(${getDisplayName(WrappedComponent)})`;

    return WithAuthentication;
}

/**
 * Wrapしたコンポーネントの表示名を取得する
 * @param WrappedComponent 表示名を取得するコンポーネント
 * @return 表示名
 */
function getDisplayName(
    WrappedComponent: ComponentType<Record<string, unknown>>
) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}
