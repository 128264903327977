import { PATH_MAPPING } from '../constants/pathMapping';
import { useState } from 'react';
import { styled } from 'styled-components';

import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { ButtonLink } from '../components/button';
import '../react-pdf.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
`;

const ReturnToTopWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 20px 0;

    & > a {
        display: inline-block;
    }
`;

const SecondReturnToTopWrapper = styled(ReturnToTopWrapper)`
    padding-bottom: 0;
`;

export const Terms = () => {
    const [numPages, setNumPages] = useState(1);
    const [loaded, setLoaded] = useState(false);
    const termsFileName =
        import.meta.env.VITE_TERMS_FILE_NAME || PATH_MAPPING.ROOT;

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        setNumPages(numPages);
        setLoaded(true);
    };

    return (
        <Container>
            {loaded && (
                <ReturnToTopWrapper>
                    <ButtonLink to={PATH_MAPPING.ROOT} borderColor="#000000">
                        トップへ戻る
                    </ButtonLink>
                </ReturnToTopWrapper>
            )}
            <Document
                file={termsFileName}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                {Array.from(new Array(numPages), (_, index) => (
                    <Page
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        scale={0.8}
                        loading=""
                    />
                ))}
            </Document>
            {loaded && (
                <SecondReturnToTopWrapper>
                    <ButtonLink to={PATH_MAPPING.ROOT} borderColor="#000000">
                        トップへ戻る
                    </ButtonLink>
                </SecondReturnToTopWrapper>
            )}
        </Container>
    );
};
