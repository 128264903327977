import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import goal from '../assets/images/goal.svg';
import target from '../assets/images/target.svg';
import soccerAnim from '../assets/images/soccer.gif';
import {
    Container,
    BricWall,
    Ground,
    Goal,
    HorizontalLine,
    TargetsRow,
    Target,
} from './SharedScreenElements';

const SoccerGif = styled.img<{ direction: 'left' | 'right' | 'top' | null }>`
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 5;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    animation: ${({ direction }) => {
        switch (direction) {
            case 'left':
                return `oval-path-left  0.6s infinite linear`;
            case 'right':
                return `scale-down-right 0.6s infinite linear`;
            case 'top':
                return `scale-down-top 0.6s infinite linear`;
        }
    }};

    @keyframes oval-path-left {
        0% {
            top: 90%;
            left: 50%;
            width: 30%;
            height: 25%;
        }

        33% {
            top: 50%;
            left: 40%;
            width: 40%;
            height: 30%;
        }

        66% {
            top: 10%;
            left: 30%;
            width: 30%;
            height: 25%;
        }

        100% {
            top: 20%;
            left: 20%;
            width: 10%;
            height: 10%;
        }
    }

    @keyframes scale-down-right {
        0% {
            top: 90%;
            left: 50%;
            width: 30%;
            height: 25%;
        }

        33% {
            top: 50%;
            left: 50%;
            width: 40%;
            height: 30%;
        }

        66% {
            top: 10%;
            left: 70%;
            width: 30%;
            height: 25%;
        }

        100% {
            top: 20%;
            left: 80%;
            width: 10%;
            height: 10%;
        }
    }

    @keyframes scale-down-top {
        0% {
            top: 90%;
            left: 50%;
            width: 30%;
            height: 25%;
        }

        33% {
            top: 50%;
            left: 50%;
            width: 40%;
            height: 30%;
        }

        66% {
            top: 10%;
            left: 50%;
            width: 30%;
            height: 25%;
        }

        100% {
            top: 20%;
            left: 50%;
            width: 10%;
            height: 10%;
        }
    }
`;

const flipAnimation = keyframes`
    0% {
        transform: rotateX(0deg);
        visibility: hidden;
    }
    50% {
        transform: rotateX(-90deg);
        visibility: visible;
    }
    100% {
        transform: rotateX(-180deg);
        visibility: visible;
    }
`;

const FlippedTarget = styled.div<{ $isStageEventSuccess: boolean | null }>`
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 92px;
    height: 92px;
    margin: 0 5px;
    font-size: 30px;
    font-weight: bold;
    color: white;
    visibility: hidden;
    background-color: ${({ $isStageEventSuccess }) =>
        $isStageEventSuccess === null
            ? 'transparent'
            : $isStageEventSuccess
              ? '#E40513'
              : '#2060AC'};
    border-radius: 50%;
    animation: ${flipAnimation} 0.3s ease-in-out forwards;
`;

const FlippedTargetText = styled.div`
    transform: rotateX(180deg);
`;

type GoalScreenProps = {
    direction: 'left' | 'right' | 'top' | null;
    isStageEventSuccess: boolean | null;
};

const GoalScreen: React.FC<GoalScreenProps> = ({
    direction,
    isStageEventSuccess,
}) => {
    const [flippedTarget, setFlippedTarget] = useState<
        'left' | 'right' | 'top' | null
    >(null);
    const [showSoccerGif, setShowSoccerGif] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (direction === 'left') {
                setFlippedTarget('left');
                setShowSoccerGif(false);
            } else if (direction === 'right') {
                setFlippedTarget('right');
                setShowSoccerGif(false);
            } else if (direction === 'top') {
                setFlippedTarget('top');
                setShowSoccerGif(false);
            }
        }, 520);

        return () => clearTimeout(timer);
    }, [direction]);

    return (
        <Container>
            <Ground />
            <BricWall />
            <Goal>
                <img src={goal} alt="Goal" />
                <TargetsRow>
                    {direction === 'left' && (
                        <>
                            {flippedTarget === 'left' &&
                            isStageEventSuccess !== null ? (
                                <FlippedTarget
                                    $isStageEventSuccess={isStageEventSuccess}
                                >
                                    <FlippedTargetText>
                                        {isStageEventSuccess === null
                                            ? ''
                                            : isStageEventSuccess
                                              ? 'アタリ'
                                              : 'ハズレ'}
                                    </FlippedTargetText>
                                </FlippedTarget>
                            ) : (
                                <Target src={target} />
                            )}
                            <Target src={target} alt="Target 2" />
                            <Target src={target} alt="Target 3" />
                        </>
                    )}
                    {direction === 'right' && (
                        <>
                            <Target src={target} alt="Target 1" />
                            <Target src={target} alt="Target 2" />
                            {flippedTarget === 'right' &&
                            isStageEventSuccess !== null ? (
                                <FlippedTarget
                                    $isStageEventSuccess={isStageEventSuccess}
                                >
                                    <FlippedTargetText>
                                        {isStageEventSuccess === null
                                            ? ''
                                            : isStageEventSuccess
                                              ? 'アタリ'
                                              : 'ハズレ'}
                                    </FlippedTargetText>
                                </FlippedTarget>
                            ) : (
                                <Target src={target} alt="Target 3" />
                            )}
                        </>
                    )}
                    {direction === 'top' && (
                        <>
                            <Target src={target} alt="Target 1" />
                            {flippedTarget === 'top' &&
                            isStageEventSuccess !== null ? (
                                <FlippedTarget
                                    $isStageEventSuccess={isStageEventSuccess}
                                >
                                    <FlippedTargetText>
                                        {isStageEventSuccess === null
                                            ? ''
                                            : isStageEventSuccess
                                              ? 'アタリ'
                                              : 'ハズレ'}
                                    </FlippedTargetText>
                                </FlippedTarget>
                            ) : (
                                <Target src={target} alt="Target 2" />
                            )}
                            <Target src={target} alt="Target 3" />
                        </>
                    )}
                </TargetsRow>
                <HorizontalLine />
            </Goal>
            {showSoccerGif && (
                <SoccerGif
                    src={soccerAnim}
                    alt="Soccer Animation"
                    direction={direction}
                />
            )}
        </Container>
    );
};

export default GoalScreen;
