import { defer } from 'react-router-dom';
import { getNftDetail } from '../api/getNftDetail';
import { NftDetailLoaderParams } from '../types/nftDetailLoaderParams';

/**
 * NFT詳細画面のローダー
 * @returns NFT詳細画面で使うデータ
 */
export const nftDetailLoader = async ({
    params,
}: {
    params: NftDetailLoaderParams;
}) => {
    try {
        const nft = getNftDetail(params.eventId);
        return defer({ nft: nft });
    } catch (error) {
        console.error('Error fetching nft detail:', error);
        return defer({ nft: [] });
    }
};
