import React, { useLayoutEffect } from 'react';
import styled from 'styled-components';
import { Await, Link, generatePath, useLoaderData } from 'react-router-dom';
import useLogout from '../hooks/useLogout';
import AccountInfoType from '../types/accountInfo';
import { AccountInfoLoaderDataType } from '../types/accountInfoLoaderData';
import { handleAxiosError } from '../lib/handleErrorResponse';
import { PATH_MAPPING } from '../constants/pathMapping';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper/core';
import { EffectCoverflow } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import '../swiper.css';
import dolewakuwakuImage from '../assets/images/dolewakuwaku.png';

import dolekunTiltingImage from '../assets/images/dolekun-tilting.svg';
import Dialog from '../components/Dialog';
import { useDialog } from '../components/UseDialog';
import { getEvents } from '../api/getEvents';
import EventType from '../types/event';
import SessionStorageManager from '../lib/sessionStorageManager';
import { SESSION_STORAGE_KEYS } from '../constants/sessionStorageKeys';
import { useShowCheckInNoticeBadgeContext } from '../hooks/useShowNoticeBadgeContext';
import EventLink from '../components/EventLink';
import { requestForToken } from '../lib/firebase/firebase';
import { postNotificationApproval } from '../api/postNotificationApproval';
import CookieManager from '../lib/cookieManager';
import { COOKIE_KEYS } from '../constants/cookieKeys';
import { isPWAEnabled } from '../lib/isPWAEnabled';
import { isAndroid, isIOS } from 'react-device-detect';
import appIcon from '../../public/conft225_android_round.png';
import iosDownload from '../assets/images/ios-download.png';
import androidDownload from '../assets/images/android-download.png';
import pcDownload from '../assets/images/pc-download.png';

// 画像のインポート
import backgroundImage from '../assets/images/background.jpg';
import { ga4PushEvent } from '../ga4';
import { GA4_CUSTOM_EVENT } from '../constants/ga4CustomEvent';
import settingsIconImage from '../assets/images/icon_setting.svg';
import { EventName } from '../components/EventName';
import { getNfts } from '../api/getNfts';
import Nft from '../types/nftType';
import { getNotifications } from '../api/getNotifications';
import AppNotification from '../types/AppNotification';
import { useCheckingLoginDialog } from '../hooks/useCheckingLoginDialog';

// コンテナースタイル
const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100dvh - 82px - 48px);
    color: white;
    background: url(${backgroundImage});
    background-position-x: center;
    background-size: cover;
`;

const BackgroundImage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    /* min-height: calc(100dvh - 82px - 48px); */
`;

const JLeagueContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
    padding: 10px 20px 40px;
    text-align: center;
`;

const JLeagueNameField = styled.span`
    font-size: 16px;
    font-weight: bold;
    color: #000000;
`;

const JLeagueIDText = styled.span`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 6px;
    font-size: 14px;
    font-weight: bold;
    color: #000000;

    @media (width <= 370px) {
        flex-direction: column;
    }
`;

const FallbackImage = styled.img`
    width: 233px;
    height: 233px;
    margin: 40px 0;

    @media (width <= 400px) {
        width: 65vw;
        height: 65vw;
    }
`;

const SlideContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

const NftStamp = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 45px;
    padding: 9px 0;
    font-size: 12px;
    font-weight: bold;
    line-height: 20px;
    background-color: #d10411;
`;

const NFTTotalAmount = styled.span`
    margin-top: 5px;
    font-family: 'DIN Condensed Bold', sans-serif;
    font-size: 22px;
`;

const SwiperContent = styled.div`
    width: 470px;
    height: 250px;
    margin: 35px 0;
`;

const NFTImage = styled.img`
    height: 233px;
    box-shadow: 6px 6px 6px rgba(0 0 0/60%);
`;

const IconContent = styled.div`
    display: flex;
    align-items: end;
    margin-top: 40px;
`;

type ProfileCircleIconProps = {
    itemProp?: string;
};

const ProfileCircleIcon = styled.div<ProfileCircleIconProps>`
    width: 98px;
    height: 98px;
    margin: 0 20px 0 66px;
    background: url(${(props) => props.itemProp}) no-repeat center/cover;
    background-color: #ffffff;
    border: none;
    border-radius: 50%;
`;

const SettingCircleIcon = styled(Link)`
    width: 48px;
    height: 48px;
    cursor: pointer;
    background: url(${settingsIconImage}) no-repeat center/cover;
    border: none;
    border-radius: 50%;
`;

const DialogImageWrapper = styled.div`
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
`;

const DialogImage = styled.img`
    width: 70px;
`;

const EventDialogTitle = styled.span`
    font-weight: bold;
    text-align: center;
`;

const DoCheckInText = styled.span`
    margin-top: 10px;
    font-weight: 500;
`;

const CheckInNotice = styled.span`
    margin-top: 10px;
    font-size: 14px;
    font-weight: 500;
`;

const LinkText = styled(Link)`
    margin-top: 20px;
    font-weight: bold;

    /* Dialog.aに負けたくない */
    color: blue !important;
`;

const NotificationDialogTitle = styled.span`
    font-weight: bold;
    text-align: center;
`;

const LoadingText = styled.span`
    margin-top: 120px;
    font-weight: bold;
    color: white;
`;

const PlaceholderText = styled.span`
    font-size: 1.125em;
    font-weight: bold;
    color: white;
`;

const PushNotificationMessageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 14px;
    margin-bottom: 25px;
    font-weight: 500;
    text-align: center;
`;

const PushNotificationTitle = styled.span`
    font-weight: 700;
`;

type BadgeProps = {
    disabled: boolean;
};

const PushNotificationStatusBadge = styled.span<BadgeProps>`
    padding: 2px 5px;
    margin: 0 5px;
    color: #ffffff;
    background-color: ${(props) => (props.disabled ? '#4caf50' : '#e40513')};
    border-radius: 5px;
`;
const PushNotificationMessage = styled.span`
    font-size: 14px;
`;

// ボタンのスタイル定義
const ButtonContainer = styled.div`
    display: flex;
    gap: 10px;
    justify-content: center;
    padding-bottom: 20px;
    background-color: #ffffff;
`;

const StyledButton = styled.button`
    width: 100px;
    padding: 12px 0;
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid #000000;
    border-radius: 20px;

    /* アクセシビリティ的によくないが、最初からネガティブな選択肢にフォーカスがあたっているのが気に食わないので… */
    &:focus-visible {
        outline: none;
    }
`;

const NextButton = styled(StyledButton)`
    color: white;
    background-color: #d71920;
    border: 1px solid #d71920;
`;

const PwaMessageWrapper = styled.div`
    font-weight: bold;
    text-align: center;
`;

const AppIconImage = styled.img`
    width: 40%;
    height: auto;
`;

const IOSDownloadImage = styled.img`
    width: 10%;
    height: auto;
`;

const AndroidDownloadImage = styled.img`
    width: 7%;
    height: auto;
    margin-bottom: 5px;
`;

const PCDownloadImage = styled.img`
    width: 10%;
    height: auto;
`;

const PwaAnnotationMessageWrapper = styled.div`
    margin-top: 40px;
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
`;

const PwaAnnotationMessageTitle = styled.span`
    font-weight: bold;
`;

/**
 * マイページトップのコンポーネント
 * @returns マイページトップのコンポーネント
 */
const MyPageTop: React.FC = () => {
    const { ref, closeModal: originalCloseModal } = useDialog();
    // SessionStorageにチェックインダイアログを閉じたかどうかの情報を保存するので別関数として定義
    const closeModal = () => {
        ga4PushEvent(GA4_CUSTOM_EVENT.PRESSED_EVENT_DIALOG_CLOSE);
        originalCloseModal();
        setHasClosedEventDialog(true);
        SessionStorageManager.setSessionStorageValue(
            SESSION_STORAGE_KEYS.MY_PAGE_TOP_EVENT_DIALOG_CLOSED,
            'true'
        );
    };
    const [hasClosedEventDialog, setHasClosedEventDialog] = React.useState(
        SessionStorageManager.getSessionStorageValue(
            SESSION_STORAGE_KEYS.MY_PAGE_TOP_EVENT_DIALOG_CLOSED
        ) === 'true'
    );
    const [isLoading, setIsLoading] = React.useState(true);
    const [event, setEvent] = React.useState<EventType | undefined>(undefined);
    const [currentNotification, setCurrentNotification] = React.useState<
        AppNotification | undefined
    >(undefined);
    const [currentNotificationIndex, setCurrentNotificationIndex] =
        React.useState(0);
    const [notifications, setNotifications] = React.useState<AppNotification[]>(
        []
    );
    const { setShowCheckInNoticeBadge } = useShowCheckInNoticeBadgeContext();
    const logout = useLogout();

    const [
        hasClosedPushNotificationDialog,
        setHasClosedPushNotificationDialog,
    ] = React.useState(
        CookieManager.getCookieValue(
            COOKIE_KEYS.PUSH_NOTIFICATION_DIALOG_CLOSED
        ) === 'true'
    );

    const PUSH_NOTIFICATION_STATE = {
        INITIAL: 0,
        STAND_BY: 1,
        COMPLETE: 2,
    };

    const [pushNotificationContent, setPushNotificationContent] =
        React.useState(PUSH_NOTIFICATION_STATE.INITIAL);

    const [hasClosedPwaInstallDialog, setHasClosedPwaInstallDialog] =
        React.useState(
            SessionStorageManager.getSessionStorageValue(
                SESSION_STORAGE_KEYS.MY_PAGE_TOP_CLOSED_PWA_INSTALL_ID
            ) === 'true'
        );

    const [initialized, setInitialized] = React.useState(false);
    const checkingLogin = useCheckingLoginDialog();

    /**
     * お知らせやイベントのダイアログを表示するための処理
     */
    React.useEffect(() => {
        /**
         * お知らせを取得してstateに保存する
         */
        const fetchNotifications = async () => {
            const showOnTopNotifications: AppNotification[] = [];
            try {
                const notifications = await getNotifications();
                const closedNotificationIds: number[] =
                    getClosedNotificationIdsFromSessionStorage();
                // 対象のやつだけ取り出す
                // ・トップページに出す対象である
                // ・まだ閉じられていないお知らせである
                for (const notification of notifications) {
                    if (
                        notification.showOnTop &&
                        !closedNotificationIds.includes(notification.id)
                    ) {
                        showOnTopNotifications.push(notification);
                    }
                }
                setNotifications(showOnTopNotifications);
                setCurrentNotification(showOnTopNotifications[0]);
            } catch (error) {
                return handleAxiosError(logout, error);
            }
        };
        // ホントはイベント情報も遅延読み込みさせるべきなのかもしれないが、実装がカオスになりそうだったので一旦useEffectへ
        const fetchEvents = async () => {
            try {
                const events = await getEvents();
                // TODO 複数イベントの考慮
                const event = events[0];
                // ＠FIXME setEventを待ってほしいのに待ってくれないから引数で渡す fetchって言ってるのにfetch以外のこともやらせてしまっているが、やむなし
                handleNotice(event);
                setEvent(event);
            } catch (error) {
                return handleAxiosError(logout, error);
            }
        };
        /**
         * 来場チェックを促すコンテンツ（未読バッジ、ダイアログ）の表示状態をハンドルする
         */
        const handleNotice = (event: EventType) => {
            // イベントがなくなった場合はもろもろリセットしておき、次に備える
            if (!event) {
                SessionStorageManager.deleteSessionStorageValue(
                    SESSION_STORAGE_KEYS.MY_PAGE_TOP_EVENT_DIALOG_CLOSED
                );
                SessionStorageManager.deleteSessionStorageValue(
                    SESSION_STORAGE_KEYS.ABLE_TO_CHECK_IN_EVENT_ID
                );
                setShowCheckInNoticeBadge(false);
                setHasClosedEventDialog(true);
                return;
            }
            const stringEventId = event.id.toString();
            const beforeEventId = SessionStorageManager.getSessionStorageValue(
                SESSION_STORAGE_KEYS.ABLE_TO_CHECK_IN_EVENT_ID
            );
            if (!event.has_checked_in) {
                // バッジ表示
                setShowCheckInNoticeBadge(true);
                // イベントIDを保持させて、次回以降の比較に使用
                SessionStorageManager.setSessionStorageValue(
                    SESSION_STORAGE_KEYS.ABLE_TO_CHECK_IN_EVENT_ID,
                    stringEventId
                );
                // 一度ダイアログを閉じたが別のイベントが始まっている状態の場合はダイアログを再表示させなくてはいけない
                // イベント未実施の状態と比較させたくないため、stringであることを担保させてから比較
                if (
                    hasClosedEventDialog &&
                    beforeEventId &&
                    beforeEventId !== stringEventId
                ) {
                    setHasClosedEventDialog(false);
                    SessionStorageManager.deleteSessionStorageValue(
                        SESSION_STORAGE_KEYS.MY_PAGE_TOP_EVENT_DIALOG_CLOSED
                    );
                }
            } else {
                // チェックイン済の場合はチェックイン可能イベント名を削除
                SessionStorageManager.deleteSessionStorageValue(
                    SESSION_STORAGE_KEYS.ABLE_TO_CHECK_IN_EVENT_ID
                );
                // すでにチェックインしているが、一度ログアウトした人はダイアログを出させたくないので閉じたことにする
                if (!hasClosedEventDialog) {
                    setHasClosedEventDialog(true);
                    SessionStorageManager.setSessionStorageValue(
                        SESSION_STORAGE_KEYS.MY_PAGE_TOP_EVENT_DIALOG_CLOSED,
                        'true'
                    );
                }
            }
        };
        fetchEvents();
        fetchNotifications();
        // 1回だけ呼ばせたい
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * マイページ遷移直後の処理をまとめて行う
     */
    React.useEffect(() => {
        if (!initialized) {
            ga4PushEvent(GA4_CUSTOM_EVENT.DISPLAY_MY_PAGE);
            if (hasClosedPushNotificationDialog) {
                CookieManager.setCookieValue(
                    COOKIE_KEYS.PUSH_NOTIFICATION_DIALOG_CLOSED,
                    'true'
                );
                const status = CookieManager.getCookieValue(
                    COOKIE_KEYS.PUSH_NOTIFICATION_PERMISSION
                );
                CookieManager.setCookieValue(
                    COOKIE_KEYS.PUSH_NOTIFICATION_PERMISSION,
                    status ? status : 'denied'
                );
            }
        }
        setInitialized(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialized]);

    /**
     * プッシュ通知ダイアログの「次へ」を選択した場合の処理
     */
    const handlePushNotifications = async () => {
        setPushNotificationContent(PUSH_NOTIFICATION_STATE.STAND_BY);
        await handleRequestForToken();
        setPushNotificationContent(PUSH_NOTIFICATION_STATE.COMPLETE);
    };

    /**
     * プッシュ通知ダイアログを閉じたときの処理
     */
    const handlePushNotificationDialogClose = () => {
        setHasClosedPushNotificationDialog(true);
        originalCloseModal();
    };

    /**
     * 通知設定の状態を確認 & デバイストークンの発行
     */
    const handleRequestForToken = async () => {
        try {
            const { status, deviceToken } = await requestForToken();
            if (deviceToken) {
                // デバイストークン登録APIを呼び出す
                await postNotificationApproval(deviceToken);
            }
            if (status === 'granted' || status === 'denied') {
                CookieManager.setCookieValue(
                    COOKIE_KEYS.PUSH_NOTIFICATION_DIALOG_CLOSED,
                    'true'
                );
                // プッシュ通知を設定をした時点の状態をCookieに保存する
                // そのため、ユーザがOSの通知設定を直接変更した場合はCookieの値と異なる可能性がある。
                // あくまで、CONFTアプリ内から通知設定を確定した時点の状態を保存するためのものである。
                CookieManager.setCookieValue(
                    COOKIE_KEYS.PUSH_NOTIFICATION_PERMISSION,
                    status
                );
            }
        } catch (error) {
            console.error('Failed to request for token:', error);
        }
    };

    const isNotificationAPISupported = window.Notification ? true : false;

    const data = useLoaderData() as AccountInfoLoaderDataType;
    const account_info = data.account_info;
    const [nfts, setNfts] = React.useState<Nft[]>([]);

    useLayoutEffect(() => {
        const fetchNfts = async () => {
            try {
                const fetchedNfts = await getNfts();
                // 画像URLのないNFTを除外
                const validNfts = fetchedNfts.filter((nft) => nft.imageUrl);
                setNfts(validNfts);
                setIsLoading(false); // ローディング終了
            } catch (error) {
                return handleAxiosError(logout, error);
            }
        };

        fetchNfts();
    }, [logout]);

    // Swiperで利用するモジュールを登録
    SwiperCore.use([EffectCoverflow]);

    if (!account_info) {
        // ここでエラーケースを処理
        handleAxiosError(logout, new Error('Account info not loaded'));
        return;
    }

    const slides = nfts.map((nft) => ({
        image: nft.imageUrl,
    }));

    /**
     * 来場チェックダイアログ内「来場チェックへ」を押下した際のハンドル
     */
    const handleCheckInLinkClick = () => {
        ga4PushEvent(GA4_CUSTOM_EVENT.PRESSED_EVENT_DIALOG_CHECK_IN_LINK);
        setHasClosedEventDialog(true);
        SessionStorageManager.setSessionStorageValue(
            SESSION_STORAGE_KEYS.MY_PAGE_TOP_EVENT_DIALOG_CLOSED,
            'true'
        );
    };

    /**
     * お知らせダイアログを閉じたときのハンドリング
     */
    const handleNotificationClose = () => {
        ga4PushEvent(GA4_CUSTOM_EVENT.PRESSED_NOTIFICATION_DIALOG_CLOSE);
        pushClosedNotificationIdsToSessionStorage();
        const nextNotificationIndex = currentNotificationIndex + 1;
        if (nextNotificationIndex >= notifications.length) {
            setCurrentNotification(undefined);
            return;
        }
        const notification = notifications[currentNotificationIndex + 1];
        setCurrentNotification(notification);
        setCurrentNotificationIndex(nextNotificationIndex);
    };

    /**
     * ダイアログを閉じたお知らせをSessionStorageに保存する
     */
    const pushClosedNotificationIdsToSessionStorage = () => {
        // ここで使うぶんには、これは必ず配列になる想定
        const closedNotificationIds =
            getClosedNotificationIdsFromSessionStorage();
        // オプショナルチェーンでつないでるけど、このメソッドが呼ばれる段階ではundefinedになることはない想定
        closedNotificationIds.push(currentNotification?.id);
        SessionStorageManager.setSessionStorageValue(
            SESSION_STORAGE_KEYS.MY_PAGE_TOP_CLOSED_NOTIFICATION_IDS,
            // stringifyしてからぶっこむ
            JSON.stringify(closedNotificationIds)
        );
    };

    /**
     * インストール訴求ダイアログを閉じたときのハンドリング
     */
    const handlePwaInstallClose = () => {
        ga4PushEvent(GA4_CUSTOM_EVENT.PRESSED_INSTALL_DIALOG_CLOSE);
        SessionStorageManager.setSessionStorageValue(
            SESSION_STORAGE_KEYS.MY_PAGE_TOP_CLOSED_PWA_INSTALL_ID,
            'true'
        );
        setHasClosedPwaInstallDialog(true);
        originalCloseModal();
    };

    /**
     * SessionStorageからダイアログを閉じたお知らせの一覧を取得する
     *
     * @returns ダイアログを閉じたお知らせの一覧
     */
    const getClosedNotificationIdsFromSessionStorage = () => {
        return (
            SessionStorageManager.getSessionStorageValueAsJSON(
                SESSION_STORAGE_KEYS.MY_PAGE_TOP_CLOSED_NOTIFICATION_IDS
            ) || []
        );
    };

    return (
        <Container>
            <BackgroundImage>
                {/* アイコン */}
                <IconContent>
                    <React.Suspense fallback={<ProfileCircleIcon />}>
                        <Await resolve={account_info}>
                            {(accountInfo: AccountInfoType) => {
                                return (
                                    <ProfileCircleIcon
                                        itemProp={accountInfo.icon_image_url}
                                    />
                                );
                            }}
                        </Await>
                    </React.Suspense>
                    <SettingCircleIcon to={PATH_MAPPING.ACCOUNT_INFO} />
                </IconContent>
                {/* 会員情報 */}
                <React.Suspense
                    fallback={
                        <JLeagueContainer>
                            {/* Name Text */}
                            <JLeagueNameField>Loading...</JLeagueNameField>
                            {/* Unique Key Text */}
                            <JLeagueIDText>
                                Jリーグ ID: Loading...
                            </JLeagueIDText>
                        </JLeagueContainer>
                    }
                >
                    <Await resolve={account_info}>
                        {(accountInfo: AccountInfoType) => {
                            return (
                                <JLeagueContainer>
                                    {/* Name Text */}
                                    {accountInfo.first_name &&
                                        accountInfo.last_name && (
                                            <JLeagueNameField>
                                                {accountInfo.last_name}{' '}
                                                {accountInfo.first_name}
                                            </JLeagueNameField>
                                        )}
                                    {/* Unique Key Text */}
                                    {accountInfo.attribute1 && (
                                        <JLeagueIDText>
                                            <span>
                                                JリーグID:
                                                <br />
                                            </span>
                                            <span>
                                                {accountInfo.attribute1}
                                            </span>
                                        </JLeagueIDText>
                                    )}
                                </JLeagueContainer>
                            );
                        }}
                    </Await>
                </React.Suspense>
                {/* 所持NFT */}
                <NftStamp>
                    <span>所持NFT</span>
                    {isLoading ? (
                        <PlaceholderText>&#8212;</PlaceholderText>
                    ) : (
                        <NFTTotalAmount>{nfts.length}</NFTTotalAmount>
                    )}
                </NftStamp>
                {/* NFT一覧 */}
                {isLoading ? (
                    <LoadingText>Loading...</LoadingText>
                ) : nfts.length > 0 ? (
                    <SwiperContent>
                        <Swiper
                            // スワイプの感度
                            touchRatio={1.5}
                            // 'coverflow'エフェクトを適用
                            effect={'coverflow'}
                            centeredSlides={true}
                            // 一度に表示するスライドの数を指定
                            slidesPerView={3}
                            // coverflowエフェクトの詳細な設定
                            coverflowEffect={{
                                // スライドの回転角度を設定
                                rotate: 0,
                                // スライドの伸縮を設定
                                stretch: 0,
                                // スライドの奥行を設定
                                depth: 350,
                                // エフェクトの修飾子を設定
                                modifier: 1,
                                // スライドの影を表示するかどうかを設定
                                slideShadows: false,
                            }}
                            // Swiperに使用するモジュールを指定
                            modules={[EffectCoverflow]}
                        >
                            {slides.length > 0 ? (
                                slides.map((slide, index) => (
                                    <SwiperSlide key={index}>
                                        {slide.image && (
                                            <EventLink
                                                eventId={nfts[index].event.id}
                                                ga4CustomEvent={
                                                    GA4_CUSTOM_EVENT.PRESSED_MY_PAGE_NFT_CAROUSEL
                                                }
                                            >
                                                <NFTImage
                                                    src={slide.image}
                                                    alt={`NFT${index + 1}`}
                                                />
                                            </EventLink>
                                        )}
                                    </SwiperSlide>
                                ))
                            ) : (
                                <SwiperSlide>
                                    <SlideContainer>
                                        <FallbackImage
                                            src={dolewakuwakuImage}
                                            alt="Fallback Image"
                                        />
                                    </SlideContainer>
                                </SwiperSlide>
                            )}
                        </Swiper>
                    </SwiperContent>
                ) : (
                    <SlideContainer>
                        <FallbackImage
                            src={dolewakuwakuImage}
                            alt="Fallback Image"
                        />
                    </SlideContainer>
                )}
            </BackgroundImage>
            {/* NOTE: 下記の条件をすべて満たす場合にダイアログを表示する
                イベントが存在する、このダイアログが閉じられていない、ログインダイアログが表示されていない
            */}
            {event && !hasClosedEventDialog && !checkingLogin && (
                <Dialog
                    ref={ref}
                    isOpen={!hasClosedEventDialog}
                    DialogImageWrapper={
                        <DialogImageWrapper>
                            <DialogImage
                                src={dolekunTiltingImage}
                                alt="dolekun"
                            />
                        </DialogImageWrapper>
                    }
                    closeModal={closeModal}
                >
                    <>
                        <EventDialogTitle>
                            本日イベント開催中！<br></br>
                            <EventName name={event.short_name} />
                        </EventDialogTitle>
                        <DoCheckInText>
                            試合観戦の来場チェックをしよう！
                        </DoCheckInText>
                        <CheckInNotice>
                            ※スタジアム敷地内のどこでもチェック可能
                        </CheckInNotice>
                        <LinkText
                            to={PATH_MAPPING.CHECK_IN}
                            onClick={handleCheckInLinkClick}
                        >
                            来場チェックへ
                        </LinkText>
                    </>
                </Dialog>
            )}
            {/* NOTE: 下記の条件をすべて満たす場合にダイアログを表示する
                イベントダイアログが閉じられている、ログインダイアログが閉じられている、このダイアログが閉じられていない、Notification APIがサポートされている、PWAモードで開かれている
            */}
            {hasClosedEventDialog &&
                !checkingLogin &&
                !hasClosedPushNotificationDialog &&
                isNotificationAPISupported &&
                isPWAEnabled() && (
                    <Dialog
                        ref={ref}
                        isOpen={true}
                        DialogImageWrapper={
                            <DialogImageWrapper>
                                <DialogImage
                                    src={dolekunTiltingImage}
                                    alt="dolekun"
                                />
                            </DialogImageWrapper>
                        }
                        closeModal={handlePushNotificationDialogClose}
                        showCloseButton={
                            pushNotificationContent ===
                            PUSH_NOTIFICATION_STATE.COMPLETE
                        }
                    >
                        {pushNotificationContent ===
                            PUSH_NOTIFICATION_STATE.INITIAL && (
                            <>
                                <PushNotificationMessageWrapper>
                                    CONFTから最新情報を受け取ろう！
                                </PushNotificationMessageWrapper>
                                <ButtonContainer>
                                    <NextButton
                                        onClick={handlePushNotifications}
                                    >
                                        次へ
                                    </NextButton>
                                </ButtonContainer>
                            </>
                        )}
                        {pushNotificationContent ===
                            PUSH_NOTIFICATION_STATE.STAND_BY && (
                            <PushNotificationMessageWrapper>
                                <PushNotificationTitle>
                                    通知設定の準備中
                                </PushNotificationTitle>
                                <PushNotificationMessage>
                                    しばらくお待ちください。
                                </PushNotificationMessage>
                            </PushNotificationMessageWrapper>
                        )}
                        {pushNotificationContent ===
                            PUSH_NOTIFICATION_STATE.COMPLETE && (
                            <PushNotificationMessageWrapper>
                                <PushNotificationTitle>
                                    プッシュ通知が
                                    {CookieManager.getCookieValue(
                                        COOKIE_KEYS.PUSH_NOTIFICATION_PERMISSION
                                    ) === 'granted' ? (
                                        <PushNotificationStatusBadge
                                            disabled={true}
                                        >
                                            有効
                                        </PushNotificationStatusBadge>
                                    ) : (
                                        <PushNotificationStatusBadge
                                            disabled={false}
                                        >
                                            無効
                                        </PushNotificationStatusBadge>
                                    )}
                                    になりました。
                                </PushNotificationTitle>
                                <PushNotificationMessage>
                                    プッシュ通知の設定を変更する場合は、アプリの再インストールが必要です。
                                </PushNotificationMessage>
                            </PushNotificationMessageWrapper>
                        )}
                    </Dialog>
                )}
            {/* NOTE: 下記の条件をすべて満たす場合にダイアログを表示する
                イベントダイアログが閉じられている、ログインダイアログが閉じられている、このダイアログが閉じられていない、Webブラウザで開かれている
            */}
            {hasClosedEventDialog &&
                !checkingLogin &&
                !hasClosedPwaInstallDialog &&
                !isPWAEnabled() && (
                    <Dialog
                        ref={ref}
                        isOpen={true}
                        DialogImageWrapper={<DialogImageWrapper />}
                        closeModal={handlePwaInstallClose}
                        dialogContentPadding="0px 20px"
                        $color="#ffffff"
                        $backgroundColor="#000000"
                        $borderColor="#000000"
                        $backgroundAlphaPercent={100}
                    >
                        <PwaMessageWrapper>
                            <AppIconImage src={appIcon} alt="アプリアイコン" />
                            <br />
                            ホーム画面に追加すると
                            <br />
                            次回からスムーズにアクセスできます
                            <br />
                            <br />
                            {isIOS ? (
                                <>
                                    <IOSDownloadImage
                                        src={iosDownload}
                                        alt="PWAインストール"
                                    />
                                    <br />
                                    ブラウザのアドレスバーからこのアイコンをタップしてホームに追加
                                </>
                            ) : isAndroid ? (
                                <>
                                    <AndroidDownloadImage
                                        src={androidDownload}
                                        alt="PWAインストール"
                                    />
                                    <br />
                                    ブラウザのアドレスバーからこのアイコンをタップしてホームに追加
                                    ＞ インストール
                                </>
                            ) : (
                                <>
                                    <PCDownloadImage
                                        src={pcDownload}
                                        alt="PWAインストール"
                                    />
                                    <br />
                                    ブラウザのアドレスバーからこのアイコンをタップしてホームに追加
                                </>
                            )}
                        </PwaMessageWrapper>
                        <PwaAnnotationMessageWrapper>
                            <PwaAnnotationMessageTitle>
                                ＜既にホーム画面にリンクを追加済みの方＞
                            </PwaAnnotationMessageTitle>
                            <br />
                            お手数ですがホーム画面のリンクを削除した後、再度リンクの追加をお願いします
                        </PwaAnnotationMessageWrapper>
                    </Dialog>
                )}

            {/* NOTE: 下記の条件をすべて満たす場合にダイアログを表示する
                イベントダイアログが閉じられている、プッシュ通知確認ダイアログ or インストール訴求ダイアログが閉じられている、お知らせが存在する
            */}
            {hasClosedEventDialog &&
                !checkingLogin &&
                // not PWA -> PWA訴求ダイアログが閉じられているときお知らせを表示
                ((!isPWAEnabled() && hasClosedPwaInstallDialog) ||
                    // PWA -> プッシュ通知確認ダイアログが閉じられているときお知らせを表示
                    (isPWAEnabled() && hasClosedPushNotificationDialog)) &&
                currentNotification !== undefined && (
                    <Dialog
                        ref={ref}
                        isOpen={currentNotification !== undefined}
                        DialogImageWrapper={
                            <DialogImageWrapper>
                                <DialogImage
                                    src={dolekunTiltingImage}
                                    alt="dolekun"
                                />
                            </DialogImageWrapper>
                        }
                        closeModal={handleNotificationClose}
                    >
                        <>
                            <NotificationDialogTitle>
                                {currentNotification.title}
                            </NotificationDialogTitle>
                            <LinkText
                                to={generatePath(
                                    PATH_MAPPING.NOTIFICATION_DETAIL,
                                    {
                                        notificationId: currentNotification.id,
                                    }
                                )}
                                onClick={() =>
                                    ga4PushEvent(
                                        GA4_CUSTOM_EVENT.PRESSED_NOTIFICATION_DIALOG_DETAIL_LINK(
                                            currentNotification.id
                                        )
                                    )
                                }
                            >
                                詳細を確認する
                            </LinkText>
                        </>
                    </Dialog>
                )}
        </Container>
    );
};

export default MyPageTop;
