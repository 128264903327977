import axios, { AxiosError, AxiosInstance } from 'axios';
import { createAxiosInstance } from '../lib/createAxiosInstance';
import { API_PATHS } from '../api/constants/apiPaths';

interface PostItemRequestBody {
    action: string;
    sugoroku_item_code: string;
    event_id?: number;
}

/**
 * すごろく獲得アイテムAPIを呼び出す
 * @param action - アクション
 * @param sugorokuItemCode - すごろくアイテムコード
 * @param eventId - イベントID
 * @returns boolean アイテム付与が行われたかどうか
 * @throws {Error} - APIコールが失敗した場合にエラーをスローします。
 */
export const postItem = async (
    action: string,
    sugorokuItemCode: string,
    eventId: number | undefined = undefined
): Promise<boolean> => {
    try {
        const instance: AxiosInstance = createAxiosInstance();
        let requestBody: PostItemRequestBody = {
            action: action,
            sugoroku_item_code: sugorokuItemCode,
        };
        if (eventId) {
            requestBody = {
                ...requestBody,
                event_id: eventId,
            };
        }
        const response = await instance.post(
            API_PATHS.POST_SUGOROKU_ITEM,
            requestBody
        );

        if (response.status === 200) {
            return true;
        }
        return false;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const axiosError = error as AxiosError;
            // 409エラーは重複エラー(既にアイテム獲得済み)なので、エラーログを出力しない
            if (axiosError.response?.status !== 409) {
                console.error(`
                Error sending post item to the backend::
                - Response Data: ${axiosError.response?.data}
                - Request: ${axiosError.request}
                - Message: ${axiosError.message}
                `);
            }
        } else {
            console.error(`
            Unexpected error sending post item to the backend:
            - Error: ${error}
            `);
        }
        throw error;
    }
};
