import { createAxiosInstance } from '../lib/createAxiosInstance';
import { API_PATHS } from './constants/apiPaths';
import { AxiosInstance } from 'axios';

/**
 * デバイストークン登録APIを呼び出す
 * @param deviceToken デバイストークン
 * @throws {Error} 登録失敗時にエラーを投げる
 */
export const postNotificationApproval = async (
    deviceToken: string
): Promise<void> => {
    try {
        const instance: AxiosInstance = createAxiosInstance();
        await instance.post(API_PATHS.POST_NOTIFICATION_APPROVAL, {
            token: deviceToken,
        });
    } catch (error: unknown) {
        console.error('Failed add device token:', error);
        throw error;
    }
};
