import axios, {
    AxiosInstance,
    AxiosRequestHeaders,
    AxiosResponse,
    InternalAxiosRequestConfig,
} from 'axios';
import { TokenManager } from './tokenManager';
import { memoizedTokenRefresh } from './tokenRefresh';

/**
 * axiosのインスタンスにリクエスト、レスポンスのフックを追加する
 * @param {AxiosInstance} instance axiosのインスタンス
 * @returns {void}
 */
export const setInterceptors = (instance: AxiosInstance): void => {
    instance.interceptors.request.use(
        async (config: InternalAxiosRequestConfig<unknown>) => {
            const accessToken: string = TokenManager.getAccessToken() ?? '';
            config.headers = {
                ...config.headers,
                Authorization: `Bearer ${accessToken ?? ''}`,
            } as unknown as AxiosRequestHeaders;
            return config;
        },
        (error: unknown) => Promise.reject(error)
    );

    instance.interceptors.response.use(
        (response: AxiosResponse) => response,
        async (error) => {
            const config = error?.config;

            if (error?.response?.status === 401 && !config?.sent) {
                config.sent = true;

                const accessToken: string =
                    (await memoizedTokenRefresh()) ?? '';

                config.headers = {
                    ...config.headers,
                    Authorization: `Bearer ${accessToken ?? ''}`,
                } as unknown as AxiosRequestHeaders;
                return axios(config);
            }
            return Promise.reject(error);
        }
    );
};
