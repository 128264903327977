/**
 * アプリ全体で使用するメッセージを定義する定数ファイル。
 */
export const MESSAGES = {
    /**
     * APIエラーでログイン画面に戻すときのダイアログのメッセージ
     */
    API_ERROR_DIALOG_MESSAGE:
        'ただいま混み合っています。時間をおいて再度お試しください。',
    API_TOKEN_EXPIRED:
        'ログインの有効期限が切れました。再度ログインしてください。',
    LOGIN_FAILED:
        'ログインに失敗しました。時間をおいてから再度お試しください。',
    SAVE_DIARY_SUCCEEDED_PREFIX: '日記を保存しました。',
    SAVE_DIARY_FAILED:
        '日記の保存に失敗しました。時間をおいてから再度お試しください。',
    SAVE_DIARY_WON_DICE: '日記保存でサイコロを獲得しました！',
    SAVE_GALLERY_WON_DICE: '写真保存でサイコロを獲得しました！',
    SAVE_PICTURE_SUCCEEDED_PREFIX: '写真を保存しました。',
    UPLOAD_PICTURE_FAILED:
        '写真のアップロードに失敗しました。時間を置いてもう一度お試しください。',
    SAVE_TRAJECTORY_SUCCEEDED_SUFFIX: '今後もCONFTに思い出を残していこう！',
    PICTURE_DELETE_CONFIRMATION_PREFIX: '写真を削除します。',
    PICTURE_DELETE_CONFIRMATION_SUFFIX: 'よろしいですか？',
    UNSUPPORTED_IMAGE_FORMAT:
        'アップロードされた画像のフォーマットがサポートされていないため、写真の保存に失敗しました。',
};
