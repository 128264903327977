import axios from 'axios';
import { generateApiBaseUrl } from './generateApiBaseUrl';

/**
 * axiosのインスタンスを生成する
 * 認証情報を必要としない場合はこれを使います。
 */
export const axiosPublic = axios.create({
    baseURL: generateApiBaseUrl(),
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: false,
});
