import { CHAIN_NAMESPACES } from '@web3auth/base';

export const chainConfig = {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: '0x89',
    rpcTarget: 'https://rpc.ankr.com/polygon',
    displayName: 'Polygon Mainnet',
    blockExplorerUrl: 'https://polygonscan.com',
    ticker: 'MATIC',
    tickerName: 'MATIC',
    logo: 'https://cryptologos.cc/logos/polygon-matic-logo.png',
};
