import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import { MESSAGES } from '../../constants/firebase';

const firebaseConfig = {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestForToken = async (
    retryCount = 9
): Promise<{ status: string | undefined; deviceToken: string | undefined }> => {
    let deviceToken = undefined;
    // 通知の許可を求める
    const status: string | undefined = await Notification.requestPermission();
    if (status !== 'granted') {
        return { status, deviceToken };
    }

    // トークン取得のリトライ処理 https://github.com/firebase/firebase-js-sdk/issues/7693
    for (let i = 0; i < retryCount + 1; i++) {
        try {
            // 既にトークンを取得している場合は、そのトークンを返す
            deviceToken = await getToken(messaging, {
                vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY,
            });
        } catch (error) {
            if (
                error instanceof DOMException &&
                error.toString() === MESSAGES.SUBSCRIPTION_FAILED
            ) {
                continue;
            }
            // その他の例外は握りつぶす
        }
    }
    return { status, deviceToken };
};
