import React, { MouseEventHandler } from 'react';
import styled from 'styled-components';

/**
 * 閉じるボタン
 */
const CloseButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 17px;
    height: 17px;
    cursor: pointer;

    /* ボーダーで作る */
    &::before,
    &::after {
        position: absolute;
        width: 24px;
        height: 1px;
        content: '';
        background-color: black;
    }

    &::before {
        transform: rotate(45deg);
    }

    &::after {
        transform: rotate(-45deg);
    }
`;

/**
 * 閉じるアイコンをbutton要素で作った
 */

const CloseIconButton: React.FC<{
    onClick: MouseEventHandler<HTMLDivElement>;
}> = ({ onClick }) => {
    return <CloseButton onClick={onClick} />;
};

export default CloseIconButton;
