import imageCompression from 'browser-image-compression';
import { ImageCompressionError } from '../CustomError';

/*
 * 画像を圧縮する
 * @param {File} imageFile - 画像ファイル
 * @returns {Promise<File>} - 圧縮された画像ファイル
 * @throws {Error} - 圧縮失敗時にエラーを投げる
 */
export const compressImage = async (imageFile: File): Promise<File> => {
    const compressOptions = {
        maxSizeMB: 5,
        useWebWorker: true,
    };
    try {
        const compressedFile: File = await imageCompression(
            imageFile,
            compressOptions
        );
        // 元のファイル名から拡張子を取得
        const extension: string | undefined = imageFile.name.split('.').pop();
        if (!extension) {
            throw new ImageCompressionError(
                'Failed to get extension from image file name'
            );
        }
        const newFileName: string = `compressed.${extension}`;
        const compressedFileWithExtension: File = new File(
            [compressedFile],
            newFileName,
            {
                type: compressedFile.type,
                lastModified: Date.now(),
            }
        );
        return compressedFileWithExtension;
    } catch (error) {
        if (error instanceof Event) {
            throw new ImageCompressionError(
                `Image compression failed: ${error.type}`
            );
        } else {
            console.error('Image compression failed:', error);
            throw error;
        }
    }
};
