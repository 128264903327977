import React from 'react';
import styled from 'styled-components';
import { Event } from '../types/nftType';
import DateFormatter from '../lib/dateFormatter';
import Visa from '../assets/images/visa.svg';
import EventLink from '../components/EventLink';
import { GA4_CUSTOM_EVENT } from '../constants/ga4CustomEvent';

type NftStampColorType = {
    color: string;
};

export const NftStampContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 157px;
    height: 230px;
`;

export const VisaWrapper = styled.div`
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5px;
`;

export const VisaTitle = styled.span<NftStampColorType>`
    padding: 34px 47px 30px;
    font-size: 17px;
    font-weight: bold;
    line-height: 1.5rem;
    color: ${(props) => props.color};
    text-align: center;
`;

export const ShortNameContent = styled.p<NftStampColorType>`
    margin-top: 8px;
    font-size: 21px;
    font-weight: bold;
    color: #ffffff;
    color: ${(props) => props.color};
`;

export const VisaDate = styled.p<NftStampColorType>`
    margin-top: 4px;
    font-size: 10px;
    font-weight: bold;
    color: ${(props) => props.color};
`;

export const VisaImg = styled.svg`
    position: absolute;
    width: 170px;
    height: 100%;
`;

type EventProps = Pick<
    Event,
    'id' | 'color' | 'mainTextColor' | 'subTextColor' | 'shortName' | 'date'
>;

type SelectedNftProps = {
    event: EventProps;
    shouldLink?: boolean;
};

/**
 * NFTのスタンプを表示するコンポーネント
 * @param event イベント
 * @param shouldLink スタンプにイベントリンクをつけるか付けないか
 * @returns NFTのスタンプを表示するコンポーネント
 */
const NftStamp: React.FC<SelectedNftProps> = ({ event, shouldLink = true }) => {
    const { color, mainTextColor, subTextColor, shortName, date, id } = event;
    const dates: string = DateFormatter.formatDate(date);
    const dayOfWeek: string = DateFormatter.formatDayOfWeek(date);

    const BaseNftStampComponent = () => {
        return (
            <NftStampContainer>
                <VisaImg>
                    <use xlinkHref={`${Visa}#visa`} fill={color} />
                </VisaImg>
                <VisaWrapper>
                    <VisaTitle color={subTextColor}>
                        <span>Thanks</span>
                        <br />
                        <span>for</span>
                        <br />
                        <span>coming</span>
                    </VisaTitle>
                    <ShortNameContent color={mainTextColor}>
                        {shortName}
                    </ShortNameContent>
                    <VisaDate color={subTextColor}>
                        {dates} {dayOfWeek}
                    </VisaDate>
                </VisaWrapper>
            </NftStampContainer>
        );
    };

    return (
        <>
            {shouldLink ? (
                <EventLink
                    eventId={id}
                    ga4CustomEvent={
                        GA4_CUSTOM_EVENT.PRESSED_ATTENDANCE_HISTORY_STAMP
                    }
                >
                    <BaseNftStampComponent />
                </EventLink>
            ) : (
                <BaseNftStampComponent />
            )}
        </>
    );
};

export default NftStamp;
