import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Await, Link, useLoaderData } from 'react-router-dom';
import { LoadingSpinnerOverlay } from '../Spinner';
import { NotificationLoaderData } from '../types/notificationLoaderData';
import AppNotification from '../types/AppNotification';
import DateFormatter from '../lib/dateFormatter';
import { PATH_MAPPING } from '../constants/pathMapping';
import wakuwakudole from '../assets/images/wakuwakudole.svg';
import { ga4PushEvent } from '../ga4';
import { GA4_CUSTOM_EVENT } from '../constants/ga4CustomEvent';

const NotificationsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
    border-radius: 5px;
`;

const NotificationsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
`;

const NotificationsHeadline = styled.div`
    margin-top: 40px;
    font-size: 18px;
    font-weight: bold;
`;

const NotificationsContent = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: 100%;
    margin-top: 40px;
`;

const NotificationBox = styled(Link)<{ read: string }>`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 80vw;
    max-width: 335px;
    padding-top: 9px;
    padding-left: 35px;
    margin: 0 auto;
    color: inherit;
    text-decoration: none;
    background-color: #ffffff;
    border: 1px solid #d9e0e8;

    ${({ read }) =>
        read === 'false'
            ? `
        &::before {
            position: absolute;
            top: 50%;
            left: 13px;
            width: 13px;
            height: 13px;
            content: '';
            background-color: #d71920;
            border-radius: 50%;
            transform: translateY(-50%);
        }
    `
            : read === 'true' &&
              `
        color: #848487;
    `}
`;

const NotificationDate = styled.div`
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    color: #848487;
`;

interface NotificationTypeProps {
    $backgroundColor: string;
    $textColor: string;
}

const NotificationType = styled.div<NotificationTypeProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 66px;
    padding: 2px 0;
    font-size: 12px;
    font-weight: bold;
    color: ${(props) => props.$textColor};
    background-color: ${(props) => props.$backgroundColor};
    border-radius: 2px;
`;

const NotificationText = styled.div`
    padding-right: 15px;
    padding-bottom: 18px;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    word-wrap: break-word;
`;

const NotificationHeader = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
`;

// 「お知らせ一覧がありません。」テキストのスタイル
const NoContentText = styled.p`
    max-width: 400px;
    margin-top: 20px;
    font-size: 14px;
    text-align: center;
`;

const DolekunWakuwakuImageContainer = styled.div`
    display: inline-block;
    padding: 32px;
    background-color: white;
    border-radius: 50%;
`;

// ドーレくん(ワクワク)画像のコンテナを定義
const DolekunWakuwakuContainer = styled.div`
    margin-top: 40px;
    text-align: center;
`;

// ドーレくん(ワクワク)画像のスタイル
const DolekunWakuwakuImage = styled.img`
    width: 60vw;
    max-width: 300px;
    margin-left: 10px;
`;

const Notifications: React.FC = () => {
    const data = useLoaderData() as NotificationLoaderData;
    const loadedNotifications = data.notifications;
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (!initialized) {
            ga4PushEvent(GA4_CUSTOM_EVENT.DISPLAY_NOTIFICATION_LIST_PAGE);
        }
        setInitialized(true);
    }, [initialized]);

    return (
        <NotificationsContainer>
            <NotificationsWrapper>
                <NotificationsHeadline>お知らせ一覧</NotificationsHeadline>
                <NotificationsContent>
                    <React.Suspense fallback={<LoadingSpinnerOverlay />}>
                        <Await resolve={loadedNotifications}>
                            {(loadedNotifications: AppNotification[]) => {
                                return (
                                    <>
                                        {loadedNotifications.length === 0 ? (
                                            <>
                                                <NoContentText>
                                                    現在配信中のお知らせはありません。
                                                </NoContentText>
                                                <DolekunWakuwakuContainer>
                                                    <DolekunWakuwakuImageContainer>
                                                        <DolekunWakuwakuImage
                                                            src={wakuwakudole}
                                                            alt="ドーレくん(ワクワク)"
                                                        />
                                                    </DolekunWakuwakuImageContainer>
                                                </DolekunWakuwakuContainer>
                                            </>
                                        ) : (
                                            loadedNotifications.map(
                                                (notification) => (
                                                    <NotificationBox
                                                        key={notification.id}
                                                        to={`${PATH_MAPPING.NOTIFICATIONS}/${notification.id}`}
                                                        read={notification.read.toString()}
                                                    >
                                                        <NotificationHeader>
                                                            <NotificationDate>
                                                                {DateFormatter.formatDate(
                                                                    notification.releaseDateTime
                                                                )}
                                                            </NotificationDate>
                                                            <NotificationType
                                                                $backgroundColor={
                                                                    notification.typeBackgroundColor
                                                                }
                                                                $textColor={
                                                                    notification.typeTextColor
                                                                }
                                                            >
                                                                {
                                                                    notification.type
                                                                }
                                                            </NotificationType>
                                                        </NotificationHeader>
                                                        <NotificationText>
                                                            {notification.title}
                                                        </NotificationText>
                                                    </NotificationBox>
                                                )
                                            )
                                        )}
                                    </>
                                );
                            }}
                        </Await>
                    </React.Suspense>
                </NotificationsContent>
            </NotificationsWrapper>
        </NotificationsContainer>
    );
};

export default Notifications;
