import { createAxiosInstance } from '../lib/createAxiosInstance';
import { API_PATHS } from './constants/apiPaths';
import Picture from '../types/pictureType';
import { GetPicturesResponseBody } from './types/getPicturesType';
import { pictureValidator } from './validators/pictureValidator';
import { AxiosResponse } from 'axios';

/**
 * 写真取得APIを呼び出す
 * @param {number} accountNftId accountNftID
 * @returns {PictureType[]} 写真情報
 * @throws {Error} API呼び出しに失敗した場合にエラーを投げる
 */
export const getPictures = async (accountNftId: number): Promise<Picture[]> => {
    try {
        const instance = createAxiosInstance();
        const response: AxiosResponse = await instance.get(
            API_PATHS.GET_PICTURES(accountNftId)
        );
        const responseBody: GetPicturesResponseBody = response.data ?? {};
        const picturesResponse: GetPicturesResponseBody['account_pictures_list'] =
            responseBody?.account_pictures_list ?? [];

        const pictures: Array<Picture> = [];

        for (const picture of picturesResponse) {
            const convertedPicture: Picture = {
                id: picture.id,
                account_pictures_url: picture.account_pictures_url,
            } as Picture;

            const isValid: boolean = pictureValidator(convertedPicture);
            if (!isValid) {
                continue;
            }

            pictures.push(convertedPicture);
        }
        return pictures ?? [];
    } catch (error) {
        console.error('Error fetching pictures:', error);
        throw error;
    }
};
