import { getNotifications } from '../api/getNotifications';
import { defer } from 'react-router-dom';

/**
 * ページ描画前にお知らせ一覧を取得する
 *
 * @returns お知らせ一覧データ
 */
export const notificationLoader = async () => {
    try {
        const Notifications = getNotifications();
        return defer({ notifications: Notifications });
    } catch (error) {
        // TODO エラーハンドリング実装時に考える
        return [];
    }
};
