import React, { useState } from 'react';
import { useDialog } from '../UseDialog';
import Dialog from '../Dialog';
import { PATH_MAPPING } from '../../constants/pathMapping';
import dolekunTiltingImage from '../../assets/images/dolekun-tilting.svg';
import { Link } from 'react-router-dom';
import { EventName } from '../EventName';
import EventType from '../../types/event';
import styled from 'styled-components';

/**
 * チェックイン、登録ダイアログ画像をラップするコンポーネント
 */
const DialogImageWrapperForProbabilityDialog = styled.div`
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
`;

const DialogImageForDialogue = styled.img`
    width: 76px;
`;

const EventDialogTitle = styled.span`
    font-weight: bold;
    text-align: center;
`;

const DoCheckInText = styled.span`
    margin-top: 10px;
    font-weight: 500;
`;

/**
 * チェックイン画面へのリンクのテキスト
 */
const CheckInScreenLink = styled(Link)`
    display: inline-block;
    margin-top: 10px;
    font-weight: bold;

    /* Dialog.aに負けたくない */
    color: blue !important;
`;

interface CheckinDialogProps {
    event: EventType | undefined;
}

const CheckinDialog: React.FC<CheckinDialogProps> = ({ event }) => {
    const { ref } = useDialog();
    const [isOpen, setIsOpen] = useState(true);

    const handleCloseDialog = () => {
        setIsOpen(false);
    };

    return (
        <Dialog
            ref={ref}
            isOpen={isOpen}
            DialogImageWrapper={
                <DialogImageWrapperForProbabilityDialog>
                    <DialogImageForDialogue
                        src={dolekunTiltingImage}
                        alt="dolekun"
                    />
                </DialogImageWrapperForProbabilityDialog>
            }
            closeModal={handleCloseDialog}
            showCloseButton={true}
        >
            <EventDialogTitle>
                本日イベント開催中！<br></br>
                <EventName name={event?.name ?? ''} />
            </EventDialogTitle>
            <DoCheckInText>
                来場チェックをしてサイコロを獲得しよう！
            </DoCheckInText>
            <CheckInScreenLink to={PATH_MAPPING.CHECK_IN}>
                来場チェックへ
            </CheckInScreenLink>
        </Dialog>
    );
};

export default CheckinDialog;
