import React, { useEffect } from 'react';
import styled from 'styled-components';
import DialogType from '../types/dialog.ts';

interface DialogContainerProps {
    $color: string;
    $backgroundColor: string;
    $borderColor: string;
    $backgroundAlphaPercent: number;
}

// ダイアログのスタイルを定義
const DialogContainer = styled.dialog<DialogContainerProps>`
    /* Contentでmin-widthを調整しつつ、こっちでもmax-widthを調整しないと文字が大きいダイアログで崩れる */
    box-sizing: border-box;
    max-width: 360px;
    padding: 0;
    overflow: visible;
    font-size: 16px;
    line-height: 1.5rem;
    color: ${(props) => props.$color};
    background-color: ${(props) => props.$backgroundColor};
    border: 1px solid ${(props) => props.$borderColor};
    border-radius: 10px;

    @media (width < 400px) {
        margin: auto 20px;
    }

    &::backdrop {
        cursor: pointer;
        background-color: ${(props) =>
            `rgb(0 0 0 / ${props.$backgroundAlphaPercent}%)`};
    }

    a {
        color: #333333;
        outline: none;
    }
`;

const DialogInnerWrapper = styled.div`
    position: relative;
`;

// ダイアログ内のスタイルを定義
const DialogContent = styled.div<{ $dialogContentPadding: string }>`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    /* ビューポート - 左右マージンの合計40pxが360pxよりも小さければその値を、そうでなければ360pxで止める */
    min-width: min(calc(100vw - 40px), 360px);
    padding: ${(props) => props.$dialogContentPadding};
`;

// 閉じるボタンのスタイルを定義
const CloseButton = styled.button`
    position: absolute;
    top: -55px;
    right: 10px;
    padding: 15px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;

    span {
        position: absolute;
        right: 0;
        width: 35px;
        height: 2px;
        background: #ffffff;
    }

    .line1 {
        transform: rotate(45deg);
    }

    .line2 {
        transform: rotate(-45deg);
    }
`;

/**
 * ダイアログコンポーネントの使い方
 *
 * ※※※※※※※※※※※※※※※※※※※※※※※※※※※
 * ダイアログを表示する場合はDialog.tsxとUseDialog.tsを使用する。
 * 使い方は以下の通り。
 * 1. Dialog、UseDialogをインポートする
 *
 * 2. UseDialogから、ref, showModal, closeModalを取得する
 *    例）const { ref, showModal, closeModal } = UseDialog();
 *
 * 3. ダイアログを表示するボタンを作成し、showModalを埋め込む
 *    例）<button onClick={showModal}>ダイアログを表示</button>
 *
 * 4. ダイアログ内に表示する要素を記述し、ref, isOpen, DialogImageWrapper, closeModalを埋め込む
 *    例）<Dialog ref={ref} isOpen={false} DialogImageWrapper={<DialogIm...} closeModal={closeModal}><p>表示内容</p></Dialog>
 *
 * 5. 初回レンダリング時にモーダルを表示したい場合は、isOpenをtrueにする
 * ※※※※※※※※※※※※※※※※※※※※※※※※※※※
 */
/**
 * ダイアログコンポーネント
 * @param isOpen ダイアログを表示するかどうか
 * @param DialogImageWrapper ダイアログ内に表示する画像のラッパー
 * @param children ダイアログ内に表示する要素
 * @param closeModal ダイアログを閉じる関数
 * @param ref ダイアログのDOM要素を参照するためのref
 * @param showCloseButton 閉じるボタンを表示するかどうか
 * @returns ダイアログコンポーネント
 */
const Dialog: React.ForwardRefExoticComponent<
    React.PropsWithoutRef<DialogType> & React.RefAttributes<HTMLDialogElement>
> = React.forwardRef(
    (
        {
            isOpen,
            DialogImageWrapper,
            children,
            closeModal,
            showCloseButton = true,
            dialogContentPadding = '68px 20px 30px',
            $color = '#333333',
            $backgroundColor = '#ffffff',
            $borderColor = '#d9e0e8',
            $backgroundAlphaPercent = 70,
        },
        ref
    ) => {
        // 初回レンダリング時にのみモーダルを表示する
        useEffect(() => {
            const copiedRef = ref;
            // refにcurrentプロパティが存在し、isOpenがtrueの場合にモーダルを表示する
            if (
                copiedRef &&
                'current' in copiedRef &&
                copiedRef.current &&
                isOpen
            ) {
                copiedRef.current.showModal();
            }
            return () => {
                // クリーンアップ
                if (copiedRef && 'current' in copiedRef && copiedRef.current) {
                    copiedRef.current.close();
                }
            };
        });
        return (
            // オーバレイをクリックした時にダイアログを閉じる
            <DialogContainer
                ref={ref}
                onClick={closeModal}
                $color={$color}
                $backgroundColor={$backgroundColor}
                $borderColor={$borderColor}
                $backgroundAlphaPercent={$backgroundAlphaPercent}
            >
                {/* ダイアログをクリックした時は閉じない */}
                <DialogInnerWrapper onClick={(e) => e.stopPropagation()}>
                    {DialogImageWrapper}
                    <DialogContent $dialogContentPadding={dialogContentPadding}>
                        {/* Dialogタグで囲まれた配下の要素を表示する */}
                        {children}
                    </DialogContent>
                    {showCloseButton && (
                        <CloseButton onClick={closeModal}>
                            <span className="line1"></span>
                            <span className="line2"></span>
                        </CloseButton>
                    )}
                </DialogInnerWrapper>
            </DialogContainer>
        );
    }
);

export default Dialog;
Dialog.displayName = 'Dialog';
