import CookieManager from '../lib/cookieManager';
import { createAxiosInstance } from '../lib/createAxiosInstance';
import { API_PATHS } from './constants/apiPaths';
import { AxiosError, AxiosResponse } from 'axios';

/**
 * アクセストークン取得APIを呼び出してSetCookieするところまでやる
 * @throws {Error} API呼び出しに失敗した場合にエラーを投げる
 */
export const getTokenFromNonce = async (nonce: string): Promise<boolean> => {
    try {
        const instance = createAxiosInstance();
        const response: AxiosResponse = await instance.get(
            API_PATHS.GET_ACCESS_TOKEN_BY_NONCE,
            {
                params: {
                    nonce: nonce,
                },
            }
        );
        const apiToken: string = response?.data?.api_token ?? undefined;

        if (!apiToken) {
            throw new Error('Get Token From Nonce failed.');
        }

        CookieManager.setCookieValue('api_token', apiToken);

        return true;
    } catch (error) {
        if (error && error instanceof AxiosError) {
            throw error;
        }
        return false;
    }
};
