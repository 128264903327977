// FooterIcon.tsx
import React from 'react';
import styled from 'styled-components';

/**
 * Style for the footer icon.
 */
export const IconContainer = styled.div`
    img {
        height: 33px;
    }
`;

/**
 * FooterIcon component renders an icon.
 * @param src - Source URL of the icon.
 * @param alt - Alt text for the icon.
 */
export const FooterIcon: React.FC<{
    src: string;
    alt: string;
    width: string;
}> = ({ src, alt, width }) => {
    return (
        <IconContainer>
            <img src={src} alt={alt} width={width} />
        </IconContainer>
    );
};
