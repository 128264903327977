import React from 'react';
import styled from 'styled-components';
import iconExternalLink from '../assets/images/icon-externallink.svg';
import { ga4PushEvent } from '../ga4';
import { GA4_CUSTOM_EVENT } from '../constants/ga4CustomEvent';
import { EXTERNAL_LINKS } from '../constants/externalLinks';

const SupportLinkStyle = styled.div`
    padding: 20px;
    margin-top: 15px;
    color: #141313;
    text-align: left;
    text-decoration: none;
    overflow-wrap: break-word;
    background-color: #ffffff;
    border: 1px solid #d9e0e8;
    border-radius: 5px;
`;

const Title = styled.span`
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
`;

const Link = styled.a`
    display: flex;
    align-items: flex-start;
    margin-top: 5px;
    line-height: 24px;
    color: black;
    text-decoration: none;
    word-break: break-all;
`;

// 外部リンクへ遷移する画像のスタイル
const LinkImage = styled.img`
    width: 20px;
    height: 12px;
    margin-left: 40px;

    /* #141313 に相当するfilter */
    filter: brightness(0) saturate(100%) invert(5%) sepia(4%) saturate(486%)
        hue-rotate(314deg) brightness(100%) contrast(95%);
`;

interface SupportLinkProps {
    title: string;
    url: string;
}

/**
 * iOS or Androidのサポートリンクをクリックした場合にイベントを送信する
 * @param url サポートリンク
 */
const onClickSupportLink = (url: string) => () => {
    let ga4EventName = GA4_CUSTOM_EVENT.PRESSED_IOS_GPS_ERROR_LINK;
    if (url === EXTERNAL_LINKS.ANDROID_GPS_ERROR_LINK) {
        ga4EventName = GA4_CUSTOM_EVENT.PRESSED_ANDROID_GPS_ERROR_LINK;
    }
    ga4PushEvent(ga4EventName);
};

const SupportLink: React.FC<SupportLinkProps> = ({ title, url }) => {
    return (
        <SupportLinkStyle>
            <Title>{title}</Title>
            <Link
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                onClick={onClickSupportLink(url)}
            >
                {url}
                <LinkImage src={iconExternalLink} />
            </Link>
        </SupportLinkStyle>
    );
};

export default SupportLink;
