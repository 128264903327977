import { ComponentType } from 'react';
import { withAuthentication } from './components/withAuthentication';

/**
 * 引数で受け取ったコンポーネントに認証機能を付与する高階コンポーネント
 * @param {Object} props - プロパティ
 * @param {ComponentType} props.component - 認証機能を付与するコンポーネント
 * @returns {ComponentType} 認証機能が付与されたコンポーネント
 */
const WithAuthenticationWrapper = ({
    component: Component,
    ...props
}: {
    component: ComponentType;
    [x: string]: unknown;
}) => {
    const AuthComponent = withAuthentication(Component);
    return <AuthComponent {...props} />;
};

export default WithAuthenticationWrapper;
