import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getPictures } from '../api/getPictures';
import Picture from '../types/pictureType';
import picturesIcon from '../assets/images/icon_img.svg';
import { postPicture } from '../api/postPicture';
import { postItem } from '../components/postItem';
import { ButtonLink } from './button';
import Dialog from '../components/Dialog';
import { useDialog } from '../components/UseDialog';
import dolekunDauntingPoseImage from '../assets/images/dolekun-daunting-pose.svg';
import dialogImage from '../assets/images/dolekun-raise-one-hand.svg';
import { MESSAGES } from '../constants/messages';
import { Spinner } from '../Spinner';
import { ImageCompressionError } from '../CustomError';
import { PATH_MAPPING } from '../constants/pathMapping';
import { SUGOROKU_ITEM_ACTION } from '../constants/SugorokuItemAction';
import { SUGOROKU_ITEM_CODE } from '../constants/sugorokuItemCode';
import { Link } from 'react-router-dom';
import { useShowItemNoticeBadgeContext } from '../hooks/useShowNoticeBadgeContext';
import { NftDetail as NftDetailType } from '../types/nftDetailType';
import axios, { AxiosError } from 'axios';
import { DiceReceivedDialogMessage } from './DiceReceivedDialogMessage';
/**
 * 保存可能な写真の最大枚数
 */
const MAX_IMAGE_COUNT = 3;

const Image = styled.img`
    width: calc(50vw - 26px);
    max-width: 175px;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 1;
    cursor: ${({ src }) => (src ? 'pointer' : 'default')};
`;

interface PlaceholderBoxProps {
    $index: number;
}

const PlaceholderBox = styled.div<PlaceholderBoxProps>`
    position: relative;
    width: calc(50vw - 26px);
    max-width: 175px;
    aspect-ratio: 1;
    cursor: ${({ $index }) => ($index === 0 ? 'pointer' : 'default')};
    background-color: ${({ $index }) => ($index === 0 ? '#D9D9D9' : '#E4E4E4')};

    ${({ $index }) =>
        $index === 0 &&
        `
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 50px;
            height: 2px;
            background-color: #ffffff;
        }

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(90deg);
            width: 50px;
            height: 2px;
            background-color: #ffffff;
        }

        &::before:last-child {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(0deg);
            width: 25px;
            height: 2px;
            background-color: #ffffff;
        }
    `}

    ${({ $index }) =>
        $index > 0 &&
        `
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 80px;
            height: 55px;
            filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(95%) contrast(102%);
        }
    `}
`;

const PlaceholderSpinnerBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(50vw - 26px);
    max-width: 175px;
    aspect-ratio: 1;
    pointer-events: none;
    background-color: #d9d9d9;

    ${Spinner} {
        width: 60px;
        height: 60px;
        border-width: 8px;
    }
`;

const HiddenFileInput = styled.input`
    display: none;
`;

const StyledSwiper = styled(Swiper)`
    margin: 40px -20px 0 0;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    .swiper-wrapper {
        display: flex;

        .swiper-slide {
            flex-shrink: 1;
        }
    }
`;

const LoadingArea = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    justify-content: center;
    min-height: 100px;
    padding: 50px 20px 0;
    margin-top: 30px;
`;

const BestShotsText = styled.div`
    margin-top: 41px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;

    @media (width <= 768px) {
        font-size: 16px;
    }
`;

const ErrorDialogImageWrapper = styled.div`
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
`;

const DialogImage = styled.img`
    width: 70px;
`;

const DialogMessageWrapper = styled.span`
    font-size: 16px;
    font-weight: bold;
    text-align: center;
`;

const ButtonContainer = styled.div`
    display: flex;
    gap: 0.75rem;
    justify-content: space-between;
    margin-top: 20px;
`;

const StyledButton = styled.button`
    width: 130px;
    padding: 12px 0;
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid #000000;
    border-radius: 20px;

    /* アクセシビリティ的によくないが、最初からネガティブな選択肢にフォーカスがあたっているのが気に食わないので… */
    &:focus-visible {
        outline: none;
    }
`;

const OkButton = styled(StyledButton)`
    color: white;
    background-color: #000000;
    border: 1px solid black;
`;

/**
 * すごろく画面へのリンクのテキスト
 */
const SaikoroConfirmationDialogLink = styled(Link)`
    display: inline-block;
    margin-top: 10px;
    font-weight: bold;

    /* Dialog.aに負けたくない */
    color: blue !important;
`;

/**
 * ダイアログ画像をラップするコンポーネント
 */
const DialogImageWrapper = styled.div`
    position: absolute;
    top: -45px;
    left: 50%;
    transform: translateX(-50%);
`;

const NoticeText = styled.span`
    display: inline-block;
    width: 100%;
    margin-top: 10px;
    font-size: 14px;
    text-align: center;
`;

/**
 * サイコロ獲得メッセージ
 */
const DialogSubMessageWrapper = styled.div`
    margin-top: 20px;
    font-weight: 500;
`;

interface PhotoGalleryProps {
    accountNftId: number;
    onSelectImage: (imageId: number) => void;
    onPicturesLoaded: (pictures: Picture[]) => void;
    pictures: Picture[];
    nftData: NftDetailType;
}

const PhotoGallery: React.FC<PhotoGalleryProps> = ({
    accountNftId,
    onSelectImage,
    onPicturesLoaded,
    pictures,
    nftData,
}) => {
    const [picturesLength, setPicturesLength] = useState(pictures.length);
    const [fetchSucceeded, setFetchSucceeded] = useState(false);
    const [loading, setLoading] = useState(true);
    const fileInputRef = React.useRef<HTMLInputElement>(null);
    const [newlyUploadedPicture, setNewlyUploadedPicture] =
        useState<Picture | null>(null);
    const [hasError, setHasError] = useState(false);
    const [saved, setSaved] = useState(false);
    const [saving, setSaving] = useState(false);
    const { ref, closeModal } = useDialog();
    const [dialogMessage, setDialogMessage] = useState('');
    const [showSucceededMessage, setShowSucceeded] = useState(false);
    // すごろくアイテムを獲得したかどうかのフラグ
    const [hasAcquiredDice, setHasAcquiredDice] = useState(false);
    // フッターに「！」を表示するためのフラグを取得
    const { setShowItemNoticeBadge } = useShowItemNoticeBadgeContext();

    const callPostItem = async () => {
        const isSugorokuDisabled =
            import.meta.env.VITE_SUGOROKU_DISABLED === 'true';
        if (isSugorokuDisabled) {
            return;
        }
        try {
            // アイテム獲得APIを呼び出す
            const hasAcquiredDice = await postItem(
                SUGOROKU_ITEM_ACTION.POST_PICTURE,
                SUGOROKU_ITEM_CODE.DICE,
                nftData.event.id
            );
            if (hasAcquiredDice) {
                // すごろくアイテム獲得をしたため、フッターに「！」つける
                setShowItemNoticeBadge(true);
                // すごろくアイテムを獲得したフラグを立てる
                setHasAcquiredDice(true);
            }
        } catch (error) {
            // エラーログを出力
            if (axios.isAxiosError(error)) {
                const axiosError = error as AxiosError;
                // 409エラーは重複エラー(既にアイテム獲得済み)なので、エラーログを出力しない
                if (axiosError.response?.status !== 409) {
                    console.error(
                        'Error sending post item to the backend:',
                        error
                    );
                }
                // 予期せぬエラーが発生した場合
            } else {
                console.error(
                    'Unexpected error sending post item to the backend:',
                    error
                );
            }
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        setSaved(false);
        if (file) {
            setSaving(true);
            postPicture(accountNftId, file)
                .then((createdPicture) => {
                    setNewlyUploadedPicture(createdPicture);
                    setSaved(true);
                    // @HACK picturesの更新速度が遅い…この時点では、アップロードされた枚数 - 1となる
                    //       あんまりスッキリしないやり方だが、とりあえずこれでアップロード後の写真の枚数は取れるのでやむを得ない
                    const newPicturesLength = pictures.length + 1;
                    setPicturesLength(newPicturesLength);
                    // @HACK setPicturesLengthが反映されるのが遅いので仕方なく…
                    setShowSucceeded(newPicturesLength === MAX_IMAGE_COUNT);
                    // アイテム獲得APIを呼び出す
                    callPostItem();
                })
                .catch((error) => {
                    if (error instanceof ImageCompressionError) {
                        console.error('Image compression failed:', error);
                        setDialogMessage(MESSAGES.UNSUPPORTED_IMAGE_FORMAT);
                    } else {
                        console.error('Error uploading picture:', error);
                        setDialogMessage(MESSAGES.UPLOAD_PICTURE_FAILED);
                    }
                    setHasError(true);
                })
                .finally(() => {
                    setSaving(false);
                });
        }
    };

    const handleUploadClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const fetchPictures = useCallback(async () => {
        setLoading(true);
        try {
            const fetchedPictures = await getPictures(accountNftId);
            if (newlyUploadedPicture) {
                fetchedPictures.push(newlyUploadedPicture);
                setNewlyUploadedPicture(null);
            }
            setFetchSucceeded(true);
            onPicturesLoaded(fetchedPictures);
        } catch (error) {
            console.error('Error fetching pictures:', error);
            setFetchSucceeded(false);
        } finally {
            setLoading(false);
        }
    }, [accountNftId, onPicturesLoaded, newlyUploadedPicture]);

    useEffect(() => {
        fetchPictures();
    }, [accountNftId, fetchPictures]);

    const renderPlaceholders = useCallback((): JSX.Element[] => {
        const placeholders: JSX.Element[] = [];
        // 画像が3枚未満の場合、プラスアイコンのプレースホルダーを追加
        if (pictures.length < MAX_IMAGE_COUNT) {
            placeholders.push(
                <SwiperSlide key="placeholder-0">
                    {saving ? (
                        <PlaceholderSpinnerBox>
                            <Spinner />
                        </PlaceholderSpinnerBox>
                    ) : (
                        <PlaceholderBox
                            $index={0}
                            onClick={handleUploadClick}
                        />
                    )}
                </SwiperSlide>
            );
        }

        // 画像を追加
        for (let i = 0; i < pictures.length; i++) {
            placeholders.push(
                <SwiperSlide key={`picture-${i}`}>
                    <Image
                        src={pictures[i].account_pictures_url}
                        alt={`Account pictures ${i}`}
                        onClick={() => {
                            onSelectImage(pictures[i].id);
                        }}
                    />
                </SwiperSlide>
            );
        }

        // 残りのプレースホルダーの数を計算
        const remainingPlaceholders = MAX_IMAGE_COUNT - 1 - pictures.length;

        // 残りのプレースホルダーを追加
        for (let i = 1; i <= remainingPlaceholders; i++) {
            placeholders.push(
                <SwiperSlide key={`placeholder-${i}`}>
                    <PlaceholderBox $index={1}>
                        <img
                            src={picturesIcon}
                            alt={`Placeholder image ${i}`}
                        />
                    </PlaceholderBox>
                </SwiperSlide>
            );
        }

        return placeholders;
    }, [pictures, onSelectImage, saving]);

    /**
     * 成功ダイアログをcloseModalしつつステートの更新を行う
     */
    const closeSucceededModal = () => {
        closeModal();
        setSaved(false);
        // すごろくアイテムを獲得したフラグをリセット
        setHasAcquiredDice(false);
    };

    /**
     * 画像がまだアップロード可能な状態でダイアログを閉じようとしたときに表示するメッセージを設定する
     */
    const handleCloseImageUploadableDialog = () => {
        setShowSucceeded(true);
    };

    let element;

    if (loading) {
        element = <LoadingArea>Loading...</LoadingArea>;
    } else if (!fetchSucceeded) {
        element = (
            <LoadingArea>
                画像の読み込みに失敗しました。
                <br />
                <ButtonLink
                    to="#"
                    onClick={fetchPictures}
                    bgColor="#000000"
                    textColor="#ffffff"
                    borderColor="#000000"
                >
                    再取得する
                </ButtonLink>
            </LoadingArea>
        );
    } else {
        element = (
            <>
                <StyledSwiper
                    spaceBetween={11}
                    slidesPerView={'auto'}
                    autoplay={{ delay: 3000 }}
                >
                    {renderPlaceholders()}
                </StyledSwiper>
                <NoticeText>（他のユーザーには公開されません）</NoticeText>
            </>
        );
    }

    return (
        <>
            <BestShotsText>
                今日の思い出ベスト{MAX_IMAGE_COUNT}ショット
            </BestShotsText>
            {element}
            <HiddenFileInput
                type="file"
                accept="image/*,.png,.jpg,.jpeg,.heic,.heif,.heics,.heifs"
                ref={fileInputRef}
                onChange={handleFileChange}
            />
            {hasError && (
                <Dialog
                    ref={ref}
                    isOpen={true}
                    DialogImageWrapper={
                        <ErrorDialogImageWrapper>
                            <DialogImage
                                src={dolekunDauntingPoseImage}
                                alt="dolekun"
                            />
                        </ErrorDialogImageWrapper>
                    }
                    closeModal={() => {
                        setHasError(false);
                        setDialogMessage('');
                    }}
                >
                    <DialogMessageWrapper>{dialogMessage}</DialogMessageWrapper>
                </Dialog>
            )}
            {saved && (
                <Dialog
                    ref={ref}
                    isOpen={true}
                    DialogImageWrapper={
                        <DialogImageWrapper>
                            <DialogImage src={dialogImage} alt="dolekun" />
                        </DialogImageWrapper>
                    }
                    closeModal={
                        showSucceededMessage
                            ? closeSucceededModal
                            : handleCloseImageUploadableDialog
                    }
                    dialogContentPadding="60px 20px 40px"
                >
                    {showSucceededMessage ? (
                        // すごろくアイテムを獲得したかどうかで表示するダイアログを変える
                        hasAcquiredDice ? (
                            <>
                                <DialogMessageWrapper>
                                    {MESSAGES.SAVE_PICTURE_SUCCEEDED_PREFIX}
                                </DialogMessageWrapper>
                                <DiceReceivedDialogMessage diceCount={1} />
                                <SaikoroConfirmationDialogLink
                                    to={PATH_MAPPING.SUGOROKU}
                                >
                                    すごろくマップへ
                                </SaikoroConfirmationDialogLink>
                            </>
                        ) : (
                            <>
                                <DialogMessageWrapper>
                                    {MESSAGES.SAVE_PICTURE_SUCCEEDED_PREFIX}
                                </DialogMessageWrapper>
                                <DialogSubMessageWrapper>
                                    {MESSAGES.SAVE_TRAJECTORY_SUCCEEDED_SUFFIX}
                                </DialogSubMessageWrapper>
                            </>
                        )
                    ) : (
                        <>
                            <DialogMessageWrapper>
                                あと{MAX_IMAGE_COUNT - picturesLength}
                                枚保存できます。
                            </DialogMessageWrapper>
                            <ButtonContainer>
                                <OkButton onClick={handleUploadClick}>
                                    続けて保存する
                                </OkButton>
                            </ButtonContainer>
                        </>
                    )}
                </Dialog>
            )}
        </>
    );
};

export default PhotoGallery;
