import JLeagueLogoutUrlType from '../../types/jLeagueLogoutUrl';

export const jLeagueLogoutUrlValidator = (
    jLeagueLogoutUrl: JLeagueLogoutUrlType
) => {
    if (!jLeagueLogoutUrl) {
        return false;
    }
    if (!jLeagueLogoutUrl.jleague_logout_url) {
        return false;
    }
    return true;
};
