import JLeagueAccountInfoType from '../../types/jLeagueAccountInfo';

export const jLeagueAccountInfoValidator = (
    jLeagueAccountInfo: JLeagueAccountInfoType
) => {
    if (!jLeagueAccountInfo) {
        return false;
    }
    if (!jLeagueAccountInfo.unique_key) {
        return false;
    }
    if (!jLeagueAccountInfo.first_name) {
        return false;
    }
    if (!jLeagueAccountInfo.last_name) {
        return false;
    }
    if (!jLeagueAccountInfo.attribute1) {
        return false;
    }
    if (!jLeagueAccountInfo.gender) {
        return false;
    }
    return true;
};
