import React, { useState, useEffect, useRef, useReducer } from 'react';
import styled from 'styled-components';
import DateFormatter from '../lib/dateFormatter';
import pencilIcon from '../assets/images/icon_ edit.svg';
import { getDiary } from '../api/getDiary';
import { putDiary } from '../api/putDiary';
import Dialog from '../components/Dialog';
import { useDialog } from '../components/UseDialog';
import dolekunDauntingPoseImage from '../assets/images/dolekun-daunting-pose.svg';
import dialogImage from '../assets/images/dolekun-raise-one-hand.svg';
import { MESSAGES } from '../constants/messages';
import { ButtonLink } from './button';
import { postItem } from '../components/postItem';
import { Link } from 'react-router-dom';
import { PATH_MAPPING } from '../constants/pathMapping';
import { SUGOROKU_ITEM_ACTION } from '../constants/SugorokuItemAction';
import { SUGOROKU_ITEM_CODE } from '../constants/sugorokuItemCode';
import { useShowItemNoticeBadgeContext } from '../hooks/useShowNoticeBadgeContext';
import { NftDetail as NftDetailType } from '../types/nftDetailType';
import axios, { AxiosError } from 'axios';
import { DiceReceivedDialogMessage } from './DiceReceivedDialogMessage';
const MAX_CONTENT_LENGTH = 5000;

const ContentContainer = styled.div`
    margin: 30px 0 0;
`;

const TextBoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: calc(100% - 40px);
    padding-bottom: 20px;
    background-color: #ffffff;
    border-radius: 2px;
`;

const TextBox = styled.textarea`
    min-height: 60px;
    padding-top: 20px;
    margin-left: -5px;

    /* iOSでタップ時にズームされる問題の苦肉の策として、font-sizeは16pxにしつつscaleでちょろまかすことにした。 */
    font-size: 16px;
    line-height: 24px;
    color: #141313;
    resize: none;
    border: none;
    transform: scale(0.875);
    transform-origin: center;

    p &::placeholder {
        font-size: 16px;
        color: #cccccc;
    }

    &:focus {
        border: none;
        outline: none;
    }

    &:disabled {
        color: #141313;
        background-color: #ffffff;

        /* for iOS */
        -webkit-text-fill-color: #141313;
        opacity: 1;
    }
`;

const SaveButton = styled.button<{
    $isEditable: boolean;
    $isEmpty: boolean;
    $saving: boolean;
}>`
    padding: 12px 35px;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    pointer-events: ${({ $isEmpty, $saving }) =>
        $isEmpty || $saving ? 'none' : 'auto'};
    cursor: pointer;
    background-color: ${({ $isEditable, $isEmpty }) =>
        $isEditable || !$isEmpty ? '#000000' : 'rgba(0, 0, 0, 0.5)'};
    border: none;
    border-radius: 20px;
    opacity: ${({ $saving }) => ($saving ? 0.5 : 1)};
`;

const CancelButton = styled.button<{ $isEditable: boolean }>`
    padding: 12px;
    font-size: 14px;
    font-weight: bold;
    color: #141313;
    cursor: pointer;
    background-color: transparent;
    border: #707070 solid 1px;
    border-radius: 20px;
`;

// ボタンのスタイル定義
const ButtonContainer = styled.div`
    display: flex;
    gap: 10px;
    justify-content: center;
    padding-bottom: 20px;
    background-color: #ffffff;
`;

const PencilIconWrapper = styled.div<{ color: string }>`
    svg {
        width: 20px;
        height: 20px;
        fill: ${(props) => props.color};
    }
`;

// Additional styled component for the Pencil icon container
const PencilIconContainer = styled.div`
    width: 20px;
    height: 20px;
    cursor: pointer;
`;

const Timestamp = styled.div`
    font-size: 8px;
    color: #848487;
`;

const DateAndPencilContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    margin-top: 10px;
`;

/**
 * ダイアログ画像をラップするコンポーネント
 */
const DialogImageWrapper = styled.div`
    position: absolute;
    top: -45px;
    left: 50%;
    transform: translateX(-50%);
`;

/**
 * サイコロ獲得メッセージ
 */
const DialogSubMessageWrapper = styled.div`
    margin-top: 20px;
    font-weight: 500;
`;

/**
 * すごろく画面へのリンクのテキスト
 */
const SaikoroConfirmationDialogLink = styled(Link)`
    display: inline-block;
    margin-top: 10px;
    font-weight: bold;

    /* Dialog.aに負けたくない */
    color: blue !important;
`;

const ErrorDialogImageWrapper = styled.div`
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
`;

const DialogImage = styled.img`
    width: 70px;
`;

// ダイアログのメッセージをラップする
const DialogMessageWrapper = styled.span`
    font-size: 16px;
    font-weight: bold;
    text-align: center;
`;

const LoadingArea = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    justify-content: center;
    min-height: 100px;
    padding: 25px 20px;
    margin: 30px 0 0;
    background-color: #ffffff;
`;

const NoticeText = styled.span`
    display: inline-block;
    width: 100%;
    margin-top: 10px;
    font-size: 14px;
    text-align: center;
`;

interface DiaryEditorProps {
    accountNftId: number;
    activeTab: number;
    nftData: NftDetailType;
}

const DiaryEditor: React.FC<
    DiaryEditorProps & {
        content: string;
        setContent: React.Dispatch<React.SetStateAction<string>>;
        lastEdited: string;
        setLastEdited: React.Dispatch<React.SetStateAction<string>>;
    }
> = ({
    accountNftId,
    content,
    setContent,
    lastEdited,
    setLastEdited,
    activeTab,
    nftData,
}) => {
    const initialState = {
        saved: false,
        hasAcquiredDice: false,
        saving: false,
    };

    type SavedAndHasAcquiredActionType = {
        type: 'SET_SAVED_AND_HAS_ACQUIRED_DICE';
        payload: {
            saved: boolean;
            hasAcquiredDice: boolean;
        };
    };

    type SavingActionType = {
        type: 'SAVING';
    };

    const reducer = (
        state: typeof initialState,
        action: SavedAndHasAcquiredActionType | SavingActionType
    ) => {
        switch (action.type) {
            case 'SAVING':
                return {
                    ...state,
                    saving: true,
                };
            case 'SET_SAVED_AND_HAS_ACQUIRED_DICE':
                return {
                    ...state,
                    saved: action.payload.saved,
                    hasAcquiredDice: action.payload.hasAcquiredDice,
                    saving: false,
                };
            default:
                return state;
        }
    };
    const { ref, closeModal: originalCloseModal } = useDialog();
    const [submittable, setSubmittable] = useState(false);
    const [loading, setLoading] = useState(true);
    const [getDiarySucceeded, setGetDiarySucceeded] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [submittedContent, setSubmittedContent] = useState('');
    const [hasError, setHasError] = useState(false);
    const [state, dispatch] = useReducer(reducer, initialState);
    // フッターに「！」を表示するためのフラグを取得
    const { setShowItemNoticeBadge } = useShowItemNoticeBadgeContext();
    // useRefフックを使ってテキストエリアのDOM要素への参照を作成します。
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = event.target.value;
        setContent(newValue);
        const now = new Date();
        const formattedDate = DateFormatter.formatDate(
            `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}`
        );
        setLastEdited(`最終更新: ${formattedDate}`);
        setIsChanged(newValue !== submittedContent);
    };

    const fetchDiary = async () => {
        setLoading(true);
        try {
            const diary = await getDiary(accountNftId);
            setContent(diary.content);
            setSubmittedContent(diary.content);
            setLastEdited(`最終更新: ${diary.updatedAt}`);
            setGetDiarySucceeded(true);
        } catch (error) {
            setGetDiarySucceeded(false);
            console.error('Error fetching diary:', error);
        } finally {
            setLoading(false);
        }
    };

    const callPostItem = async () => {
        let hasAcquiredDice = false;
        const isSugorokuDisabled =
            import.meta.env.VITE_SUGOROKU_DISABLED === 'true';
        if (isSugorokuDisabled) {
            return hasAcquiredDice;
        }
        try {
            // アイテム獲得APIを呼び出す
            hasAcquiredDice = await postItem(
                SUGOROKU_ITEM_ACTION.PUT_DIARY,
                SUGOROKU_ITEM_CODE.DICE,
                nftData.event.id
            );
            if (hasAcquiredDice) {
                // すごろくアイテム獲得をしたため、フッターに「！」つける
                setShowItemNoticeBadge(true);
            }
        } catch (error) {
            // エラーログを出力
            hasAcquiredDice = false;
            if (axios.isAxiosError(error)) {
                const axiosError = error as AxiosError;
                // 409エラーは重複エラー(既にアイテム獲得済み)なので、エラーログを出力しない
                if (axiosError.response?.status !== 409) {
                    console.error(
                        'Error sending post item to the backend:',
                        error
                    );
                }
                // 予期せぬエラーが発生した場合
            } else {
                console.error(
                    'Unexpected error sending post item to the backend:',
                    error
                );
            }
        }
        return hasAcquiredDice;
    };

    /**
     * ReactのuseEffectフックを利用してテキストエリアの高さを動的に調整
     *
     * この効果は、テキストエリアの内容に基づいてその高さを調整し、ユーザーが入力するときに
     * スクロールバーなしでテキストを全て表示できるようにします。これにより、ユーザーエクスペリエンスが向上
     *
     * この効果は`content`、`editable`、`submittedContent`、`activeTab`変数に依存しており、
     * これらの値が変更されるたびに再実行されます。これにより、テキストエリアのサイズが常に
     * 現在の内容に適切であることを保証
     */
    useEffect(() => {
        /**
         * `textAreaRef`で参照されるテキストエリアの高さを調整する関数
         *
         * 高さを一時的に'auto'に設定して正確に`scrollHeight`を読み取り、
         * これはスクロールなしで全ての内容を表示するのに必要な最小の高さを表す。
         * 次に、実際の高さをこの`scrollHeight`または最小値に設定し、テキストエリアが
         * 決して小さすぎないことを保証
         */
        const adjustTextAreaHeight = () => {
            if (textAreaRef.current) {
                textAreaRef.current.style.height = 'auto';
                // 微妙に10行目がハミ出て見えるくらいの高さを指定
                textAreaRef.current.style.height = `${Math.max(Math.min(textAreaRef.current.scrollHeight, 226), 60)}px`;
                // テキストエリアの高さが高くないときはpadding-topを20px、高くなってくるにつれて徐々に0に近づけていく
                const paddingTop =
                    30 *
                    (1 - Math.min(textAreaRef.current.scrollHeight / 226, 1));
                textAreaRef.current.style.paddingTop = `${paddingTop}px`;
            }
        };

        adjustTextAreaHeight();
    }, [content, submittable, submittedContent, activeTab]);

    useEffect(() => {
        /**
         * 日記の取得 & 最終更新日のセット
         */
        fetchDiary();
        // ページ読み込み時に一発だけでよい
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountNftId]);

    /**
     * handleSave - 「保存」ボタンをクリックしたときの処理
     * 提出されたコンテンツを更新し、編集モードを終了
     */
    const handleSave = async () => {
        dispatch({ type: 'SAVING' });
        let error: Error | unknown;
        try {
            await putDiary(accountNftId, content);
        } catch (e) {
            error = e;
            setHasError(true);
            return;
        } finally {
            if (!error) {
                // アイテム獲得APIを呼び出す
                const hasAcquiredItem = await callPostItem();
                dispatch({
                    type: 'SET_SAVED_AND_HAS_ACQUIRED_DICE',
                    payload: { saved: true, hasAcquiredDice: hasAcquiredItem },
                });
                setSubmittedContent(content);
                setSubmittable(false);
                setIsChanged(false);
            }
        }
    };

    /**
     * handleEdit - 鉛筆アイコンをクリックしたときの処理
     * 編集モードを開始
     */
    const handleEdit = () => {
        setSubmittable(true);
        // 状態を設定してコンポーネントが更新され、
        // テキストエリアが編集可能になってからフォーカスを設定するようにします。また、カーソルを末尾に動かしてあげてる。
        setTimeout(() => {
            if (textAreaRef.current) {
                const end = textAreaRef.current.value.length;
                textAreaRef.current.focus();
                textAreaRef.current.setSelectionRange(end, end);
            }
        }, 0);
    };

    /**
     * handleCancel - 「キャンセル」ボタンをクリックしたときの処理
     * 編集前の内容に戻し、編集モードを終了
     */
    const handleCancel = () => {
        setContent(submittedContent);
        setSubmittable(false);
    };

    /**
     * 鉛筆アイコンのSVGを返す関数コンポーネント。
     * アイコンの色は'color'プロパティを通じてカスタマイズできます。
     *
     * @param props コンポーネントのプロパティ
     * @param [props.color='currentColor'] 鉛筆アイコンの色
     */
    const PencilIcon = ({ color = 'currentColor' }) => (
        <PencilIconWrapper color={color}>
            <svg viewBox="0 0 20 20">
                <use xlinkHref={`${pencilIcon}#icon__edit`} />
            </svg>
        </PencilIconWrapper>
    );

    /**
     * エラーダイアログをcloseModalしつつステートの更新を行う
     */
    const closeErrorModal = () => {
        originalCloseModal();
        setHasError(false);
    };

    /**
     * 成功ダイアログをcloseModalしつつステートの更新を行う
     */
    const closeSucceededModal = () => {
        originalCloseModal();
        dispatch({
            type: 'SET_SAVED_AND_HAS_ACQUIRED_DICE',
            payload: { saved: false, hasAcquiredDice: false },
        });
    };

    let element;

    if (loading) {
        element = <LoadingArea>Loading...</LoadingArea>;
    } else if (!getDiarySucceeded) {
        element = (
            <LoadingArea>
                日記の読み込みに失敗しました。
                <br />
                <ButtonLink
                    to="#"
                    onClick={fetchDiary}
                    bgColor="#000000"
                    textColor="#ffffff"
                    borderColor="#000000"
                >
                    再取得する
                </ButtonLink>
            </LoadingArea>
        );
    } else if (!submittedContent) {
        element = (
            <>
                <ContentContainer>
                    <TextBoxContainer>
                        <TextBox
                            ref={textAreaRef}
                            placeholder="思い出を日記に残しておこう！（ここに入力してください）"
                            value={content}
                            onChange={handleChange}
                            autoComplete="off"
                            maxLength={MAX_CONTENT_LENGTH}
                        />
                    </TextBoxContainer>
                    <ButtonContainer>
                        <SaveButton
                            onClick={handleSave}
                            $isEditable={false}
                            $isEmpty={!isChanged && content.trim().length === 0}
                            $saving={state.saving}
                        >
                            {state.saving ? '保存中…' : '保存'}
                        </SaveButton>
                    </ButtonContainer>
                </ContentContainer>
                <NoticeText>（他のユーザーには公開されません）</NoticeText>
            </>
        );
    } else if (!submittable) {
        element = (
            <>
                <ContentContainer>
                    <TextBoxContainer>
                        <TextBox
                            ref={textAreaRef}
                            placeholder="思い出を日記に残しておこう！（ここに入力してください）"
                            value={submittedContent}
                            onChange={handleChange}
                            autoComplete="off"
                            maxLength={MAX_CONTENT_LENGTH}
                            disabled
                        />
                        <DateAndPencilContainer>
                            <Timestamp>{lastEdited}</Timestamp>
                            <PencilIconContainer onClick={handleEdit}>
                                <PencilIcon color="#848487" />
                            </PencilIconContainer>
                        </DateAndPencilContainer>
                    </TextBoxContainer>
                </ContentContainer>
                <NoticeText>（他のユーザーには公開されません）</NoticeText>
            </>
        );
    } else {
        element = (
            <>
                <ContentContainer>
                    <TextBoxContainer>
                        <TextBox
                            ref={textAreaRef}
                            placeholder="思い出を日記に残しておこう！（ここに入力してください）"
                            value={content}
                            onChange={handleChange}
                            autoComplete="off"
                            maxLength={MAX_CONTENT_LENGTH}
                        />
                        <DateAndPencilContainer>
                            <Timestamp>{lastEdited}</Timestamp>
                            <PencilIconContainer onClick={handleEdit}>
                                <PencilIcon color="#000000" />
                            </PencilIconContainer>
                        </DateAndPencilContainer>
                    </TextBoxContainer>
                    <ButtonContainer>
                        <CancelButton
                            onClick={handleCancel}
                            $isEditable={false}
                        >
                            キャンセル
                        </CancelButton>
                        <SaveButton
                            onClick={handleSave}
                            $isEditable={true}
                            $isEmpty={!isChanged && content.trim().length === 0}
                            $saving={state.saving}
                        >
                            {state.saving ? '保存中…' : '保存'}
                        </SaveButton>
                    </ButtonContainer>
                </ContentContainer>
                <NoticeText>（他のユーザーには公開されません）</NoticeText>
            </>
        );
    }

    return (
        <>
            {element}
            {hasError && (
                <Dialog
                    ref={ref}
                    isOpen={true}
                    DialogImageWrapper={
                        <ErrorDialogImageWrapper>
                            <DialogImage
                                src={dolekunDauntingPoseImage}
                                alt="dolekun"
                            />
                        </ErrorDialogImageWrapper>
                    }
                    closeModal={closeErrorModal}
                >
                    <DialogMessageWrapper>
                        {MESSAGES.SAVE_DIARY_FAILED}
                    </DialogMessageWrapper>
                </Dialog>
            )}
            {state.saved && (
                <Dialog
                    ref={ref}
                    isOpen={true}
                    DialogImageWrapper={
                        <DialogImageWrapper>
                            <DialogImage src={dialogImage} alt="dolekun" />
                        </DialogImageWrapper>
                    }
                    closeModal={closeSucceededModal}
                >
                    {state.hasAcquiredDice ? (
                        <>
                            <DialogMessageWrapper>
                                {MESSAGES.SAVE_DIARY_SUCCEEDED_PREFIX}
                            </DialogMessageWrapper>
                            <DiceReceivedDialogMessage diceCount={1} />
                            <SaikoroConfirmationDialogLink
                                to={PATH_MAPPING.SUGOROKU}
                            >
                                すごろくマップへ
                            </SaikoroConfirmationDialogLink>
                        </>
                    ) : (
                        <>
                            <DialogMessageWrapper>
                                {MESSAGES.SAVE_DIARY_SUCCEEDED_PREFIX}
                            </DialogMessageWrapper>
                            <DialogSubMessageWrapper>
                                {MESSAGES.SAVE_TRAJECTORY_SUCCEEDED_SUFFIX}
                            </DialogSubMessageWrapper>
                        </>
                    )}
                </Dialog>
            )}
        </>
    );
};

export default DiaryEditor;
