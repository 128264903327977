import { PATH_MAPPING } from '../constants/pathMapping';

/**
 * Footer のリンク情報
 */
export const FOOTER_LINKS = {
    MY_PAGE: {
        name: 'マイページ',
        iconName: 'mypagetop',
        link: PATH_MAPPING.MY_PAGE,
        openInNewTab: false,
        iconWidth: '26px',
        end: true,
    },
    CHECK_IN: {
        name: '来場チェック',
        iconName: 'checkin',
        link: PATH_MAPPING.CHECK_IN,
        openInNewTab: false,
        iconWidth: '36px',
        end: true,
    },
    NFT: {
        name: '応援の軌跡',
        iconName: 'trajectory',
        link: PATH_MAPPING.NFT,
        openInNewTab: false,
        iconWidth: '33px',
        end: false,
    },
    SUGOROKU: {
        name: 'すごろく',
        iconName: 'dice',
        link: PATH_MAPPING.SUGOROKU,
        openInNewTab: false,
        iconWidth: '33px',
        end: true,
    },
};
