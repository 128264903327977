import { useContext } from 'react';
import { NoticeBadgeContext } from '../contexts/NoticeBadgeContext';

/**
 * 「来場チェック」の未読バッジの表示状態を取得および更新するためのカスタムフック
 *
 * @returns useContext
 */
export const useShowCheckInNoticeBadgeContext = () => {
    return useContext(NoticeBadgeContext);
};

/**
 * 「すごろく」の未読バッジの表示状態を取得および更新するためのカスタムフック
 *
 * @returns useContext
 */
export const useShowItemNoticeBadgeContext = () => {
    return useContext(NoticeBadgeContext);
};
