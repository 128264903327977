/**
 * SessionStorageの取得・設定を行う
 */
class SessionStorageManager {
    /**
     * SessionStorageから値を取得する
     * @param key SessionStorageのキー
     * @returns キーに合致する値
     */
    static getSessionStorageValue(key: string): string | null {
        return sessionStorage.getItem(key);
    }

    /**
     * SessionStorageから値を取得し、JSON形式に変換する
     * @param key SessionStorageのキー
     * @returns JSON形式の値
     */
    static getSessionStorageValueAsJSON(key: string) {
        const value = sessionStorage.getItem(key);
        if (!value) {
            return null;
        }
        return JSON.parse(value);
    }

    /**
     * SessionStorageに値を設定する
     * @param key SessionStorageのキー
     * @param value SessionStorageに設定する値
     */
    static setSessionStorageValue(key: string, value: string): void {
        sessionStorage.setItem(key, value);
    }

    /**
     * SessionStorageから値を削除する
     * @param key SessionStorageのキー
     */
    static deleteSessionStorageValue(key: string): void {
        sessionStorage.removeItem(key);
    }

    /**
     * すべてのSessionStorageの値を削除する
     */
    static deleteAllSessionStorageValues(): void {
        sessionStorage.clear();
    }
}

export default SessionStorageManager;
