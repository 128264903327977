import { Diary } from '../../types/diaryType';

export const diaryValidator = (diary: Diary) => {
    if (!diary) {
        return false;
    }
    if (typeof diary.content !== 'string') {
        return false;
    }
    if (typeof diary.updatedAt !== 'string') {
        return false;
    }
    return true;
};
