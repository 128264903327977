import React, { useState } from 'react';
import dolekunTiltingImage from '../../assets/images/dolekun-tilting.svg';
import { PATH_MAPPING } from '../../constants/pathMapping';
import Dialog from '../Dialog';
import { useDialog } from '../UseDialog';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

/**
 * チェックイン、登録ダイアログ画像をラップするコンポーネント
 */
const DialogImageWrapperForProbabilityDialog = styled.div`
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
`;

const DialogImageForDialogue = styled.img`
    width: 76px;
`;

const NonRegisterImageDiaryTitle = styled.span`
    font-weight: bold;
    text-align: center;
`;

/**
 * イベント名コンテナ
 */
const EventNameContainer = styled.div`
    margin-top: 20px;
    text-align: left;
`;

/**
 * イベント名
 */
const EventNameLabel = styled.div`
    padding-left: 2em;
    font-size: 14px;
    font-weight: 500;
    text-indent: -1em;
`;

/**
 * NFT一覧画面へのリンクのテキスト
 */
const NftListScreenLink = styled(Link)`
    display: inline-block;
    margin-top: 10px;
    font-weight: bold;

    /* Dialog.aに負けたくない */
    color: blue !important;
`;

interface RegisterDialogProps {
    eventNames: string[];
}

const RegisterDialog: React.FC<RegisterDialogProps> = ({ eventNames }) => {
    const { ref } = useDialog();
    const [isOpen, setIsOpen] = useState(true);

    const handleCloseDialog = () => {
        setIsOpen(false);
    };

    return (
        <Dialog
            ref={ref}
            isOpen={isOpen}
            DialogImageWrapper={
                <DialogImageWrapperForProbabilityDialog>
                    <DialogImageForDialogue
                        src={dolekunTiltingImage}
                        alt="dolekun"
                    />
                </DialogImageWrapperForProbabilityDialog>
            }
            closeModal={handleCloseDialog}
            showCloseButton={true}
        >
            <NonRegisterImageDiaryTitle>
                「写真」または「日記」を
                <br />
                登録していない試合があります。
                <br />
                登録してサイコロを獲得しよう！
                <EventNameContainer>
                    {eventNames.map((eventName, index) => (
                        <EventNameLabel key={index}>
                            ・{eventName}
                            <br />
                        </EventNameLabel>
                    ))}
                </EventNameContainer>
            </NonRegisterImageDiaryTitle>
            <NftListScreenLink to={PATH_MAPPING.NFT}>
                応援の軌跡へ
            </NftListScreenLink>
        </Dialog>
    );
};

export default RegisterDialog;
