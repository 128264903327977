import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

/**
 * スタイルされたLinkコンポーネント
 */
export const StyledLink = styled(Link)<{
    /**
     * position
     */
    $position: string;
    /**
     * 背景色
     */
    $bgColor: string;
    /**
     * テキストの色
     */
    $textColor: string;
    /**
     * ボーダーの色
     */
    $borderColor: string;
    /**
     * 行の高さ
     */
    $lineHeight?: string;
    /**
     * ボタンが無効かどうか
     */
    $disabled?: boolean;
}>`
    position: ${(props) => props.$position};
    box-sizing: border-box;
    width: 100%;
    max-width: 360px;
    height: auto;
    padding: 14px 0;
    line-height: ${(props) => props.$lineHeight};
    color: ${(props) => (props.$disabled ? '#333333' : props.$textColor)};
    text-align: center;
    text-decoration: none;
    pointer-events: ${(props) => (props.$disabled ? 'none' : 'auto')};
    background-color: ${(props) => props.$bgColor};
    border: 1px solid ${(props) => props.$borderColor};
    border-radius: 5px;
    opacity: ${(props) => (props.$disabled ? 0.5 : 1)};
`;
/**
 * ButtonLinkのプロパティ
 */
interface ButtonLinkProps {
    /**
     * リンク先
     */
    to: string;
    /**
     * state
     */
    state?: object;
    /**
     * 子要素
     */
    children?: React.ReactNode;
    /**
     * クリック時に実行される関数
     */
    onClick?: () => void;
    /**
     * position
     */
    position?: string;
    /**
     * 背景色
     */
    bgColor?: string;
    /**
     * テキストの色
     */
    textColor?: string;
    /**
     * ボーダーの色
     */
    borderColor?: string;
    /**
     * パディング
     */
    padding?: string;
    /**
     * フォントサイズ
     */
    fontSize?: string;
    /**
     * 行の高さ
     */
    lineHeight?: string;
    /**
     * ボタンが無効かどうか
     */
    disabled?: boolean;
    /**
     * 新しいタブで開くかどうか
     */
    openInNewTab?: boolean;
}

/**
 * StyledButtonのラッパーコンポーネント
 */
const ButtonLinkComponent: React.FC<ButtonLinkProps> = ({
    to,
    state = {},
    children,
    position = 'static',
    bgColor = 'transparent',
    textColor = 'black',
    borderColor = 'transparent',
    lineHeight = '24px',
    onClick,
    disabled = false,
    openInNewTab = false,
}) => (
    <StyledLink
        to={to}
        state={state}
        onClick={onClick}
        $position={position}
        $bgColor={bgColor}
        $textColor={textColor}
        $borderColor={borderColor}
        $lineHeight={lineHeight}
        $disabled={disabled}
        target={openInNewTab ? '_blank' : undefined}
        rel={openInNewTab ? 'noopener noreferrer' : undefined}
    >
        {children}
    </StyledLink>
);

export const ButtonLink = ButtonLinkComponent;
