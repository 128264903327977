import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useShowItemNoticeBadgeContext } from '../hooks/useShowNoticeBadgeContext';
import { useEffect } from 'react';
import { getAccountSugorokuProgress } from '../api/getAccountSugorokuProgress';
import { postItem } from './postItem';
import { SUGOROKU_ITEM_ACTION } from '../constants/SugorokuItemAction';
import { SUGOROKU_ITEM_CODE } from '../constants/sugorokuItemCode';
import SugorokuItemManager from '../lib/sugorokuItemManager';
import { SESSION_STORAGE_KEYS } from '../constants/sessionStorageKeys';
import { PATH_MAPPING } from '../constants/pathMapping';
import SessionStorageManager from '../lib/sessionStorageManager';

type MyPageLayoutContainerProps = {
    setShowLoginBonusDialog: React.Dispatch<React.SetStateAction<boolean>>;
    setCheckingLoginDialog: React.Dispatch<React.SetStateAction<boolean>>;

    children: React.ReactNode;
};

const MyPageLayoutContainerWrapper = styled.div`
    width: 100%;
    height: 100%;
    min-height: 100dvh;
    background-color: #f3f3f3;
`;

/**
 * マイページレイアウトのコンテナー
 * ※ 最初はログボ判定をMyPageLayoutのuseEffectで行っていたが、MyPageLayoutがNoticeBadgeProviderの外にいるせいで
 *    フッターの「！」が表示されない問題が発生したため、こちらに移動
 */
export const MyPageLayoutContainer: React.FC<MyPageLayoutContainerProps> = ({
    setShowLoginBonusDialog,
    setCheckingLoginDialog,
    children,
}) => {
    const isSugorokuDisabled =
        import.meta.env.VITE_IS_SUGOROKU_DISABLED === 'true';
    const location = useLocation();
    // フッターに「！」を表示するためのフラグを取得
    const { setShowItemNoticeBadge } = useShowItemNoticeBadgeContext();

    useEffect(() => {
        // マイページ内で、すごろく以外の場所にいるときセッションクリア
        // PK→選手詳細→すごろくマップ の場合はまだ余り出目の記憶があると思うが、どこか別ページを挟んだらむしろ余り出目分進むことに違和感を覚えそうなため
        if (!location.pathname.includes(PATH_MAPPING.SUGOROKU)) {
            SessionStorageManager.deleteSessionStorageValue(
                SESSION_STORAGE_KEYS.MY_PAGE_SUGOROKU_REMAIN_DICE_VALUE
            );
        }
        // すごろくマップを意図的に蓋閉めしている場合は何もしない
        if (isSugorokuDisabled) {
            // ログボ付与不要。念の為フッターのバッジも消す。
            setShowItemNoticeBadge(false);
            setCheckingLoginDialog(false);
            return;
        }
        const fetchSugorokuProgress = async () => {
            const progress = await getAccountSugorokuProgress();
            if (!progress) {
                throw new Error('Failed to fetch account sugoroku progress');
            }
            return progress;
        };
        // @MEMO 一旦エラーハンドリングはナシにしてます。
        // 成功するまでマイページ内の遷移によって毎回呼ばれる想定です。
        fetchSugorokuProgress()
            .then(async (progress) => {
                if (progress.is_finished) {
                    // ログボ付与不要。念の為フッターのバッジも消す。
                    setShowItemNoticeBadge(false);
                    setCheckingLoginDialog(false);
                    return;
                }
                // Note: まずサイコロの個数によって！つけるかどうか決める
                const diceCount = SugorokuItemManager.getDiceCount(progress);
                setShowItemNoticeBadge(diceCount > 0);

                // Note: ログインボーナスのチェック
                if (!progress.is_today_login_bonus_received) {
                    const hasAcquiredDice = await postItem(
                        SUGOROKU_ITEM_ACTION.LOGIN,
                        SUGOROKU_ITEM_CODE.DICE
                    );
                    if (hasAcquiredDice) {
                        // すべてのAPIコールが正常に終了した場合にstateの更新とローカルストレージへの保存を行う
                        setShowLoginBonusDialog(true);
                        // すごろくアイテム獲得をしたため、フッターに「！」つける
                        setShowItemNoticeBadge(true);
                    }
                } else {
                    // チェック不要
                    setCheckingLoginDialog(false);
                }
            })
            // エラー発生時はログインダイアログを閉じたことにする
            .catch((e) => {
                console.error(e);
                setCheckingLoginDialog(false);
            });
    }, [
        isSugorokuDisabled,
        location,
        setCheckingLoginDialog,
        setShowLoginBonusDialog,
        setShowItemNoticeBadge,
    ]);
    return (
        <MyPageLayoutContainerWrapper>{children}</MyPageLayoutContainerWrapper>
    );
};
