import axios, { AxiosInstance } from 'axios';
import { generateApiBaseUrl } from './generateApiBaseUrl';
import { setInterceptors } from './setInterceptors';

/**
 * axiosのインスタンスを生成する
 * ヘッダにcookieから取得したJWTを付与する
 * @returns {AxiosInstance} axiosインスタンス
 */
export const createAxiosInstance = (
    contentType: string = 'application/json'
): AxiosInstance => {
    const axiosInstance: AxiosInstance = axios.create({
        baseURL: generateApiBaseUrl(),
        headers: {
            'Content-Type': contentType,
        },
        withCredentials: false,
    });
    setInterceptors(axiosInstance);
    return axiosInstance;
};

/**
 * ヘッダーにAuthorizationを含まないaxiosのインスタンスを生成する
 * ログインのAPIリクエストではAuthorizationをヘッダに含めないためこちらを使う。
 *
 * ※ createAxiosInstanceを使った場合、認証不正が起き、バグの原因となる。
 * ログインリクエストのレスポンスからJWTを受け取り、それをcookieにセットするため、
 * ブラウザキャッシュによって最新ではないトークンが使われバグる。
 * @returns {AxiosInstance} axiosのインスタンス
 */
export const createAxiosInstanceWithoutAuthorization = (): AxiosInstance => {
    const instance: AxiosInstance = axios.create({
        baseURL: generateApiBaseUrl(),
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: false,
    });

    return instance;
};
