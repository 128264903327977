import React from 'react';
import { ButtonLink } from './button';
import Event, { EventContainer } from './Event';
import EventType from '../types/event';
import styled from 'styled-components';

const ButtonWrap = styled.div`
    width: 100%;

    & > a {
        display: inline-block;
        margin-top: 40px;
    }
`;

const AbleToCheckInText = styled.div`
    margin-top: 40px;
    font-size: 20px;
    font-weight: bold;
`;

const EventsSection: React.FC<{
    events: EventType[];
    isCheckInSuccessful: boolean;
    buttonText: string;
    onButtonClick: () => void;
    buttonDisabled: boolean;
}> = ({
    events,
    isCheckInSuccessful,
    buttonText,
    onButtonClick,
    buttonDisabled,
}) => {
    return (
        <>
            {events.map((event: EventType, index: number) => (
                <EventContainer key={index}>
                    <Event {...event} />
                </EventContainer>
            ))}
            {/* TODO 文言は変更の可能性あり */}
            <AbleToCheckInText>
                スタジアム敷地内でのみ保存可能
            </AbleToCheckInText>
            <ButtonWrap>
                <ButtonLink
                    to="#"
                    bgColor={isCheckInSuccessful ? 'grey' : '#000000'}
                    textColor="#ffffff"
                    onClick={onButtonClick}
                    disabled={
                        buttonDisabled ||
                        isCheckInSuccessful ||
                        events[0]?.has_checked_in
                    }
                >
                    {buttonText}
                </ButtonLink>
            </ButtonWrap>
        </>
    );
};

export default EventsSection;
