import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import RecycleBinIcon from '../assets/images/icon_ Garbage.svg';
import LeftArrowWhite from '../assets/images/icon_leftwhite.svg';
import RighttArrowWhite from '../assets/images/icon_rightwhite.svg';
import { deletePicture } from '../api/deletePicture';
import Picture from '../types/pictureType';
import Dialog from '../components/Dialog';
import { useDialog } from '../components/UseDialog';
import dolekunDauntingPoseImage from '../assets/images/dolekun-daunting-pose.svg';
import { Swiper, SwiperSlide, SwiperRef } from 'swiper/react';
import { MESSAGES } from '../constants/messages';

// Overlay that covers the entire viewport
const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    background-color: rgb(0 0 0 / 80%);
`;

const SwiperContainer = styled.div`
    width: 100%;
    margin-top: 44.62px;

    @media (height <= 576px) {
        margin-top: 10px;
    }
`;

const SwiperInnerContainer = styled.div`
    position: relative;
`;

// Mimics ParentContainer but for use in the Overlay
const PopupContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 400px;
    padding: 10px 20px 20px;
    margin: 0 auto;
`;

const CloseButton = styled.div`
    position: relative;
    top: 0;
    width: 35px;
    height: 35px;
    cursor: pointer;

    &::before,
    &::after {
        position: absolute;
        width: 2px;
        height: 100%;
        margin-left: 16px;
        content: '';
        background-color: #ffffff;
        transform-origin: center;
    }

    &::before {
        transform: rotate(45deg);
    }

    &::after {
        transform: rotate(-45deg);
    }
`;

const BigImage = styled.img`
    width: 100%;
    aspect-ratio: 1;
    object-fit: contain;
`;

const ThumbnailsContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
`;

const ThumbnailInnerContainer = styled.div`
    display: flex;
`;

const ThumbnailImage = styled.img<{ $isSelected: boolean }>`
    width: 59px;
    height: 59px;
    margin: 5px;
    object-fit: cover;
    cursor: pointer;
    border: ${({ $isSelected }) =>
        $isSelected ? '1px solid #FFFFFF' : 'none'};
`;

const RecycleBinButtonContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const RecycleBinButton = styled.button`
    width: 28px;
    height: 28px;
    padding: 0;
    cursor: pointer;
    background: none;
    border: none;

    img {
        margin-top: 20px;
    }
`;

const ArrowButton = styled.button`
    position: absolute;
    top: 45%;
    z-index: 10;
    width: 12px;
    height: 24px;
    padding: 0;
    cursor: pointer;
    background: none;
    border: none;
    transform: translateY(-50%);

    &::before {
        position: absolute;
        inset: -10px;
        content: '';
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    gap: 0.75rem;
    justify-content: space-between;
    margin-top: 20px;
`;

const StyledButton = styled.button`
    width: 100px;
    padding: 12px 0;
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid #000000;
    border-radius: 20px;

    /* アクセシビリティ的によくないが、最初からネガティブな選択肢にフォーカスがあたっているのが気に食わないので… */
    &:focus-visible {
        outline: none;
    }
`;

const OkButton = styled(StyledButton)`
    color: white;
    background-color: #d71920;
    border: 1px solid #d71920;
`;

const DeleteDialogImageWrapper = styled.div`
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
`;

// ダイアログのメッセージをラップする
const DialogMessageWrapper = styled.span`
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;
`;

const DialogImage = styled.img`
    width: 70px;
`;

const LeftArrowButton = styled(ArrowButton)`
    left: 15px;
    margin-top: 30px;
`;

const RightArrowButton = styled(ArrowButton)`
    right: 15px;
    margin-top: 30px;
`;

// Popup component
type ImagePopupProps = {
    isOpen: boolean;
    images: number[];
    selectedImage: number | null;
    onClose: () => void;
    onSelectImage: (imageId: number) => void;
    accountNftId: number;
    pictures: Picture[];
    onPicturesUpdated: (updatedPictures: Picture[]) => void;
};

export const ImagePopup: React.FC<ImagePopupProps> = ({
    isOpen,
    images,
    selectedImage,
    onClose,
    onSelectImage,
    accountNftId,
    pictures,
    onPicturesUpdated,
}) => {
    const [currentImages, setCurrentImages] = useState<number[]>([]);
    const { ref: dialogRef, showModal, closeModal } = useDialog();
    const swiperRef = useRef<SwiperRef>(null);
    const recycleBinButtonRef = useRef<HTMLButtonElement>(null);
    const swiperInnerContainerRef = useRef<HTMLDivElement>(null);
    const thumbnailInnerContainerRef = useRef<HTMLDivElement>(null);
    const popupContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (swiperRef.current && swiperRef.current.swiper) {
            const swiper = swiperRef.current.swiper;

            const handleSlideChange = () => {
                const currentIndex = swiper.realIndex;
                const newSelectedImage = currentImages[currentIndex];
                onSelectImage(newSelectedImage);
            };

            swiper.on('slideChange', handleSlideChange);

            // Handle initial slide based on selectedImage
            const currentIndex =
                selectedImage !== null
                    ? currentImages.indexOf(selectedImage)
                    : 0;
            swiper.slideTo(currentIndex);

            return () => {
                swiper.off('slideChange', handleSlideChange);
            };
        }
    }, [currentImages, selectedImage, onSelectImage]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            // 押したら何らかのアクションが起きそうな要素じゃないところを押されたときに閉じるようにする
            const isRecycleBinClicked = recycleBinButtonRef.current?.contains(
                event.target as Node
            );
            const isThumbnailClicked =
                thumbnailInnerContainerRef.current?.contains(
                    event.target as Node
                );
            const isSwiperInnerContainerClicked =
                swiperInnerContainerRef.current?.contains(event.target as Node);
            const isDialogClicked = dialogRef.current?.contains(
                event.target as Node
            );
            if (
                !isRecycleBinClicked &&
                !isThumbnailClicked &&
                !isSwiperInnerContainerClicked &&
                !isDialogClicked
            ) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose, dialogRef]);

    useEffect(() => {
        setCurrentImages(images);
    }, [images]);

    const handleLeftArrowClick = () => {
        const currentIndex =
            selectedImage !== null ? currentImages.indexOf(selectedImage) : -1;
        if (currentIndex > 0) {
            onSelectImage(currentImages[currentIndex - 1]);
        }
    };

    const handleRightArrowClick = () => {
        const currentIndex =
            selectedImage !== null ? currentImages.indexOf(selectedImage) : -1;
        if (currentIndex < currentImages.length - 1) {
            onSelectImage(currentImages[currentIndex + 1]);
        }
    };

    const handleRecycleBinClick = () => {
        showModal();
    };

    const handleAlertConfirm = async () => {
        try {
            const pictureToDelete = pictures.find(
                (picture) => picture.id === selectedImage
            );

            if (pictureToDelete) {
                // Call the deletePicture API
                await deletePicture(accountNftId, pictureToDelete.id);

                // Remove the deleted picture from the pictures prop
                const updatedPictures = pictures.filter(
                    (picture) => picture.id !== selectedImage
                );

                // Update the pictures prop in the parent component
                onPicturesUpdated(updatedPictures);

                // If no images are left, close the popup
                if (updatedPictures.length === 0) {
                    onClose();
                } else {
                    // If there are images left, select the first one
                    const newSelectedImage = updatedPictures[0].id;
                    onSelectImage(newSelectedImage);
                }
            } else {
                console.log('Picture not found');
            }
            closeModal();
        } catch (error) {
            console.error('Failed to delete picture:', error);
        }
    };

    if (!isOpen || currentImages.length === 0) {
        return null;
    }

    return (
        <Overlay>
            <PopupContainer ref={popupContainerRef}>
                <CloseButton onClick={onClose} />
                <SwiperContainer>
                    <SwiperInnerContainer ref={swiperInnerContainerRef}>
                        <LeftArrowButton
                            onClick={handleLeftArrowClick}
                            style={{
                                visibility:
                                    selectedImage === null ||
                                    currentImages.indexOf(selectedImage) === 0
                                        ? 'hidden'
                                        : 'visible',
                            }}
                        >
                            <img src={LeftArrowWhite} alt="Previous image" />
                        </LeftArrowButton>

                        <Swiper
                            ref={swiperRef}
                            spaceBetween={50}
                            slidesPerView={1}
                            initialSlide={
                                selectedImage !== null
                                    ? currentImages.indexOf(selectedImage)
                                    : 0
                            }
                        >
                            {currentImages.map((imageId, index) => {
                                const picture = pictures.find(
                                    (p) => p.id === imageId
                                );
                                return picture ? (
                                    <SwiperSlide key={index}>
                                        <BigImage
                                            src={picture.account_pictures_url}
                                            alt={`Slide ${index}`}
                                        />
                                    </SwiperSlide>
                                ) : null;
                            })}
                        </Swiper>
                        <RightArrowButton
                            onClick={handleRightArrowClick}
                            style={{
                                visibility:
                                    selectedImage === null ||
                                    currentImages.indexOf(selectedImage) ===
                                        currentImages.length - 1
                                        ? 'hidden'
                                        : 'visible',
                            }}
                        >
                            <img src={RighttArrowWhite} alt="Next image" />
                        </RightArrowButton>
                    </SwiperInnerContainer>
                </SwiperContainer>

                <ThumbnailsContainer>
                    <ThumbnailInnerContainer ref={thumbnailInnerContainerRef}>
                        {images.map((imageId) => {
                            const picture = pictures.find(
                                (p) => p.id === imageId
                            );
                            return picture ? (
                                <ThumbnailImage
                                    key={imageId}
                                    src={picture.account_pictures_url}
                                    alt={`Thumbnail ${imageId}`}
                                    $isSelected={imageId === selectedImage}
                                    onClick={() => onSelectImage(imageId)}
                                />
                            ) : null;
                        })}
                    </ThumbnailInnerContainer>
                </ThumbnailsContainer>
                <RecycleBinButtonContainer>
                    <RecycleBinButton
                        onClick={handleRecycleBinClick}
                        ref={recycleBinButtonRef}
                    >
                        <img src={RecycleBinIcon} alt="Recycle Bin Icon" />
                    </RecycleBinButton>
                </RecycleBinButtonContainer>

                <Dialog
                    ref={dialogRef}
                    isOpen={false}
                    DialogImageWrapper={
                        <DeleteDialogImageWrapper>
                            <DialogImage
                                src={dolekunDauntingPoseImage}
                                alt="dolekun"
                            />
                        </DeleteDialogImageWrapper>
                    }
                    closeModal={closeModal}
                    dialogContentPadding="70px 20px 40px"
                >
                    <DialogMessageWrapper>
                        {MESSAGES.PICTURE_DELETE_CONFIRMATION_PREFIX}
                        <br></br>
                        {MESSAGES.PICTURE_DELETE_CONFIRMATION_SUFFIX}
                    </DialogMessageWrapper>

                    <ButtonContainer>
                        <StyledButton onClick={closeModal}>
                            キャンセル
                        </StyledButton>
                        <OkButton onClick={handleAlertConfirm}>OK</OkButton>
                    </ButtonContainer>
                </Dialog>
            </PopupContainer>
        </Overlay>
    );
};
