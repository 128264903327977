import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { FooterIcon, IconContainer } from './FooterIcon';
import NoticeBadge from '../assets/images/notice-badge.svg';
import { ga4PushEvent } from '../ga4';
import { EXTERNAL_LINKS } from '../constants/externalLinks';
import { GA4_CUSTOM_EVENT } from '../constants/ga4CustomEvent';
import { useShowCheckInNoticeBadgeContext } from '../hooks/useShowNoticeBadgeContext';
import { useShowItemNoticeBadgeContext } from '../hooks/useShowNoticeBadgeContext';

import { FOOTER_LINKS } from '../constants/footerLinks';

type FooterLinkProps = {
    name: string;
    iconProps: { src: string; alt: string; width: string };
    link: string;
    openInNewTab?: boolean;
    end?: boolean;
};

/* 必要に応じてFOOTERContainerの高さと幅を調整するや他のCSS設定 */
const FooterLinkContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80px;
    margin-right: 0.6vw;
    margin-left: 0.6vw;
`;

/* テキストのCSS */
const Text = styled.span`
    margin-top: 8px;
    font-size: 8px;
    line-height: 12px;
    text-align: center;
`;
const Badge = styled.img`
    position: absolute;
    top: -10px;
    right: 10px;
    width: 20px;
`;

const FooterLinkWrapper = styled(NavLink)`
    position: relative;
    font-weight: bold;
    color: black;
    text-decoration: none;

    &.active {
        pointer-events: none;
    }

    &:not(.active) > * > ${IconContainer}, &:not(.active) > * > ${Text} {
        opacity: 0.5;
    }
`;

/**
 * FooterLink押下時にGA4イベントを送信する。
 * @param link 遷移しようとしているリンク
 */
const onClickFooterLink = (link: string) => () => {
    // リンクがFAQの場合のみイベントを送信する
    if (link === EXTERNAL_LINKS.NFT_PASSPORT_FAQ_LINK) {
        ga4PushEvent(GA4_CUSTOM_EVENT.PRESSED_FAQ_LINK);
    }
};

/* HTMLマークアップ */
const FooterLink: React.FC<FooterLinkProps> = ({
    name,
    iconProps,
    link,
    openInNewTab,
    end,
}) => {
    // チェックイン通知バッジの表示フラグを取得
    const { showCheckInNoticeBadge } = useShowCheckInNoticeBadgeContext();
    // アイテム通知バッジの表示フラグを取得
    const { showItemNoticeBadge } = useShowItemNoticeBadgeContext();

    return (
        <FooterLinkWrapper
            end={end}
            to={link}
            target={openInNewTab ? '_blank' : ''}
            rel={openInNewTab ? 'noopener noreferrer' : ''}
            onClick={onClickFooterLink(link)}
        >
            <FooterLinkContainer>
                {name === FOOTER_LINKS.CHECK_IN.name &&
                    showCheckInNoticeBadge && <Badge src={NoticeBadge} />}
                {name === FOOTER_LINKS.SUGOROKU.name && showItemNoticeBadge && (
                    <Badge src={NoticeBadge} />
                )}
                <FooterIcon {...iconProps} />
                <Text>{name} </Text>
                {/* TODO nameがFAQだったら要素を追加する */}
            </FooterLinkContainer>
        </FooterLinkWrapper>
    );
};

export default FooterLink;
export type { FooterLinkProps };
