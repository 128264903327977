const nftPassportBaseLink = 'https://announce.nftpassport.jp/';

const supportEmail = 'mailto:nftpassport-support@scsk.jp';

/**
 * アプリ全体で使用する外部リンクを定義する定数ファイル。
 */
export const EXTERNAL_LINKS = {
    // NFTパスポートのベースURL
    NFT_PASSPORT_BASE_LINK: nftPassportBaseLink,
    // NFTパスポートのFAQに遷移するURL
    NFT_PASSPORT_FAQ_LINK: nftPassportBaseLink + '#faq',
    // NFTパスポートのFAQ（GPSが利用不可）に遷移するURL
    NFT_PASSPORT_GPS_DISABLED_LINK:
        nftPassportBaseLink +
        '#位置情報利用を許諾しているはずですが、来場チェックが完了できません。',
    // NFTパスポートのFAQ（範囲外）に遷移するURL
    NFT_PASSPORT_OUTSIDE_VENUE_LINK:
        nftPassportBaseLink +
        '#会場内にいるはずですが、来場チェックが会場外エラーとなります。',
    // コンサドーレ公式の試合日程ページに遷移するURL
    GAME_SCHEDULE_LINK: 'https://www.consadole-sapporo.jp/game/gamelist/',
    // AppleのGPSエラー解決方法のページに遷移するURL
    IOS_GPS_ERROR_LINK: 'https://support.apple.com/ja-jp/HT207092',
    // GoogleのGPSエラー解決方法のページに遷移するURL
    ANDROID_GPS_ERROR_LINK:
        'https://support.google.com/android/answer/6179507?hl=ja',
    // お問い合わせ用メールアドレス
    SUPPORT_EMAIL: supportEmail,
};
