import { SugorokuMaster } from '../types/sugorokuMasterType';
import { getReceiveProbability } from '../api/getReceiveProbability';

/**
 * 現在のステージのエリア名を取得する
 * @param accountSugorokuProgress アカウントすごろく進捗
 * @param sugorokuMaster すごろくマスタデータ
 * @returns 現在のステージのエリア名
 */
export const fetchStageAreaName = async (
    accountSugorokuProgress: AccountSugorokuProgress,
    sugorokuMaster: SugorokuMaster
): Promise<string> => {
    const currentStageCode = accountSugorokuProgress.current_stage_code;
    const mapStage = sugorokuMaster.sugorokuMap.stages.find(
        (stage) => stage.code === currentStageCode
    );
    const stageAreaName = mapStage?.areaName || 'Unknown';
    return stageAreaName;
};

/**
 * 現在のイベントステージの残りのマス数を取得する
 * @param accountSugorokuProgress アカウントすごろく進捗
 * @param mapEvents すごろくマスタデータ
 * @returns 残りのマス数
 */
export const findRemainingTilesInEvent = (
    accountSugorokuProgress: AccountSugorokuProgress | null,
    mapEvents: SugorokuMaster
) => {
    if (accountSugorokuProgress && mapEvents && mapEvents.sugorokuMap) {
        const currentStageCode = accountSugorokuProgress.current_stage_code;
        if (currentStageCode) {
            const currentStage = mapEvents.sugorokuMap.stages.find(
                (stage) => stage.code === currentStageCode
            );
            const currentCell = accountSugorokuProgress.current_cell || 0;
            if (currentStage?.events) {
                const remainingTiles = currentStage.events.reduce(
                    (total, event) => {
                        return total + event.cell;
                    },
                    0
                );
                return remainingTiles - currentCell;
            }
        }
    }
};

/**
 * 受け取り確率を取得し、イベント名の配列を返す
 * @returns イベント名の配列
 */
export const fetchReceiveProbability = async () => {
    try {
        const receiveProbability = await getReceiveProbability();
        // Extract the event names from the receiveProbability response
        const eventNameArray = [
            ...new Set([
                ...receiveProbability.postPicture.map((item) =>
                    item.eventName.replaceAll('<br>', ' ')
                ),
                ...receiveProbability.putDiary.map((item) =>
                    item.eventName.replaceAll('<br>', ' ')
                ),
            ]),
        ];
        return eventNameArray;
    } catch (error) {
        console.error('Failed to fetch receive probability:', error);
        return [];
    }
};

/**
 * これまでに進んだマスと今回進むマスを足し合わせる
 * @param totalCells 総マス数
 * @param tilePosition 現在いるマス数
 * @param remainingTiles 残りのマス数
 * @returns たされたマス数
 */
export const calculateTraversedCells = (
    totalCells: number,
    tilePosition: number,
    remainingTiles: number
) => {
    if (remainingTiles === 2) {
        return tilePosition + 2;
    } else if (remainingTiles === 1) {
        if (tilePosition + 3 > totalCells) {
            return tilePosition + 2;
        } else {
            return tilePosition + 3;
        }
    } else {
        return tilePosition + 1;
    }
};

/**
 * 指定された終了日までの残り日数を計算する
 * @param endDate 終了日
 * @returns 残り日数
 */
export const calculateDaysRemaining = (endDate: string) => {
    const targetDate = endDate ? new Date(endDate) : new Date();
    const currentDate = new Date();
    const timeDiff = targetDate.getTime() - currentDate.getTime();
    const daysRemaining = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return daysRemaining;
};

interface AccountSugorokuProgress {
    current_stage_code: string;
    current_cell: number;
    cells_to_goal: number;
    items: {
        code: string;
        club_player_code: string;
        quantity: number;
    }[];
}

/**
 * アカウントのすごろく進捗から、獲得した選手数を計算する
 * @param accountSugorokuProgress アカウントのすごろく進捗
 * @returns 獲得した選手数
 */
export const calculateAvailablePlayers = (
    accountSugorokuProgress: AccountSugorokuProgress | null
) => {
    if (accountSugorokuProgress?.items) {
        return accountSugorokuProgress.items.filter(
            (item) => item.club_player_code !== null
        ).length;
    }
    return 0;
};

/**
 * 現在のステージの次のイベントを取得する
 * @param accountSugorokuProgress アカウントのすごろく進捗
 * @param mapEvents すごろくマスタデータ
 * @returns 次のイベント。見つからない場合はundefined
 */
export const findNextStageEvent = (
    accountSugorokuProgress: AccountSugorokuProgress | null,
    mapEvents: SugorokuMaster
) => {
    if (accountSugorokuProgress && mapEvents && mapEvents.sugorokuMap) {
        const currentStageCode = accountSugorokuProgress.current_stage_code;
        if (currentStageCode) {
            const currentStage = mapEvents.sugorokuMap.stages.find(
                (stage) => stage.code === currentStageCode
            );
            const currentCell = accountSugorokuProgress.current_cell || 0;
            if (currentStage?.events) {
                const nextEvent = currentStage.events.find(
                    (event) => event.cell >= currentCell
                );
                return nextEvent;
            }
        }
    }
};
