import React from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

import jleagueLogo from '../assets/images/jleague-logo.svg';
import JLeagueIdLoginUtility from '../lib/JLeagueIdLoginUtillity';
import iconExternalLink from '../assets/images/icon-externallink.svg';
import { PATH_MAPPING } from '../constants/pathMapping';
import { ga4PushEvent } from '../ga4';
import { GA4_CUSTOM_EVENT } from '../constants/ga4CustomEvent';

//  コンポーネントのプロパティの型を定義する
type ContainerProps = {
    // ボタンが無効かどうかを表すフラグ
    disabled?: boolean;
};

// ラッパーのスタイルを定義する Wrapper コンポーネント
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
`;

// テキストのスタイルを定義する Text コンポーネント
const Text = styled.span`
    margin-top: 20px;
    font-size: 16px;
    font-weight: normal;
`;

// JリーグIDログインボタンのスタイルを定義する JLeagueLoginContainer コンポーネント
const JLeagueLoginContainer = styled.button`
    display: grid;
    grid-template-columns: 1fr 9fr; /* 1:9 の比率でカラムを設定 */
    align-items: center;
    width: 100%;
    height: 53px;
    padding: 5px 0;
    margin-top: 10px;
    color: #000000;
    text-decoration: none;
    cursor: pointer;
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 5px;

    &:disabled {
        color: #666666;
        pointer-events: none;
        background-color: #cccccc;
        border-color: #cccccc;
    }
`;

// Jリーグロゴのスタイルを定義する JLeagueLogo コンポーネント
const JLeagueLogoContainer = styled.div<ContainerProps>`
    padding: 5px 10px;
    text-align: center;
    border-right: 1px solid #e5e5e5;
    opacity: ${(props) => (props.disabled ? '0.5' : '1')};
`;

// JリーグIDログインのテキスト部分のスタイルを定義する LoginText コンポーネント
const LoginTextContainer = styled.div`
    padding: 5px 10px;
    font-size: 15px;
    text-align: center;
`;

// JリーグIDサインアップボタンのスタイルを定義する SignUpJLeagueIdContainer コンポーネント
const SignUpJLeagueIdContainer = styled.button`
    display: grid;
    align-items: center;
    width: 100%;
    height: 53px;
    margin-top: 10px;
    color: #000000;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 5px;

    &:disabled {
        color: #666666;
        pointer-events: none;
        background-color: #cccccc;
        border-color: #cccccc;
    }
`;

// JリーグIDサインアップのテキスト部分のスタイルを定義する SignUpText コンポーネント
const SignUpTextContainer = styled.div<ContainerProps>`
    padding: 5px 10px;
    font-size: 15px;
    text-align: center;
`;

// チェックボックスのスタイルを定義する CheckboxContainer コンポーネント
const CheckboxContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    font-size: 16px;
`;

// チェックボックスのスタイルを定義する Label コンポーネント
const Label = styled.label`
    display: flex;
    align-items: end;
`;

// チェックボックスのスタイルを定義する Checkbox コンポーネント
const Checkbox = styled.input.attrs({ type: 'checkbox' })`
    position: relative;
    width: 18px;
    height: 18px;
    margin-right: 10px;
    appearance: none;
    cursor: pointer;
    background-color: #000000;
    border: 1px solid #ffffff;
    border-radius: 2px;
    outline: none;

    &:checked::before {
        position: absolute;
        top: 30%;
        left: 50%;
        width: 5px;
        height: 10px;
        content: '';
        border: solid #ffffff;
        border-width: 0 2px 2px 0;
        transform: translate(-50%, -50%) rotate(45deg);
    }
`;

// リンクテキストのスタイルを定義する LinkText コンポーネント
const LinkText = styled(Link)`
    color: #ffffff;
`;

// 外部リンクへ遷移する画像のスタイル
const LinkImage = styled.img`
    width: 16px;
    height: 12px;
    margin: 0 3px;

    /* #ffffff に相当するfilter */
    filter: brightness(0) saturate(100%) invert(85%) sepia(96%) saturate(1%)
        hue-rotate(199deg) brightness(109%) contrast(100%);
`;

// JリーグID認証コンポーネントを定義する
const JLeagueAuthentication: React.FC = () => {
    /**
     * JリーグID各認証ボタンをクリックした時の処理
     * @returns {void}
     * @description JリーグID各認証ボタンをクリックした時の処理を定義する
     * @example
     *
     */
    const [isActive, setIsActive] = React.useState(true);
    const loginButtonRef = React.useRef<HTMLButtonElement>(null);
    const signUpButtonRef = React.useRef<HTMLButtonElement>(null);

    // ボタンをクリックした時の処理を定義する
    const handleButtonClick = (
        path: string,
        buttonRef: React.RefObject<HTMLButtonElement>,
        ga4EventName: string
    ) => {
        if (buttonRef.current) {
            buttonRef.current.disabled = true;
        }
        try {
            const urlWithQuery = JLeagueIdLoginUtility.getRequestUrl(path);
            window.location.href = urlWithQuery;
            ga4PushEvent(ga4EventName);
        } finally {
            if (buttonRef.current) {
                buttonRef.current.disabled = true;
            }
        }
    };

    // チェックボックスの状態を変更するための関数を定義する
    const handleAgreementChange = () => {
        setIsActive(!isActive);
    };

    return (
        <Wrapper>
            <CheckboxContainer>
                <Label htmlFor="agreement">
                    <Checkbox
                        id="agreement"
                        autoComplete="off"
                        onChange={handleAgreementChange}
                    />
                    <LinkText
                        target="_blank"
                        rel="noopener noreferrer"
                        to={PATH_MAPPING.TERMS}
                    >
                        ご利用規約
                        <LinkImage src={iconExternalLink} />
                    </LinkText>
                    に同意します
                </Label>
            </CheckboxContainer>

            <Text>「JリーグID」でログインしてください。</Text>

            <JLeagueLoginContainer
                ref={loginButtonRef}
                disabled={isActive}
                onClick={() =>
                    handleButtonClick(
                        import.meta.env.VITE_JLEAGUE_LOGIN_PATH,
                        loginButtonRef,
                        GA4_CUSTOM_EVENT.PRESSED_LOGIN_BUTTON
                    )
                }
            >
                <JLeagueLogoContainer disabled={isActive}>
                    <img src={jleagueLogo} alt="J-League ロゴ" />
                </JLeagueLogoContainer>
                <LoginTextContainer>JリーグIDでログイン</LoginTextContainer>
            </JLeagueLoginContainer>

            <Text>「JリーグID」をお持ちでない方はこちら</Text>

            <SignUpJLeagueIdContainer
                ref={signUpButtonRef}
                disabled={isActive}
                onClick={() =>
                    handleButtonClick(
                        import.meta.env.VITE_JLEAGUE_SIGN_UP_PATH,
                        signUpButtonRef,
                        GA4_CUSTOM_EVENT.PRESSED_SIGN_UP_BUTTON
                    )
                }
            >
                <SignUpTextContainer disabled={isActive}>
                    JリーグIDを取得
                </SignUpTextContainer>
            </SignUpJLeagueIdContainer>
        </Wrapper>
    );
};

export default JLeagueAuthentication;
