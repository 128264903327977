import { useState, useEffect } from 'react';
import styled from 'styled-components';
import FooterLink, { FooterLinkProps } from './FooterLink';
import { FOOTER_LINKS } from '../constants/footerLinks';
import { isIOS, isTablet } from 'react-device-detect';
import { isPWAEnabled } from '../lib/isPWAEnabled';
// styled-components を使用して作成した Footer のコンテナースタイル
const FooterContainer = styled.div`
    position: fixed;

    /*
    * PWAが有効化されている場合の処理
    * - iOSの場合は、safe-area-inset-bottomを使用してボトムの余白を調整する
    * - それ以外の場合は、ボトムの余白を0に設定する
    */
    bottom: ${() =>
        isPWAEnabled() && isIOS
            ? `calc(env(safe-area-inset-bottom) - 25px)`
            : '0'};
    z-index: 9;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 66px;
    padding: ${() => {
        // タブレットの場合
        if (isTablet) {
            return '10px 0 17.5px 0';
        } else if (
            // PWA有効かつ、小さい画面の場合(iPhone SEなど)
            isPWAEnabled() &&
            window.innerWidth <= 375 &&
            window.innerHeight <= 667
        ) {
            return '10px 0 30px';
        } else {
            // それ以外の場合
            return '7.5px 0';
        }
    }};
    color: black;
    background-color: #f3f3f3;
    border-top: 1px solid #d9e0e8;
`;

const Footer = () => {
    const [links, setLinks] = useState<FooterLinkProps[]>([]);
    // useEffect フックは、コンポーネントがマウントされたときに実行され、
    // フッターリンクのデータを非同期的にロード
    useEffect(() => {
        const loadLinks = async () => {
            const linksData: FooterLinkProps[] = await Promise.all(
                Object.values(FOOTER_LINKS).map(async (footerLink) => {
                    return await generateLink(
                        footerLink.name,
                        footerLink.iconName,
                        footerLink.link,
                        footerLink.openInNewTab,
                        footerLink.iconWidth,
                        footerLink.end
                    );
                })
            );
            setLinks(linksData);
        };

        loadLinks();
    }, []);
    /**
     * Asynchronously generates FooterLinkProps based on name, iconName, and link.
     * @param name - Name of the link.
     * @param iconName - Icon filename for the link.
     * @param link - URL path for the link.
     * @param openInNewTab - Whether the link should open in a new tab.
     * @param iconWidth - Width of icon.
     * @param end - Which means "React Router's NavLink props 'end'"
     * @returns Promise resolving to FooterLinkProps.
     */
    const generateLink = async (
        name: string,
        iconName: string,
        link: string,
        openInNewTab = false,
        iconWidth: string,
        end: boolean
    ): Promise<FooterLinkProps> => {
        const iconURL = new URL(
            `../assets/images/${iconName}.svg`,
            import.meta.url
        );
        // Use FooterIcon component instead of img tag
        return {
            name,
            iconProps: { src: iconURL.toString(), alt: name, width: iconWidth },
            link,
            openInNewTab,
            end,
        };
    };
    // FooterContainer コンポーネントを使用して、Footer を描画する
    return (
        <FooterContainer>
            {links.map((link, index) => (
                <FooterLink key={index} {...link} />
            ))}
        </FooterContainer>
    );
};

export default Footer;
