import React from 'react';

type EventNameProps = {
    name: string;
};

/**
 * イベント名を表示する際に使用するコンポーネント
 * イベント名は<br>で改行される想定なので、ここでReact.Fragmentに突っ込んでむりやり改行させるようにしてます
 * @param eventName イベント名
 * @returns <br>で改行されるようになっているJSX要素
 */
export const EventName: React.FC<EventNameProps> = ({ name }) => {
    const brSplitEventNameList = name.split('<br>');
    const eventNameElement = brSplitEventNameList.map((item, index) => {
        return (
            <React.Fragment key={index}>
                {item}
                {index + 1 !== brSplitEventNameList.length && <br />}{' '}
            </React.Fragment>
        );
    });
    return eventNameElement;
};
