import ClubAd from '../types/clubAd';
import { createAxiosInstance } from '../lib/createAxiosInstance';
import GetClubAdResponseBody from './types/getClubAdType';
import { clubAdValidator } from './validators/clubAdValidator';
import { API_PATHS } from './constants/apiPaths';
import { AxiosResponse } from 'axios';

/**
 * 広告取得APIを呼び出す
 * @returns {ClubAd} 広告 or null
 * @throws {Error} 広告取得失敗時にエラーを投げる
 */
export const getClubAd = async (): Promise<ClubAd | null> => {
    try {
        const instance = createAxiosInstance();
        const response: AxiosResponse = await instance.get(
            API_PATHS.GET_CLUB_AD
        );
        // 有効な広告なし
        if (response.status === 204) {
            return null;
        }
        const responseBody: GetClubAdResponseBody = response.data ?? {};
        const clubAdObj: ClubAd = {
            id: responseBody.id,
            resource: responseBody.resource,
            url: responseBody.url,
        };
        const isValid = clubAdValidator(clubAdObj);
        if (!isValid) {
            throw Error;
        }
        return clubAdObj;
    } catch (error) {
        console.error('Error fetching club ads:', error);
        throw error;
    }
};
