import React from 'react';
import { ButtonLink } from './button';
import SupportLink from './SupportLink';
import styled from 'styled-components';
import EventType from '../types/event';
import Event, { EventContainer } from './Event';
import { EXTERNAL_LINKS } from '../constants/externalLinks';
import iconExternalLink from '../assets/images/icon-externallink.svg';
import { ga4PushEvent } from '../ga4';
import { GA4_CUSTOM_EVENT } from '../constants/ga4CustomEvent';

const VisitorRecordsSaveText = styled.div`
    margin-top: 40px;
    font-size: 20px;
    font-weight: bold;
    line-height: 29px;
    text-align: center;
`;

const ButtonWrapReCheck = styled.div`
    width: 100%;
    margin-top: 40px;

    & > a {
        display: inline-block;
    }
`;

const ButtonWrap = styled.div`
    width: 100%;

    & > a {
        display: inline-block;
        margin-top: 20px;
    }
`;

const SupportLinkWrapper = styled.div`
    margin-top: 25px;
`;

// 外部リンクへ遷移する画像のスタイル
const LinkImage = styled.img`
    position: absolute;
    top: 50%;
    right: 25px;
    width: 16px;
    height: 12px;
    margin: 0 3px;

    /* #141313 に相当するfilter */
    filter: brightness(0) saturate(100%) invert(5%) sepia(4%) saturate(486%)
        hue-rotate(314deg) brightness(100%) contrast(95%);
    transform: translateY(-50%);
`;

/**
 * 「よくある質問」リンクをクリックした時にGA4イベントを送信する
 */
const onClickFAQLink = () => {
    ga4PushEvent(GA4_CUSTOM_EVENT.PRESSED_GPS_ERROR_FAQ_LINK);
};

const GpsDisabledSection: React.FC<{ events: EventType[] }> = ({ events }) => {
    /**
     * ページをリロードする
     */
    const reload = () => {
        // 再チェックインボタンを押した時にGA4イベントを送信する
        ga4PushEvent(GA4_CUSTOM_EVENT.PRESSED_CHECK_IN_BUTTON);
        window.location.reload();
    };

    return (
        <>
            {events.map((event: EventType, index: number) => (
                <EventContainer key={index}>
                    <Event {...event} />
                </EventContainer>
            ))}
            <VisitorRecordsSaveText>
                来場チェックをするには
                <br />
                GPSをオンにする必要があります。
            </VisitorRecordsSaveText>

            <ButtonWrapReCheck>
                <ButtonLink
                    to="#"
                    bgColor="#000000"
                    textColor="#ffffff"
                    // checkinに対する遷移ではなくreloadとしているのは、
                    // 一度位置情報の取得を拒否したユーザーがGPSをオンにした後、位置情報の権限を反映させるため。
                    // データの再取得では権限の再反映は行えないため、ページをリロードする必要がある。
                    onClick={reload}
                >
                    再チェック
                </ButtonLink>
            </ButtonWrapReCheck>

            <ButtonWrap>
                <ButtonLink
                    to={EXTERNAL_LINKS.NFT_PASSPORT_GPS_DISABLED_LINK}
                    position="relative"
                    textColor="#141313"
                    borderColor="#000000"
                    lineHeight="22px"
                    onClick={onClickFAQLink}
                    openInNewTab={true}
                >
                    GPSをオンにしているはずだが、<br></br>
                    来場チェックできない場合
                    <LinkImage src={iconExternalLink} />
                </ButtonLink>
            </ButtonWrap>
            <SupportLinkWrapper>
                <SupportLink
                    title="手順　|　For iOS"
                    url={EXTERNAL_LINKS.IOS_GPS_ERROR_LINK}
                />
                <SupportLink
                    title="手順　|　For Android"
                    url={EXTERNAL_LINKS.ANDROID_GPS_ERROR_LINK}
                />
            </SupportLinkWrapper>
        </>
    );
};

export default GpsDisabledSection;
