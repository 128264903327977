import React from 'react';
import styled from 'styled-components';

import Dialog from '../components/Dialog';
import { useDialog } from '../components/UseDialog';
// 画像のインポート
import dolekunDauntingPoseImage from '../assets/images/dolekun-daunting-pose.svg';
import { PATH_MAPPING } from '../constants/pathMapping';
import { MESSAGES } from '../constants/messages';
// Wrapper コンポーネント: 画面全体のラッパーを定義
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100dvh;
    color: #ffffff;
    background-color: #000000;
`;

// Container コンポーネント: 各コンポーネントを集約するコンテナを定義
const Container = styled.div`
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
    min-height: 100dvh;
    padding: 0 20px 20px;
`;

const LoginFailedDialogImageWrapper = styled.div`
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
`;

const DialogImage = styled.img`
    width: 70px;
`;

// ダイアログのメッセージをラップする
const DialogMessageWrapper = styled.span`
    font-weight: bold;
    text-align: center;

    a {
        color: #333333;
    }
`;

/**
 * トップページ
 * @returns トップページ
 */
const ErrorPage: React.FC = () => {
    const { ref } = useDialog();
    return (
        <Wrapper>
            <Container>
                <Dialog
                    ref={ref}
                    isOpen={true}
                    DialogImageWrapper={
                        <LoginFailedDialogImageWrapper>
                            <DialogImage
                                src={dolekunDauntingPoseImage}
                                alt="dolekun"
                            />
                        </LoginFailedDialogImageWrapper>
                    }
                    closeModal={() => {}}
                    showCloseButton={false}
                >
                    <DialogMessageWrapper>
                        {MESSAGES.API_ERROR_DIALOG_MESSAGE}
                        <br></br>
                        <br></br>
                        <a href={PATH_MAPPING.ROOT}>TOP</a>
                    </DialogMessageWrapper>
                </Dialog>
            </Container>
        </Wrapper>
    );
};

export default ErrorPage;
