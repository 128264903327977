import React, {
    useLayoutEffect,
    useCallback,
    useReducer,
    Reducer,
} from 'react';
import { LoadingSpinnerOverlay } from '../Spinner';
import styled from 'styled-components';
import { AxiosError } from 'axios';
import { getEvents } from '../api/getEvents';
import { postCheckIn } from '../api/postCheckIn';
import { postItem } from '../components/postItem';
import Dialog from '../components/Dialog';
import { useDialog } from '../components/UseDialog';
import { getDistance } from 'geolib';
import EventsSection from '../components/EventsSection';
import NoEventsSection from '../components/NoEventsSection';
import OutsideVenueSection from '../components/OutsideVenueSection';
import GpsDisabledSection from '../components/GpsDisabledSection';
import { checkInState, checkInAction } from '../types/checkInState';
import EventDetail from '../components/EventDetail';
import dialogImage from '../assets/images/dolekun-raise-one-hand.svg';
import { ErrorHandleState, handleAxiosError } from '../lib/handleErrorResponse';
import useLogout from '../hooks/useLogout';
import { ga4PushEvent } from '../ga4';
import { GA4_CUSTOM_EVENT } from '../constants/ga4CustomEvent';
import { Link } from 'react-router-dom';
import { PATH_MAPPING } from '../constants/pathMapping';
import { SUGOROKU_ITEM_ACTION } from '../constants/SugorokuItemAction';
import { SUGOROKU_ITEM_CODE } from '../constants/sugorokuItemCode';
import { useShowCheckInNoticeBadgeContext } from '../hooks/useShowNoticeBadgeContext';
import { useShowItemNoticeBadgeContext } from '../hooks/useShowNoticeBadgeContext';
import { DiceReceivedDialogMessage } from '../components/DiceReceivedDialogMessage';

// Wrapper コンポーネント: 画面全体のラッパーを定義
const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    color: #141313;
    background-color: #f3f3f3;
`;

// Container コンポーネント: 各コンポーネントを集約するコンテナを定義
const Container = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
    padding: 0 20px 20px;
`;

/**
 * ダイアログ画像をラップするコンポーネント
 */
const DialogImageWrapper = styled.div`
    position: absolute;
    top: -45px;
    left: 50%;
    transform: translateX(-50%);
`;

/**
 * ダイアログの画像
 */
const DialogImage = styled.img`
    width: 75px;
`;

/**
 * チェックイン後のダイアログの見出しコンポーネント
 */
const CheckedInTitle = styled.span`
    font-size: 18px;
    font-weight: bold;
    line-height: 25px;
`;
/**
 * チェックイン後のダイアログのメッセージ
 */
const CheckedInMessage = styled.span`
    margin-top: 20px;
    font-weight: 500;
    text-align: center;
`;

/**
 * チェックイン後の応援の軌跡のメッセージ
 */
const RecordedHistoryMessage = styled.div`
    margin-top: 20px;
    font-weight: 500;
`;

/**
 * サイコロ獲得メッセージ
 */
const DiceWonMessage = styled.div`
    font-weight: 500;
`;

/**
 * チェックイン後の応援の軌跡へのリンクのテキスト
 */
const CheckedInConfirmationDialogLink = styled(Link)`
    margin-top: 10px;
    font-weight: 500;

    /* Dialog.aに負けたくない */
    color: blue !important;
`;

/**
 * すごろく画面へのリンクのテキスト
 */
const SaikoroConfirmationDialogLink = styled(Link)`
    display: inline-block;
    margin-top: 10px;

    /* Dialog.aに負けたくない */
    color: blue !important;
`;

/**
 * 配布お知らせメッセージ
 */
const DistributeNoticeMessage = styled.span`
    margin-top: 10px;
    font-size: 13px;
    font-weight: 500;
    line-height: 15px;
`;

/**
 * チェックインページ
 * @returns チェックインページ
 */

const CheckIn: React.FC = () => {
    // 処理の流れ
    // 0. イベントを取得する
    // 1. 現在の位置情報を取得する
    // └位置情報の許可が得られなかった場合、NG画面を描画
    // 2. そのイベントにチェックイン済みかどうかを確認する
    // └そのイベントにすでにチェックインしている場合は、チェックイン済み画面を描画
    // 3. そのイベントのチェックイン範囲内かどうかを確認する
    // └範囲外の場合、範囲外画面を描画
    // 4. それ以外のときはチェックインできますよ画面を描画
    // 5. チェックインできるときはチェックインする
    // └位置情報の取得などしてダメだったら都度エラー画面を描画

    const { ref, showModal, closeModal } = useDialog();
    const logout = useLogout();
    const { setShowCheckInNoticeBadge } = useShowCheckInNoticeBadgeContext();
    const { setShowItemNoticeBadge } = useShowItemNoticeBadgeContext();

    /**
     * reducer。stateとactionを受け取り、新しいstateを返す
     * @param state ステート
     * @param action アクション
     * @returns 新しいステート
     */
    const reducer: Reducer<checkInState, checkInAction> = (state, action) => {
        switch (action.type) {
            // ロード中
            case 'SET_IS_LOADING':
                return {
                    ...state,
                    loading: true,
                };
            // チェックイン中
            case 'NOW_CHECKING_IN':
                return {
                    ...state,
                    loading: true,
                    buttonDisabled: true,
                };
            // チェックイン成功 & アイテムを獲得した
            case 'CHECK_IN_SUCCESSFUL_AND_ACQUIRED_ITEM':
                return {
                    ...state,
                    buttonText: 'チェックイン済み',
                    isCheckInSuccessful: true,
                    showSuccessMessage: true,
                    // todayEventsの0番地のhas_checked_inをtrueにする
                    todayEvents: state.todayEvents.map((event, index) =>
                        index === 0 ? { ...event, has_checked_in: true } : event
                    ),
                    loading: false,
                    buttonDisabled: true,
                    showItemAcquiredMessage: true,
                };
            // チェックイン成功 & アイテムは獲得していない
            case 'CHECK_IN_SUCCESSFUL_AND_NOT_ACQUIRED_ITEM':
                return {
                    ...state,
                    buttonText: 'チェックイン済み',
                    isCheckInSuccessful: true,
                    showSuccessMessage: true,
                    // todayEventsの0番地のhas_checked_inをtrueにする
                    todayEvents: state.todayEvents.map((event, index) =>
                        index === 0 ? { ...event, has_checked_in: true } : event
                    ),
                    loading: false,
                    buttonDisabled: true,
                    showItemAcquiredMessage: false,
                };
            case 'GET_LOCATION_FAILED':
                return {
                    ...state,
                    loading: false,
                    gotLocationSuccessfully: false,
                };
            // チェックインに失敗
            case 'CHECK_IN_FAILED':
                return {
                    ...state,
                    showConflictCheckInMessage: true,
                    loading: false,
                    buttonDisabled: false,
                };
            // イベントがなくてチェックインに失敗した
            case 'CHECK_IN_FAILED_BY_NO_EVENT':
                return {
                    ...state,
                    todayEvents: [],
                    loading: false,
                    buttonDisabled: false,
                };
            // 範囲外でチェックインに失敗した
            case 'CHECK_IN_FAILED_BY_NOT_IS_IN_SCOPE':
                return {
                    ...state,
                    isInScope: false,
                    todayEvents: action.todayEvents || [],
                    latitude: action.latitude ?? null,
                    longitude: action.longitude ?? null,
                    loading: false,
                    buttonDisabled: false,
                };
            // 重複しててチェックインに失敗した
            case 'CHECK_IN_FAILED_BY_ALREADY_CHECKED_IN':
                return {
                    ...state,
                    buttonText: 'チェックイン済み',
                    isCheckInSuccessful: true,
                    // todayEventsの0番地のhas_checked_inをtrueにする
                    todayEvents: state.todayEvents.map((event, index) =>
                        index === 0 ? { ...event, has_checked_in: true } : event
                    ),
                    showConflictCheckInMessage: true,
                    loading: false,
                    buttonDisabled: true,
                };
            // チェックイン失敗モーダルを閉じる
            case 'CLOSE_CONFLICT_CHECK_IN_MODAL':
                return {
                    ...state,
                    showConflictCheckInMessage: false,
                };
            // その他（主に、全部更新したいときとかに使ってください）
            default:
                return { ...state, ...action };
        }
    };

    /**
     * 初期ステート
     */
    const initialState: checkInState = {
        todayEvents: [],
        gotLocationSuccessfully: true,
        loading: false,
        eventId: null,
        latitude: null,
        longitude: null,
        buttonText: '来場記録を保存する',
        buttonDisabled: false,
        isCheckInSuccessful: false,
        showSuccessMessage: false,
        showConflictCheckInMessage: false,
        isInScope: false,
        showItemAcquiredMessage: false,
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    /**
     * 現在の位置情報を取得する。
     * @returns 位置情報
     */
    const getCurrentGeoLocation = useCallback(async () => {
        const position = await new Promise<GeolocationPosition>(
            (resolve, reject) => {
                navigator.geolocation.getCurrentPosition(resolve, reject, {
                    // 15秒間位置情報が取れなかったらタイムアウトさせる。
                    timeout: 15000,
                    enableHighAccuracy: true,
                });
            }
        );
        return position;
    }, []);

    /**
     * 初期化処理。位置情報の取得やチェックイン済かどうかの判定を行う
     */
    const initialize = useCallback(async () => {
        dispatch({ type: 'SET_IS_LOADING' });
        let {
            todayEvents,
            gotLocationSuccessfully,
            latitude,
            eventId,
            longitude,
            buttonText,
            buttonDisabled,
            isCheckInSuccessful,
            isInScope,
        } = state;

        // 位置情報の取得
        try {
            todayEvents = await getEvents();
            // イベントがない場合はイベントなし画面になるし、位置情報の取得は不要。
            if (todayEvents.length === 0) {
                // イベントが存在しない場合にGA4イベントを送信する。
                ga4PushEvent(
                    GA4_CUSTOM_EVENT.DISPLAY_CHECK_IN_FAILED_BY_NO_EVENT_PAGE
                );
                return;
            }
            // チェックイン済の場合も位置情報チェックは不要。
            if (todayEvents[0].has_checked_in) {
                buttonText = 'チェックイン済み';
                buttonDisabled = true;
                isCheckInSuccessful = true;
                return;
            }
            const position = await getCurrentGeoLocation();
            latitude = position.coords.latitude;
            longitude = position.coords.longitude;
            gotLocationSuccessfully = true;
            // チェックイン済みかどうか
            eventId = todayEvents[0].id;
            if (todayEvents[0].has_checked_in) {
                buttonText = 'チェックイン済み';
                buttonDisabled = true;
                isCheckInSuccessful = true;
            } else {
                // チェックイン済ではない場合にだけ位置情報の判定が必要
                const eventLocation = {
                    latitude: todayEvents[0].latitude,
                    longitude: todayEvents[0].longitude,
                };
                const userLocation = {
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                };
                const distance = getDistance(eventLocation, userLocation);
                isInScope = distance <= todayEvents[0].radius;
                if (!isInScope) {
                    // エリア範囲外時にGA4イベントを送信する。
                    ga4PushEvent(
                        GA4_CUSTOM_EVENT.DISPLAY_CHECK_IN_FAILED_BY_NOT_IS_IN_SCOPE_PAGE
                    );
                }
            }
        } catch (error: unknown) {
            // トークン切れ、またはAPIエラーの場合はログイン画面へ
            const errorHandleState: ErrorHandleState | undefined =
                handleAxiosError(logout, error);
            if (errorHandleState?.isRedirected) {
                return;
            }
            if (error && error instanceof GeolocationPositionError) {
                // GPS接続NG時にGA4イベントを送信する。
                ga4PushEvent(
                    GA4_CUSTOM_EVENT.DISPLAY_CHECK_IN_FAILED_BY_NO_LOCATION_PAGE
                );
                // 位置情報の取得に失敗した場合はGPS接続NG画面を表示
                gotLocationSuccessfully = false;
                return;
            }
        } finally {
            // ここでreducer使って一気にガッって入れる
            dispatch({
                todayEvents,
                gotLocationSuccessfully,
                loading: false,
                eventId,
                latitude,
                longitude,
                buttonText,
                buttonDisabled,
                isCheckInSuccessful,
                isInScope,
            });
        }
    }, [getCurrentGeoLocation, state, logout]);

    /**
     * ページ描画時に初期化処理を行う
     */
    useLayoutEffect(() => {
        initialize();
        // チェックイン画面描画時にGA4イベントを送信する
        ga4PushEvent(GA4_CUSTOM_EVENT.DISPLAY_CHECK_IN_PAGE);
        // 初期化目的なので、依存配列には何もセットしない
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const eventPosition =
        state.todayEvents.length > 0
            ? {
                  lat: state.todayEvents[0].latitude,
                  lng: state.todayEvents[0].longitude,
              }
            : null;
    const radius =
        state.todayEvents.length > 0 ? state.todayEvents[0].radius : null;

    /**
     * エリア範囲内のチェックインボタン押下時の処理
     */
    const handleCheckInButtonClick = async () => {
        ga4PushEvent(GA4_CUSTOM_EVENT.PRESSED_CHECK_IN_BUTTON);
        // ステートの更新
        dispatch({ type: 'NOW_CHECKING_IN' });
        try {
            // 位置情報取り直し
            const position = await getCurrentGeoLocation();
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            const result = await postCheckIn(
                Number(state.eventId),
                latitude,
                longitude
            );
            // APIコールに成功したときはステートを成功した状態に更新する
            if (result.success) {
                setShowCheckInNoticeBadge(false);
                // チェックイン成功時にGA4イベントを送信する。
                ga4PushEvent(GA4_CUSTOM_EVENT.DISPLAY_CHECK_IN_SUCCESSFUL_PAGE);
                showModal();
                const isSugorokuDisabled =
                    import.meta.env.VITE_SUGOROKU_DISABLED === 'true';
                if (isSugorokuDisabled) {
                    dispatch({
                        type: 'CHECK_IN_SUCCESSFUL_AND_NOT_ACQUIRED_ITEM',
                    });
                    return;
                }
                try {
                    // アイテム獲得APIを呼び出す
                    const isItemAcquired = await postItem(
                        SUGOROKU_ITEM_ACTION.CHECK_IN,
                        SUGOROKU_ITEM_CODE.DICE,
                        Number(state.eventId)
                    );
                    if (isItemAcquired) {
                        // すごろくアイテム獲得をしたため、フッターに「！」つける
                        setShowItemNoticeBadge(true);
                        dispatch({
                            type: 'CHECK_IN_SUCCESSFUL_AND_ACQUIRED_ITEM',
                        });
                    } else {
                        dispatch({
                            type: 'CHECK_IN_SUCCESSFUL_AND_NOT_ACQUIRED_ITEM',
                        });
                    }
                } catch (error) {
                    console.error(
                        'Error sending post item to the backend:',
                        error
                    );
                    dispatch({
                        type: 'CHECK_IN_SUCCESSFUL_AND_NOT_ACQUIRED_ITEM',
                    });
                }
            } else {
                switch (result.statusCode) {
                    case 281:
                    case 282:
                        // イベントが存在しない場合にGA4イベントを送信する。
                        ga4PushEvent(
                            GA4_CUSTOM_EVENT.DISPLAY_CHECK_IN_FAILED_BY_NO_EVENT_PAGE
                        );
                        // イベントがないのでイベントなしとする
                        dispatch({ type: 'CHECK_IN_FAILED_BY_NO_EVENT' });
                        return;
                    case 283:
                        try {
                            const todayEvents = await getEvents();
                            // 範囲外
                            dispatch({
                                type: 'CHECK_IN_FAILED_BY_NOT_IS_IN_SCOPE',
                                todayEvents: todayEvents,
                                latitude: latitude,
                                longitude: longitude,
                            });
                        } catch (error) {
                            //エラー処理のロジックはなし
                        }
                        return;
                }
            }
        } catch (error: unknown) {
            // 位置情報の取得に失敗した場合はGPS接続NG画面を表示
            if (error && error instanceof GeolocationPositionError) {
                dispatch({ type: 'GET_LOCATION_FAILED' });
                return;
            }

            if (error && error instanceof AxiosError) {
                const statusCode = error.response?.status;
                if (statusCode === 409) {
                    // 409はチェックイン済なのでログイン画面に飛ばすことはせず、チェックイン済画面にする
                    showModal();
                    dispatch({
                        type: 'CHECK_IN_FAILED_BY_ALREADY_CHECKED_IN',
                    });
                    return;
                }
            }
            // トークン切れ、またはAPIエラーの場合はログイン画面へ
            handleAxiosError(logout, error);
        }
    };

    /**
     * チェックイン重複モーダルを閉じるときにステートをリセット
     */
    const handleCloseConflictCheckInModal = () => {
        dispatch({ type: 'CLOSE_CONFLICT_CHECK_IN_MODAL' });
        closeModal();
    };

    let element;

    if (state.loading) {
        element = <LoadingSpinnerOverlay />;
    } else if (state.todayEvents.length === 0) {
        element = (
            <>
                <NoEventsSection onRecheck={initialize} />
                <EventDetail />
            </>
        );
    } else if (!state.gotLocationSuccessfully) {
        element = <GpsDisabledSection events={state.todayEvents} />;
    } else if (state.isCheckInSuccessful || state.isInScope) {
        element = (
            <>
                <EventsSection
                    events={state.todayEvents}
                    isCheckInSuccessful={state.isCheckInSuccessful}
                    buttonText={state.buttonText}
                    onButtonClick={handleCheckInButtonClick}
                    buttonDisabled={state.buttonDisabled}
                />
                <EventDetail />
            </>
        );
    } else {
        element = (
            <>
                <OutsideVenueSection
                    events={state.todayEvents}
                    eventPosition={eventPosition}
                    radius={radius}
                    onRecheck={initialize}
                    currentPosition={{
                        currentlat: state.latitude ?? 0,
                        currentlng: state.longitude ?? 0,
                    }}
                />
            </>
        );
    }

    return (
        <>
            <Wrapper>
                {/* ダイアログは分ける */}
                {state.showSuccessMessage && (
                    <Dialog
                        ref={ref}
                        isOpen={state.showSuccessMessage}
                        DialogImageWrapper={
                            <DialogImageWrapper>
                                <DialogImage src={dialogImage} alt="dolekun" />
                            </DialogImageWrapper>
                        }
                        closeModal={closeModal}
                    >
                        <CheckedInTitle>来場チェック完了！</CheckedInTitle>
                        <RecordedHistoryMessage>
                            応援の軌跡に履歴を記録しました！
                        </RecordedHistoryMessage>
                        <DistributeNoticeMessage>
                            ※来場証明（NFT）は後日配布されます。
                        </DistributeNoticeMessage>
                        <CheckedInConfirmationDialogLink to={PATH_MAPPING.NFT}>
                            応援の軌跡を確認する
                        </CheckedInConfirmationDialogLink>
                        {state.showItemAcquiredMessage && (
                            <CheckedInMessage>
                                <DiceWonMessage>
                                    <DiceReceivedDialogMessage diceCount={3} />
                                </DiceWonMessage>
                                <SaikoroConfirmationDialogLink
                                    to={PATH_MAPPING.SUGOROKU}
                                >
                                    すごろくマップへ
                                </SaikoroConfirmationDialogLink>
                            </CheckedInMessage>
                        )}
                    </Dialog>
                )}
                {state.showConflictCheckInMessage && (
                    <Dialog
                        ref={ref}
                        isOpen={state.showConflictCheckInMessage}
                        DialogImageWrapper={
                            <DialogImageWrapper>
                                <DialogImage src={dialogImage} alt="dolekun" />
                            </DialogImageWrapper>
                        }
                        closeModal={handleCloseConflictCheckInModal}
                    >
                        <CheckedInTitle>
                            すでにチェックインされています。
                        </CheckedInTitle>
                    </Dialog>
                )}
                <Container>{element}</Container>
            </Wrapper>
        </>
    );
};

export default CheckIn;
