import ReceiveProbability from '../../types/receiveProbabilityType';

export const receiveProbabilityValidator = (
    receiveProbability: ReceiveProbability
) => {
    if (!Array.isArray(receiveProbability.postPicture)) {
        return false;
    }
    for (const postPicture of receiveProbability.postPicture) {
        if (typeof postPicture.eventId !== 'number') {
            return false;
        }
        if (typeof postPicture.eventName !== 'string') {
            return false;
        }
    }
    if (!Array.isArray(receiveProbability.putDiary)) {
        return false;
    }
    for (const putDiary of receiveProbability.putDiary) {
        if (typeof putDiary.eventId !== 'number') {
            return false;
        }
        if (typeof putDiary.eventName !== 'string') {
            return false;
        }
    }
    return true;
};
