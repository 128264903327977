import { useOutletContext } from 'react-router-dom';

/**
 * ログインダイアログを表示中かどうかの確認フック
 * @see https://reactrouter.com/en/main/hooks/use-outlet-context
 *
 * @returns ログインチェック中かどうかを返す
 */
export const useCheckingLoginDialog = () => {
    return useOutletContext<boolean>();
};
