import React from 'react';
import styled from 'styled-components';
import { EXTERNAL_LINKS } from '../constants/externalLinks';
import iconExternalLink from '../assets/images/icon-externallink.svg';
import { ga4PushEvent } from '../ga4';
import { GA4_CUSTOM_EVENT } from '../constants/ga4CustomEvent';

const EventDetailContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    font-size: 14px;
    color: #141313;

    a {
        color: #141313;
    }
`;

const EventDetailTitle = styled.span`
    font-size: 16px;
    font-weight: bold;
`;

const EventDetailNoticeContainer = styled.div`
    display: grid;
    row-gap: 10px;
    margin-top: 20px;
`;

const EventDetailNotice = styled.span`
    line-height: 20px;
    text-align: center;
`;

const EventDetailLink = styled.a`
    margin-top: 10px;
`;

// 外部リンクへ遷移する画像のスタイル
const LinkImage = styled.img`
    width: 16px;
    height: 12px;
    margin: 0 3px;

    /* #141313 に相当するfilter */
    filter: brightness(0) saturate(100%) invert(5%) sepia(4%) saturate(486%)
        hue-rotate(314deg) brightness(100%) contrast(95%);
`;

/**
 * 詳細日程はこちらをクリックした場合にイベントを送信する
 */
const onClickGameScheduleLink = () => {
    ga4PushEvent(GA4_CUSTOM_EVENT.PRESSED_GAME_SCHEDULE_LINK);
};

const EventDetail: React.FC = () => {
    const url = EXTERNAL_LINKS.GAME_SCHEDULE_LINK;
    return (
        <EventDetailContainer>
            <EventDetailTitle>来場チェック対象試合</EventDetailTitle>
            <EventDetailNoticeContainer>
                <EventDetailNotice>
                    2024シーズン明治安田J1リーグ
                    <br />
                    2024JリーグYBCルヴァンカップ
                </EventDetailNotice>
                <EventDetailNotice>
                    （HOME戦全試合、一部AWAY戦）
                </EventDetailNotice>
            </EventDetailNoticeContainer>
            <EventDetailLink
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                onClick={onClickGameScheduleLink}
            >
                ※ 詳細日程はこちら
                <LinkImage src={iconExternalLink} />
            </EventDetailLink>
        </EventDetailContainer>
    );
};

export default EventDetail;
