import { ReactNode, useState } from 'react';
import { NoticeBadgeContext } from '../contexts/NoticeBadgeContext';

/**
 * フッターの「来場チェック」と「すごろく」に未読バッジを出すか出さないかのコンテキストのプロバイダー
 *
 * @param props 子要素
 * @returns プロバイド後のコンポーネント
 */
export function NoticeBadgeProvider(props: { children: ReactNode }) {
    // チェックイン通知バッジの表示フラグ
    const [showCheckInNoticeBadge, setShowCheckInNoticeBadge] = useState(false);

    // アイテム通知バッジの表示フラグ
    const [showItemNoticeBadge, setShowItemNoticeBadge] = useState(false);

    return (
        <>
            <NoticeBadgeContext.Provider
                value={{
                    showCheckInNoticeBadge,
                    setShowCheckInNoticeBadge,
                    showItemNoticeBadge,
                    setShowItemNoticeBadge,
                }}
            >
                {props.children}
            </NoticeBadgeContext.Provider>
        </>
    );
}
