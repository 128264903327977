import { createAxiosInstance } from '../lib/createAxiosInstance';
import { compressImage } from '../lib/imageCompressor';
import Picture from '../types/pictureType';
import { API_PATHS } from './constants/apiPaths';

/**
 * 写真登録APIを呼び出す
 * @param {number} accountNftId アカウントNFT ID
 * @param {File} imageFile 画像ファイル
 * @returns {Picture} 登録した写真
 * @throws {Error} 登録失敗時にエラーを投げる
 */
export const postPicture = async (
    accountNftId: number,
    imageFile: File
): Promise<Picture> => {
    try {
        const compressedImage: File = await compressImage(imageFile);
        const imageHolder: FormData = new FormData();
        imageHolder.append('binary_data', compressedImage);
        const instance = createAxiosInstance('multipart/form-data');
        const response = await instance.post(
            API_PATHS.POST_PICTURE(accountNftId),
            imageHolder
        );
        const createdPicture: Picture = (response.data as Picture) ?? {};
        return createdPicture;
    } catch (error) {
        console.error('Failed add picture:', error);
        throw error;
    }
};
