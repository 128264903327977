import ClubPlayer from '../../types/clubPlayer';

export const clubPlayerValidator = (clubPlayer: ClubPlayer) => {
    if (!clubPlayer) {
        return false;
    }
    if (typeof clubPlayer.id !== 'number') {
        return false;
    }
    if (typeof clubPlayer.code !== 'string') {
        return false;
    }
    if (typeof clubPlayer.name !== 'string') {
        return false;
    }
    if (typeof clubPlayer.position !== 'string') {
        return false;
    }
    if (typeof clubPlayer.hometown !== 'string') {
        return false;
    }
    if (typeof clubPlayer.birthday !== 'string') {
        return false;
    }
    if (typeof clubPlayer.height !== 'number') {
        return false;
    }
    if (typeof clubPlayer.weight !== 'number') {
        return false;
    }
    if (typeof clubPlayer.imageIconUrl !== 'string') {
        return false;
    }
    if (!Array.isArray(clubPlayer.content)) {
        return false;
    }
    for (const content of clubPlayer.content) {
        if (typeof content.map_name !== 'string') {
            return false;
        }
        if (typeof content.content !== 'string') {
            return false;
        }
    }
    return true;
};
