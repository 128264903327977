import React from 'react';
import styled from 'styled-components';

const DiceAcquisitionMessage = styled.span`
    font-weight: 500;
`;

const DiceCount = styled.span`
    padding: 0 5px;
    font-size: 16px;
    font-weight: 900;
    line-height: 30px;
    color: #d71920;
`;

export const DiceReceivedDialogMessage: React.FC<{ diceCount: number }> = ({
    diceCount,
}) => {
    return (
        <DiceAcquisitionMessage>
            サイコロを
            <DiceCount>{diceCount}個</DiceCount>
            獲得しました！
        </DiceAcquisitionMessage>
    );
};
