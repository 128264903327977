import { EthereumPrivateKeyProvider } from '@web3auth/ethereum-provider';
import {
    OPENLOGIN_NETWORK_TYPE,
    OpenloginAdapter,
    TypeOfLogin,
} from '@web3auth/openlogin-adapter';
import { chainConfig } from '../../constants/web3auth';
import { Web3AuthNoModal } from '@web3auth/no-modal';

/*
 * Web3Authの初期化
 * @returns {Promise<Web3AuthNoModal>} Web3AuthNoModal
 */
export const initializeWeb3Auth = async (): Promise<Web3AuthNoModal> => {
    const privateKeyProvider = new EthereumPrivateKeyProvider({
        config: { chainConfig },
    });

    const web3auth = new Web3AuthNoModal({
        clientId: import.meta.env.VITE_WEB3AUTH_CLIENT_ID,
        web3AuthNetwork: import.meta.env
            .VITE_WEB3AUTH_NETWORK as OPENLOGIN_NETWORK_TYPE,
        privateKeyProvider: privateKeyProvider,
        useCoreKitKey: false,
    });

    const openloginAdapter = new OpenloginAdapter({
        adapterSettings: {
            uxMode: 'redirect', // redirect or popup
            loginConfig: {
                jwt: {
                    verifier: import.meta.env.VITE_WEB3AUTH_JWT_VERIFIER,
                    typeOfLogin: import.meta.env
                        .VITE_WEB3AUTH_TYPE_OF_LOGIN as TypeOfLogin,
                    clientId: import.meta.env.VITE_WEB3AUTH_CLIENT_ID,
                },
            },
        },
        privateKeyProvider,
    });
    web3auth.configureAdapter(openloginAdapter);
    await web3auth.init();
    return web3auth;
};
