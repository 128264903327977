/**
 * LocalStorageの取得・設定を行う
 */
class LocalStorageManager {
    /**
     * LocalStorageから値を取得する
     * @param key LocalStorageのキー
     * @returns キーに合致する値
     */
    static getLocalStorageValue(key: string): string | null {
        return localStorage.getItem(key);
    }

    /**
     * LocalStorageから値を取得し、JSON形式に変換する
     * @param key LocalStorageのキー
     * @returns JSON形式の値
     */
    static getLocalStorageValueAsJSON(key: string) {
        const value = localStorage.getItem(key);
        if (!value) {
            return null;
        }
        return JSON.parse(value);
    }

    /**
     * LocalStorageに値を設定する
     * @param key LocalStorageのキー
     * @param value LocalStorageに設定する値
     */
    static setLocalStorageValue(key: string, value: string): void {
        localStorage.setItem(key, value);
    }

    /**
     * LocalStorageから値を削除する
     * @param key LocalStorageのキー
     */
    static deleteLocalStorageValue(key: string): void {
        localStorage.removeItem(key);
    }

    /**
     * すべてのLocalStorageの値を削除する
     */
    static deleteAllLocalStorageValues(): void {
        localStorage.clear();
    }
}

export default LocalStorageManager;
