import { createAxiosInstance } from '../lib/createAxiosInstance';
import { API_PATHS } from './constants/apiPaths';
import Picture from '../types/pictureType';

/**
 * 写真削除APIを呼び出す
 * @param {number} accountNftId アカウントNFT_ID
 * @param {number} pictureId 写真ID
 * @returns {Picture} 削除した写真
 * @throws {Error} 削除失敗時にエラーを投げる
 */

export const deletePicture = async (
    accountNftId: number,
    pictureId: number
): Promise<Picture> => {
    try {
        const instance = createAxiosInstance();
        const apiUrl = API_PATHS.DELETE_PICTURE(accountNftId, pictureId);

        const response = await instance.delete(apiUrl);

        const deletedPicture: Picture = (response.data as Picture) ?? {};

        return deletedPicture;
    } catch (error) {
        console.error('Failed to delete picture:', error);
        throw error;
    }
};
