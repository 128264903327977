import React from 'react';
import {
    Await,
    Link,
    generatePath,
    useLoaderData,
    useNavigate,
} from 'react-router-dom';
import htmlReactParser from 'html-react-parser';
import styled from 'styled-components';
import LeftArrow from '../assets/images/icon_left.svg';
import RightArrow from '../assets/images/icon_right.svg';
import { StyledLink } from '../components/button';
import { PATH_MAPPING } from '../constants/pathMapping';
import { NotificationDetailLoaderDataType } from '../types/notificationDetailLoaderData';
import { LoadingSpinnerOverlay } from '../Spinner';
import { AppNotificationDetail } from '../types/AppNotificationDetail';
import { ga4PushEvent } from '../ga4';
import { GA4_CUSTOM_EVENT } from '../constants/ga4CustomEvent';
import { useSwipeable } from 'react-swipeable';
import { CustomSwipeableOptions } from '../types/swipeableOptions';

// Define custom props for ArrowContainer styled component
type ArrowContainerProps = {
    $hasPrevious: boolean;
    $hasNext: boolean;
};

// Define styled components
const ParentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 400px;
    padding: 0 20px 30px;
    margin: 0 auto;
`;

const DetailContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: auto;
`;

const DetailDate = styled.div`
    height: 15px;
    margin-top: 10px;
    font-size: 10px;
    font-weight: 500;
    color: #848487;
    text-align: left;
`;

const DetailText = styled.div`
    padding: 0 20px;
    margin-top: 5px;
    font-size: 18px;
    font-weight: bold;
    color: #141313;
    text-align: center;
    word-wrap: break-word;
`;

const DescriptionContainer = styled.div`
    padding: 20px;
    margin-top: 20px;
    font-size: 14px;
    line-height: 24px;
    color: #141313;
    text-align: left;
    word-wrap: break-word;
    background-color: #ffffff;

    img {
        max-width: 100%;
    }
`;

const AnnouncementTypeStyled = styled.div`
    display: inline-block;
    width: 66px;
    margin-top: 40px;
    font-size: 12px;
    font-weight: bold;
    line-height: 20px;
    color: #ffffff;
    text-align: center;
    background-color: #000000;
    border-radius: 2px;
`;

const ArrowContainer = styled.div<ArrowContainerProps>`
    position: relative;
    display: flex;
    justify-content: ${({ $hasPrevious, $hasNext }) =>
        $hasPrevious && $hasNext
            ? 'space-between'
            : $hasPrevious
              ? 'flex-end'
              : $hasNext
                ? 'flex-start'
                : 'center'};
    width: 100%;
    margin-top: 44px;
`;

const ArrowLink = styled(Link)`
    position: relative;

    /* タップ判定を広げるおまじない */
    &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 30px;
        height: 30px;
        content: '';
        transform: translate(-50%, -50%);
    }
`;

const ArrowIcon = styled.img`
    width: 8.8347px;
    height: 15.5694px;
`;

const CustomButtonLink = styled(StyledLink)`
    max-width: none;
`;

const ButtonWrapper = styled.div`
    display: flex;
    padding-top: 58px;
    border-radius: 5px;
`;

const NotificationDetail = () => {
    const data = useLoaderData() as NotificationDetailLoaderDataType;
    const navigate = useNavigate();

    React.useEffect(() => {
        ga4PushEvent(GA4_CUSTOM_EVENT.DISPLAY_NOTIFICATION_DETAIL_PAGE);
        // NOTE: 依存配列なしのuseEffectだと、次のお知らせ詳細に遷移してもイベントが送信されないため
        // Promiseが解決される前ではあるが、ローダーが変更されたタイミングでイベントを送信する
    }, [data]);

    //スマホの際の横スワイプできるようになる実装。ライブラリーを使用。
    const SwipeHandler = (
        previousNotificationId: number | null,
        nextNotificationId: number | null
    ) =>
        useSwipeable({
            onSwipedLeft: () => {
                previousNotificationId !== null
                    ? navigate(
                          generatePath(PATH_MAPPING.NOTIFICATION_DETAIL, {
                              notificationId: previousNotificationId,
                          })
                      )
                    : '#';
            },
            onSwipedRight: () => {
                nextNotificationId !== null
                    ? navigate(
                          generatePath(PATH_MAPPING.NOTIFICATION_DETAIL, {
                              notificationId: nextNotificationId,
                          })
                      )
                    : '#';
            },
            onSwipedDown: () => {},
            onSwipedUp: () => {},
            delta: 50,
            preventDefaultTouchmoveEvent: true,
            trackMouse: true,
        } as CustomSwipeableOptions);

    return (
        <React.Suspense fallback={<LoadingSpinnerOverlay opacity={0.6} />}>
            <Await resolve={data.notification}>
                {(notificationData: AppNotificationDetail) => {
                    const hasPrevious = !!notificationData.previousId;
                    const hasNext = !!notificationData.nextId;

                    return (
                        <ParentContainer>
                            <DetailContainer>
                                <div
                                    {...SwipeHandler(
                                        notificationData.previousId,
                                        notificationData.nextId
                                    )}
                                >
                                    <AnnouncementTypeStyled
                                        style={{
                                            backgroundColor:
                                                notificationData.typeBackgroundColor,
                                        }}
                                    >
                                        {notificationData.type}
                                    </AnnouncementTypeStyled>
                                    <DetailDate>
                                        {notificationData.releaseDateTime}
                                    </DetailDate>
                                    <DetailText>
                                        {notificationData.title}
                                    </DetailText>
                                    <DescriptionContainer>
                                        {htmlReactParser(
                                            notificationData.content
                                        )}
                                    </DescriptionContainer>
                                </div>
                                <ArrowContainer
                                    $hasPrevious={hasPrevious}
                                    $hasNext={hasNext}
                                >
                                    {hasNext && (
                                        <ArrowLink
                                            to={generatePath(
                                                PATH_MAPPING.NOTIFICATION_DETAIL,
                                                {
                                                    notificationId:
                                                        notificationData.nextId,
                                                }
                                            )}
                                        >
                                            <ArrowIcon
                                                src={LeftArrow}
                                                alt="Left Arrow"
                                            />
                                        </ArrowLink>
                                    )}
                                    {hasPrevious && (
                                        <ArrowLink
                                            to={generatePath(
                                                PATH_MAPPING.NOTIFICATION_DETAIL,
                                                {
                                                    notificationId:
                                                        notificationData.previousId,
                                                }
                                            )}
                                        >
                                            <ArrowIcon
                                                src={RightArrow}
                                                alt="Right Arrow"
                                            />
                                        </ArrowLink>
                                    )}
                                </ArrowContainer>
                                <ButtonWrapper>
                                    <CustomButtonLink
                                        to={PATH_MAPPING.NOTIFICATIONS}
                                        state={{ isFlipped: false }}
                                        $position="static"
                                        $bgColor="transparent"
                                        $textColor="#141313"
                                        $borderColor="#000000"
                                    >
                                        お知らせ一覧
                                    </CustomButtonLink>
                                </ButtonWrapper>
                            </DetailContainer>
                        </ParentContainer>
                    );
                }}
            </Await>
        </React.Suspense>
    );
};

export default NotificationDetail;
